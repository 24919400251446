import { createSlice } from '@reduxjs/toolkit';
import { getMemoryList, createMemory, updateMemory, deleteMemory } from './memoryActions';

const initialState = {
  memoriesList: [],
  isLoading: false,
  error: null,
  total: 0,
  totalPages: 0
};

const memorySlice = createSlice({
  name: 'memory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMemoryList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMemoryList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.memoriesList = action.payload.project_messages;
        state.total = action.payload.total;
        state.totalPages = action.payload.total_pages;
      })
      .addCase(getMemoryList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(createMemory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createMemory.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createMemory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateMemory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateMemory.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateMemory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteMemory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteMemory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.memoriesList = state.memoriesList.filter(
          memory => memory.id !== action.payload.memoryId
        );
        state.total -= 1;
      })
      .addCase(deleteMemory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export default memorySlice.reducer;