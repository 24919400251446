import { apiAgent } from "../../config/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    updateLoadError,
    setLoadingState,
    setSavingState,
    updateTestPlansList,
    updateSelectedTestPlanDetail
} from "./testPlanSlice";
import { TEST_PLANS_PAGE_SIZE } from "../../config/constants";

const createTestPlan = createAsyncThunk(
    "createTestPlan",
    async ({projectId, title, environmentSetId}, { dispatch, rejectWithValue }) => {
        await dispatch(setLoadingState(true));
        try {
            const response = await apiAgent({
                method: "POST",
                url: '/api/v1/test_plans/',
                data: {
                    project_id: projectId,
                    title: title,
                    environment_set_id: environmentSetId
                }
            })
            if (response.status === 200) {
                await dispatch(setLoadingState(false));
                return response?.data;
            } else {
                await dispatch(setLoadingState(false));
                return rejectWithValue(response.data);
            }
        } catch (err) {
            dispatch(updateLoadError({ error_message: err.response.data.message }));
        }
    }
)

const getTestPlan = createAsyncThunk(
    "getTestPlan",
    async ({testPlanId}, {dispatch, rejectWithValue}) => {
        await dispatch(setLoadingState(true));
        try {
            const response = await apiAgent({
                method: "GET",
                url: `/api/v1/test_plans/${testPlanId}`,
            });
            if (response.status === 200) {
                await dispatch(
                    updateSelectedTestPlanDetail({
                      testPlanDetail: response?.data,
                    })
                );
                await dispatch(setLoadingState(false));
                return response?.data;
            }
        } catch (err) {
            dispatch(updateLoadError({ error_message: err.response.data.message }));
            await dispatch(setLoadingState(false));
        }
    }
)

const getAllTestPlans = createAsyncThunk(
    "getAllTestPlans",
    async ({ projectId, pageNumber, pageSize = TEST_PLANS_PAGE_SIZE }, { dispatch }) => {
        await dispatch(setLoadingState(true));
        try {
            const response = await apiAgent({
                method: "GET",
                url: '/api/v1/test_plans',
                params: {
                    project_id: projectId,
                    page: pageNumber,
                    page_size: pageSize
                }
            });
            if (response.status === 200) {
                await dispatch(updateTestPlansList({testPlansList: response?.data?.test_plans, totalPages: response?.data?.total_pages, total: response?.data?.total}));
                await dispatch(setLoadingState(false));
                return response?.data;
            }
        } catch (err) {
            dispatch(updateLoadError({ error_message: err.response.data.message }));
            await dispatch(setLoadingState(false));
        }
    }
)

const updateTestPlan = createAsyncThunk(
    "updateTestPlan",
    async ({ data, testPlanId }, { dispatch }) => {
        await dispatch(setSavingState(true));
        try {
            const response = await apiAgent({
                method: "PUT",
                url: `/api/v1/test_plans/${testPlanId}`,
                data
            });
            if (response.status === 200) {
                await dispatch(
                    updateSelectedTestPlanDetail({
                      testPlanDetail: response?.data,
                    })
                );
                await dispatch(setSavingState(false));
                return response?.data;
            } else {
                await dispatch(setSavingState(false));
            }
        } catch (err) {
            dispatch(updateLoadError({ error_message: err.response.data.message }));
            await dispatch(setSavingState(false));
        }
    }
);

const deleteTestPlan = createAsyncThunk(
    "deleteTestPlan",
    async ({ testPlanId }, {dispatch, rejectWithValue}) => {
        await dispatch(setSavingState(true));
        try {
            const response = await apiAgent({
                method: "DELETE",
                url: `/api/v1/test_plans/${testPlanId}`,
            });
            if (response.status === 204) {
                await dispatch(setSavingState(false));
                return response?.data;
            }
        } catch (err) {
            dispatch(updateLoadError({ error_message: err.response.data.message }));
        }
    }
);

const createBuild = createAsyncThunk(
    'createBuild',
    async ({ testPlanId }, { dispatch, rejectWithValue }) => {
      await dispatch(setLoadingState(true));
      try {
        const response = await apiAgent({
          method: "POST",
          url: '/api/v1/test_plan_builds',
          data: {
            test_plan_id: testPlanId,
          },
        });
        if (response.status === 200) {
          await dispatch(setLoadingState(false));
          return response?.data;
        } else {
          await dispatch(setLoadingState(false));
          return rejectWithValue(response.data);
        }
      } catch (err) {
        dispatch(updateLoadError({ error_message: err.response.data.message }));
      }
    }
);

export {
    createTestPlan,
    getTestPlan,
    getAllTestPlans,
    updateTestPlan,
    deleteTestPlan,
    createBuild
}
