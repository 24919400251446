import { apiAgent } from "../../config/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  updateLoadError,
  setLoadingState,
  setSavingState,
  updateTestCaseSetList,
} from "./testCaseSetSlice";

const createTestCaseSet = createAsyncThunk(
  "createTestCaseSet",
  async (body, { dispatch, rejectWithValue }) => {
    await dispatch(setSavingState(true));
    const data = {
      test_scenario_id: body.testScenarioId,
      entity_id: body.entityId,
      request_type: body.requestType,
      url: body.requestUrl,
      title: body.title,
    };
    if (body.params) data.params = body.params;
    if (body.headers) data.headers = body.headers;
    if (body.body) data.body = body.body;
    if (body.bodyType) data.body_type = body.bodyType;
    try {
      const response = await apiAgent({
        method: "POST",
        url: '/api/v1/test_case_sets/',
        data: data,
      });
      if (response.status === 200) {
        await dispatch(setSavingState(false));
        return response?.data;
      } else {
        await dispatch(setSavingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setSavingState(false));
    }
  }
);

const cloneTestCaseSet = createAsyncThunk(
  "cloneTestCaseSet",
  async ({ testCaseSetId, title }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    const data = {
      title: title,
    };
    try {
      const response = await apiAgent({
        method: "POST",
        url: `/api/v1/test_case_sets/${testCaseSetId}/clone`,
        data: data,
      });
      if (response.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

const fetchTestCaseSetList = createAsyncThunk(
  "fetchTestCaseSetList",
  async (
    { testScenarioId, pageNumber, pageSize = 10 },
    { dispatch, rejectWithValue }
  ) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: "GET",
        url: `/api/v1/test_case_sets/?test_scenario_id=${testScenarioId}`,
      });
      if (response.status === 200) {
        await dispatch(
          updateTestCaseSetList({
            testCaseSetList: response?.data?.test_case_sets,
          })
        );
        await dispatch(setLoadingState(false));
        return response?.data?.test_case_sets || [];
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

const updateTestCaseSet = createAsyncThunk(
  "updateTestCaseSet",
  async (
    {
      entityId,
      requestType,
      requestUrl,
      dataSetId,
      title,
      testCaseSetId,
      delay_seconds
    },
    { dispatch, rejectWithValue }
  ) => {
    await dispatch(setSavingState(true));
    const data = {};
    if (title) data.title = title;
    if (entityId) data.entity_id = entityId;
    if (requestType) data.request_type = requestType;
    if (requestUrl) data.url = requestUrl;
    if (dataSetId) data.data_set_id = dataSetId;
    if (delay_seconds) data.delay_seconds = delay_seconds;
    try {
      const response = await apiAgent({
        method: "PUT",
        url: `/api/v1/test_case_sets/${testCaseSetId}`,
        data: data,
      });
      if (response.status === 200) {
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      await dispatch(setSavingState(false));
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const deleteTestCaseSet = createAsyncThunk(
  "deleteTestCaseSet",
  async ({ testCaseSetId }, { dispatch, rejectWithValue }) => {
    await dispatch(setSavingState(true));
    try {
      const response = await apiAgent({
        method: "DELETE",
        url: `/api/v1/test_case_sets/${testCaseSetId}`,
      });
      if (response.status === 204) {
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

export {
  createTestCaseSet,
  fetchTestCaseSetList,
  updateTestCaseSet,
  deleteTestCaseSet,
  cloneTestCaseSet,
};
