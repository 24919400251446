import { apiAgent } from "../../config/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    updateLoadError,
    setSavingState
} from "./testSuitePlanSlice";

const attachPlansWithSuites = createAsyncThunk(
    "attachPlansWithSuites",
    async ({testSuiteIds, testPlanIds}, {dispatch, rejectWithValue}) => {
        await dispatch(setSavingState(true));
        try {
            const response = await apiAgent({
                method: "POST",
                url: '/api/v1/test_suite_plans/attach',
                data: {
                    test_suite_ids: testSuiteIds,
                    test_plan_ids: testPlanIds
                }
            });
            if (response.status === 200) {
                await dispatch(setSavingState(false));
                return response?.data;
            }
        } catch (err) {
            dispatch(updateLoadError({ error_message: err.response.data.message }));
        }
    }
);

const detachPlanFromSuite = createAsyncThunk(
    "detachPlanFromSuite",
    async ({testSuiteId, testPlanId}, {dispatch, rejectWithValue}) => {
        await dispatch(setSavingState(true));
        try {
            const response = await apiAgent({
                method: "POST",
                url: `/api/v1/test_suite_plans/detach`,
                data: {
                    test_suite_id: testSuiteId,
                    test_plan_id: testPlanId
                }
            });
            if (response.status === 204) {
                await dispatch(setSavingState(false));
                return response?.data;
            }
        } catch (err) {
            dispatch(updateLoadError({ error_message: err.response.data.message }));
        }
    }
);

export {
    attachPlansWithSuites,
    detachPlanFromSuite
}
