import { apiAgent } from '../../config/axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  updateLoadError,
  setLoadingState,
  setSavingState,
} from './variableSlice';

const createVariable = createAsyncThunk(
  'createVariable',
  async (
    { resourceId, resourceType, var_name, var_value },
    { dispatch, rejectWithValue }
  ) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'POST',
        url: '/api/v1/variables',
        data: {
          var_name,
          var_value,
          resource_type: resourceType,
          resource_id: resourceId,
        },
      });
      if (response.status === 200) {
        // await dispatch(setSelectedTestScenario({testScenarioId: response?.data?.id}))
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const updateVariable = createAsyncThunk(
  'updateVariable',
  async ({ variableValue, variableId }, { dispatch, rejectWithValue }) => {
    await dispatch(setSavingState(true));
    try {
      const response = await apiAgent({
        method: 'PUT',
        url: `/api/v1/variables/${variableId}`,
        data: {
          var_value: variableValue,
        },
      });
      if (response.status === 200) {
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const deleteVariable = createAsyncThunk(
  'deleteVariable',
  async ({ variableId }, { dispatch, rejectWithValue }) => {
    await dispatch(setSavingState(true));
    try {
      const response = await apiAgent({
        method: 'DELETE',
        url: `/api/v1/variables/${variableId}`,
      });
      if (response.status === 200) {
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const getVariable = createAsyncThunk(
  'getVariable',
  async ({ variableId }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'GET',
        url: `/api/v1/variables/${variableId}`,
      });
      if (response.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

const getAllVariables = createAsyncThunk(
  'getAllVariables',
  async ({ resourceId }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'GET',
        url: `/api/v1/variables/?resource_id=${resourceId}`,
      });
      if (response.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data?.test_rules;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

export {
  createVariable,
  updateVariable,
  deleteVariable,
  getVariable,
  getAllVariables,
};
