import { apiAgent } from '../../config/axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  updateLoadError,
  setLoadingState,
  setCollectionForks,
} from './collectionForksSlice';

const getCollectionForks = createAsyncThunk(
  'getCollectionForks',
  async ({ projectId, pageSize, pageNumber }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'GET',
        url: `/api/v1/collection_fork_infos/?project_id=${projectId}&page=${pageNumber}&page_size=${pageSize}`,
      });
      if (response.status === 200) {
        await dispatch(
          setCollectionForks({
            collectionForks: response?.data?.collection_fork_infos,
            totalPages: response?.data?.total_pages,
            total: response?.data?.total
          })
        );
        await dispatch(setLoadingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

export {
  getCollectionForks
};
