import React, { useState } from 'react';
import { Typography, Box, Button, Container, Grid, Card, InputLabel, TextField } from '@mui/material';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import { sendPasswordResetInstructions } from 'redux-store/currentUserActions';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';


const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
    const { openSnackbar } = useSnackbar();
    const dispatch = useDispatch(); 
    const history = useHistory();

  const validateEmail = () => {
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setError('Please enter a valid email.');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateEmail()) return;
  
    setIsSubmitting(true);
    try {
      const res = await dispatch(sendPasswordResetInstructions(email));
      if (!res.error) {
        openSnackbar({
          message: 'Password reset instructions have been sent to your email.',
          severity: 'success'
        });
          setEmail('');
          history.push('/login');
      } else {
        openSnackbar({
          message: res.payload?.message || 'Failed to send reset instructions.',
          severity: 'error'
        });
      }
    } catch (error) {
      openSnackbar({
        message: 'Failed to send reset instructions.',
        severity: 'error'
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Container maxWidth="md" className="full-height">
      <Grid container spacing={2} className="d-flex justify-content-center align-items-center h-100">
        <Grid item xs={12} md={6} className="column-margin">
          <Card className="login-grid-card">
            <div className="right-column">
              <Box className="heading-container">
                <Box className="create-account-title">
                  Forgot Password
                </Box>
              </Box>
              <Box>
                <InputLabel htmlFor="email" className="input-label">Email</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  className="input-field"
                  variant="outlined"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError('');
                  }}
                  error={!!error}
                  helperText={error || ' '}
                />
              </Box>
              <div className="btn_login">
                <Button 
                  variant="contained" 
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  size="medium" 
                  style={{ padding: '10px 0', width: '100%' }}
                >
                  {isSubmitting ? 'Sending...' : 'Send Instructions'}
                </Button>
              </div>
              <Box 
                className="mb-4 mt-4" 
                sx={{ 
                  textAlign: 'center',
                  color: 'text.secondary',
                  fontSize: '14px'
                }}
              >
                Remember your password?{' '}
                <a 
                  href="/login" 
                  className="signin-link"
                  style={{
                    color: '#6241d4',
                    textDecoration: 'none',
                    fontWeight: 500,
                    marginLeft: '4px'
                  }}
                >
                  Sign In
                </a>
              </Box>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ForgotPassword;