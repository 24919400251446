import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingOverlay from "../Common/LoadingOverlay";
import KeyValueTable from "../Common/KeyValueTable";

import {
  attachVariableSet,
  detachVariableSet,
  getVariableSet,
  getAllVariableSets,
} from "../../redux-store/variableSetReducers/variableSetActions";
import {
  deleteVariable,
  createVariable,
  updateVariable,
} from "../../redux-store/variableReducers/variableActions";
import { updateSelectedTestScenarioDetail } from "../../redux-store/testScenarioReducers/testScenarioSlice";

const TestScenarioVariables = function (props) {
  const { testScenarioId, projectId } = props;
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.variableSet.isLoading);
  const testScenarioDetail = useSelector(
    (state) => state.testScenario.selectedTestScenarioDetail
  );
  const [variablesMap, setVariablesMap] = useState({});
  const [variables, setVariables] = useState([]);
  const [attachedVariableSetIds, setAttachedVariableSetIds] = useState([]);
  const [attachedVariableSetMap, setAttachedVariableSetMap] = useState({});
  const [allVariableSets, setAllVariableSets] = useState([]);
  const [variableSetErrorMsg, setVariableSetErrorMsg] = useState("");
  const [variableErrorMsg, setVariableErrorMsg] = useState("");
  const [selectedVariableSet, setSelectedVariableSet] = useState(null);
  const [expandedVariableSet, setExpandedVariableSet] = useState("");
  const [variableSetMap, setVariableSetMap] = useState({});

  useEffect(() => {
    fetchAllVariableSets();
  }, [projectId]);

  useEffect(() => {
    if (testScenarioDetail?.variable_set_resources) {
      setInitialVariables(testScenarioDetail);
    }
  }, [testScenarioDetail]);

  const fetchAllVariableSets = async () => {
    const allVariableSetsResponse = await dispatch(
      getAllVariableSets({ projectId, pageNumber: 1, isEnvironment: false })
    );
    const variableSets = allVariableSetsResponse?.payload?.variable_sets;
    setAllVariableSets(
      variableSets?.map((vSet) => ({ ...vSet, isSelected: false }))
    );
  };

  const setInitialVariables = (testScenarioDetail) => {
    const { variable_set_resources, variables } = testScenarioDetail;
    
    // Reset maps and arrays
    const newVariablesMap = {};
    const attachedVarSetMap = {};
    
    // Format and set variables
    const formattedVariables = variables.map((v) => formatVariableResponse(v));
    setVariables(formattedVariables);
    
    formattedVariables.forEach((v) => {
      newVariablesMap[v.id] = { ...v };
    });
    setVariablesMap(newVariablesMap);

    // Handle variable sets
    const variableSetIds = variable_set_resources.map((vSet) => {
      attachedVarSetMap[vSet.variable_set.id] = vSet;
      return vSet.variable_set.id;
    });
    
    setAttachedVariableSetIds(variableSetIds);
    setAttachedVariableSetMap(attachedVarSetMap);
  };

  const formatVariableResponse = (variable) => {
    return {
      id: variable.id,
      key: variable.var_name,
      var_name: variable.var_name, 
      var_value: variable.var_value,
      value: variable.var_value,
      isNew: variable.isNew,
      isEdit: variable.isEdit,
    };
  };

 
  const onVariableSetAttach = async (event, variableSet) => {
    if (!variableSet) return;
    
    setVariableSetErrorMsg("");
    let variableSetDetail;
    
    try {
      if (variableSetMap[variableSet.id]) {
        variableSetDetail = variableSetMap[variableSet.id];
      } else {
        const variableSetDetailResponse = await dispatch(
          getVariableSet({ variableSetId: variableSet.id })
        );
        variableSetDetail = variableSetDetailResponse.payload;
        setVariableSetMap((prevState) => ({
          ...prevState,
          [variableSet.id]: { ...variableSetDetail },
        }));
      }
  
      const isValid = validateVariableSetCollission(variableSetDetail);
      if (isValid.success) {
        const attachedVariableSetResponse = await dispatch(
          attachVariableSet({
            resourceId: testScenarioId,
            resourceType: "TestScenario",
            variableSetId: variableSet.id,
          })
        );
        
        if (attachedVariableSetResponse?.payload) {
          // Update Redux state
          const updatedTestScenario = {
            ...testScenarioDetail,
            variable_set_resources: [
              ...testScenarioDetail.variable_set_resources,
              attachedVariableSetResponse.payload
            ]
          };
          
          dispatch(updateSelectedTestScenarioDetail({
            testScenarioDetail: updatedTestScenario
          }));
  
          // Clear selected value
          setSelectedVariableSet(null);
        }
      } else {
        setVariableSetErrorMsg(isValid.msg);
      }
    } catch (error) {
      console.error('Error attaching variable set:', error);
      setVariableSetErrorMsg('Failed to attach variable set');
    }
  };


  
  const onVariableSetDetach = async (variableResourceSet) => {
    try {
      const response = await dispatch(
        detachVariableSet({ variableSetResourceId: variableResourceSet.id })
      );
      
      if (response?.payload?.success) {         // Update Redux state
        const updatedTestScenario = {
          ...testScenarioDetail,
          variable_set_resources: testScenarioDetail.variable_set_resources
            .filter(vsr => vsr.id !== variableResourceSet.id)
        };
        
        dispatch(updateSelectedTestScenarioDetail({
          testScenarioDetail: updatedTestScenario
        }));
      }
    } catch (error) {
      console.error('Error detaching variable set:', error);
    }
  };

  const validateVariableSetCollission = (variableSet) => {
    const newVariablesKey = variableSet.variables.map((v) => v.var_name);
    let isDuplicate = false;
    let duplicateWith;

    for (const vSetId of attachedVariableSetIds) {
      const variablesKey = attachedVariableSetMap[
        vSetId
      ].variable_set.variables.map((v) => v.var_name);
      for (const k of newVariablesKey) {
        if (variablesKey.indexOf(k) !== -1) {
          isDuplicate = true;
          duplicateWith = vSetId;
          break;
        }
      }
      if (isDuplicate) break;
    }

    return isDuplicate
      ? {
          msg: `This variable set has duplicate key/s with already added variable set present at Serial number ${
            attachedVariableSetIds.indexOf(duplicateWith) + 1
          } titled '${
            attachedVariableSetMap[duplicateWith].variable_set.title
          }'`,
        }
      : { success: true };
  };

  const validateVariableKey = (variableKey) => {
    const trimmedKey = variableKey ? variableKey.trim() : "";
    if (!trimmedKey) {
      setVariableErrorMsg("Please enter a valid key");
      return false;
    }
    const matchedKeys = Object.keys(variablesMap).filter(
      (k) => variablesMap[k].key === variableKey
    );
    if (matchedKeys.length > 0) {
      setVariableErrorMsg("Key name collides with other variable key");
      return false;
    }
    return true;
  };


  const onCreateVariable = async ({ var_name, var_value }) => {
    setVariableErrorMsg("");
    if (!validateVariableKey(var_name)) {
      return false;
    }
    const data = {
      resourceId: testScenarioId,
      resourceType: "TestScenario",
      var_name: var_name,
      var_value: var_value,
    };
    const newVariableResponse = await dispatch(createVariable(data));
    if (newVariableResponse?.payload?.variable) {
      const variable = newVariableResponse.payload.variable;
      // Update Redux state
      const updatedTestScenario = {
        ...testScenarioDetail,
        variables: [...testScenarioDetail.variables, variable]
      };
      dispatch(updateSelectedTestScenarioDetail({
        testScenarioDetail: updatedTestScenario
      }));
      updateVariableDetail(variable.id, variable);
    }
    return true;
  };

  const updateVariableDetail = (id, variable) => {
    const formattedVariable = formatVariableResponse(variable);
    updateVariableList(id, formattedVariable);
    updateVariableMap(id, formattedVariable);
  };

  const updateVariableList = (id, variableDetail) => {
    const variableIndex = variables.findIndex(
      (variableObj) => variableObj.id === id
    );
    setVariables((prevState) => {
      if (variableIndex === -1) {
        return [...prevState, variableDetail];
      }
      const newState = [...prevState];
      newState[variableIndex] = {
        ...prevState[variableIndex],
        ...variableDetail,
      };
      return newState;
    });
  };

  const updateVariableMap = (id, variableDetail) => {
    setVariablesMap((prevState) => ({
      ...prevState,
      [variableDetail.id]: { ...variableDetail },
    }));
  };

  const onSaveVariable = async ({ id, value }) => {
    const data = {
      variableValue: value,
      variableId: id,
    };
    const variableResponse = await dispatch(updateVariable(data));
    if (variableResponse?.payload?.variable) {
      updateVariableDetail(id, variableResponse.payload.variable);
    }
  };

  const onDeleteVariable = async (id) => {
    try {
      const response = await dispatch(deleteVariable({ variableId: id }));
      
      if (response?.status === 204) {
        // Update Redux state
        const updatedVariables = testScenarioDetail.variables.filter(v => v.id !== id);
        const updatedTestScenario = {
          ...testScenarioDetail,
          variables: updatedVariables
        };
        
        await dispatch(updateSelectedTestScenarioDetail({
          testScenarioDetail: updatedTestScenario
        }));
  
        // Update local state
        setVariables(prevVariables => 
          prevVariables.filter(variable => variable.id !== id)
        );
        
        // Clear the variable from the map
        setVariablesMap(prevMap => {
          const newMap = { ...prevMap };
          delete newMap[id];
          return newMap;
        });
      }
    } catch (error) {
      console.error('Error deleting variable:', error);
    }
  };

 
  const getVariableSetOptions = () => {
    if (allVariableSets && allVariableSets.length > 0) {
      // Change indexOf to better filtering logic
      return allVariableSets.filter((vSet) => {
        return !testScenarioDetail.variable_set_resources.some(
          vsr => vsr.variable_set.id === vSet.id
        );
      });
    }
    return [];
  };

  const onExpandVariableSet = (id) => {
    setExpandedVariableSet(id === expandedVariableSet ? "" : id);
  };

  const renderAttachedVariableSet = () => {
    if (attachedVariableSetIds.length === 0) return "No attached variable set";
    return attachedVariableSetIds.map((id) => {
      const variableResourceSet = attachedVariableSetMap[id];
      return (
        <Accordion
          key={id}
          expanded={expandedVariableSet === id}
          onChange={() => onExpandVariableSet(id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography>{variableResourceSet?.variable_set.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <KeyValueTable
              allItems={variableResourceSet?.variable_set?.variables.map(v => ({
                key: v.var_name,
                value: v.var_value,
                id: v.id
              }))}
              isReadOnly
            />
          </AccordionDetails>
          <AccordionActions>
            <Button onClick={() => onVariableSetDetach(variableResourceSet)}>
              Detach
            </Button>
          </AccordionActions>
        </Accordion>
      );
    });
  };

  return (
    <Box sx={{ p: 2, color: "black" }} className="editDetail">
      <Card variant="outlined" sx={{ border: "none" }}>
        <CardContent>
          <h5> Select variables sets to attach </h5>
         
          <Autocomplete
  options={getVariableSetOptions()}
  getOptionLabel={(option) => option.title}
  id="variableSet"
  value={selectedVariableSet}
  onChange={onVariableSetAttach}
  renderInput={(params) => (
    <TextField {...params} variant="standard" />
  )}
  sx={{ margin: "0 0 8px", width: "auto" }}
  key={testScenarioDetail.variable_set_resources.length} // Add this to force re-render
  isOptionEqualToValue={(option, value) => option.id === value?.id}
/>
          <h5 style={{ marginBottom: "16px", marginTop: "32px" }}>
            Attached variables sets
          </h5>
          {renderAttachedVariableSet()}
          {variableSetErrorMsg && (
            <p style={{ color: "red" }}>{variableSetErrorMsg}</p>
          )}
          <h5 style={{ marginTop: "32px" }}>Create custom variables</h5>
          <KeyValueTable
            allItems={variables}
            onCreateItem={onCreateVariable}
            onUpdateItem={onSaveVariable}
            onDeleteItem={onDeleteVariable}
            itemName="variable"
            onValidate={() => {}}
          />
          {variableErrorMsg && (
            <p style={{ color: "red" }}>{variableErrorMsg}</p>
          )}
        </CardContent>
      </Card>
      <LoadingOverlay isLoading={isLoading} />
    </Box>
  );
};

export default TestScenarioVariables;