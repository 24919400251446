import React, { useState, useEffect, useRef, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Avatar,
  Button
} from "@mui/material";
import {
  ExpandMore,
  GradingOutlined,
  FormatListBulletedOutlined,
  CheckCircleOutline,
  ErrorOutline,
  HourglassTopOutlined,
  CallMissedOutgoingOutlined,
  RefreshOutlined,
  FileDownloadOutlined
} from "@mui/icons-material";
import BuildLogsContainer from "./BuildLogsContainer";
import LoadingOverlay from "../LoadingOverlay";
import MainLayout from "../../../layouts/MainLayout";
import { isEmpty } from "lodash";

const BuildLevel = memo(
  ({
    buildType,
    buildId,
    getBuild,
    getLevelInfo,
    expanded = false,
    root = false,
  }) => {
    const expandedBuildsRef = useRef({ [buildType]: [] });
    const [isExpanded, setIsExpanded] = useState(expanded);
    const [buildDetail, setBuildDetail] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const testPlanBuilds = useSelector((state) => state.testPlanBuild.builds);
    const testSuiteBuilds = useSelector((state) => state.testSuiteBuild.builds);
    const testScenarioBuilds = useSelector(
      (state) => state.testScenarioBuild.builds
    );
    const testPlanBuildsLoading = useSelector(
      (state) => state.testPlanBuild.isLoading
    );
    const testSuiteBuildsLoading = useSelector(
      (state) => state.testSuiteBuild.isLoading
    );
    const testScenarioBuildsLoading = useSelector(
      (state) => state.testScenarioBuild.isLoading
    );

    const handleExport = async () => {
      try {
        const logFileUrl = buildDetail?.log_file_url;

        if (!logFileUrl) {
          console.error('Log file URL not found');
          return;
        }

        const response = await fetch(logFileUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/octet-stream',
            'Content-Disposition': 'attachment'
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const source_code = buildDetail?.test_scenario?.code || buildDetail?.test_suite?.code || buildDetail?.test_plan?.code;
        link.setAttribute('download', `${source_code}-${buildDetail?.build_number}.txt`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

      } catch (error) {
        console.error('Error downloading file:', error);
      }
    };

    useEffect(() => {
      if (expanded) {
        setIsExpanded(expanded);
        getBuildDetail(true);
      }
    }, [buildId, buildType]);

    useEffect(() => {
      const savedBuildDetail = isBuildPresent();
      if (savedBuildDetail) setBuildDetail({ ...savedBuildDetail });
    }, [testPlanBuilds, testSuiteBuilds, testScenarioBuilds]);

    useEffect(() => {
      if (buildType === "testPlan")
        setIsLoading(testPlanBuildsLoading);
      if (buildType === "testSuite")
        setIsLoading(testSuiteBuildsLoading);
      if (buildType === "testScenario")
        setIsLoading(testScenarioBuildsLoading);
    }, [testPlanBuildsLoading, testSuiteBuildsLoading, testScenarioBuildsLoading]);

    const getBuildDetail = (force) => {
      const savedBuildDetail = isBuildPresent();
      const levelInfo = getLevelInfo(buildType);
      if (
        force ||
        !savedBuildDetail ||
        !savedBuildDetail?.[levelInfo?.childBuildKey] ||
        savedBuildDetail?.status === "running" ||
        savedBuildDetail?.status === "queued"
      ) {
        getBuild(buildType, buildId);
      }
    };

    const refreshLogs = (force=true) => {
      getBuildDetail(force);
    }

    const isBuildPresent = () => {
      switch (buildType) {
        case "testPlan":
          return testPlanBuilds?.[buildId];
        case "testSuite":
          return testSuiteBuilds?.[buildId];
        case "testScenario":
          return testScenarioBuilds?.[buildId];
      }
      return null;
    };

    const shouldRefetch = (build, levelInfo) => {
      if (!build?.[levelInfo.childBuildKey]) {
        return true;
      }
    };

    const onExpand = () => {
      let expandedBuilds = expandedBuildsRef.current[buildType] || [];
      if (expandedBuilds.indexOf(buildId) === -1) {
        expandedBuilds = [...expandedBuilds, buildId];
        setIsExpanded(true);
      } else {
        const buildIndex = expandedBuilds.findIndex((id) => id === buildId);
        expandedBuilds = [
          ...expandedBuilds.slice(0, buildIndex),
          ...expandedBuilds.slice(buildIndex + 1),
        ];
        setIsExpanded(false);
      }
      expandedBuildsRef.current = {
        ...expandedBuildsRef.current,
        [buildType]: expandedBuilds,
      };
      getBuildDetail(true);
    };

    const getStatus = (statusType, size="small") => {
      let status;
      let statusIcon;
      if (statusType === "fail") {
        status = <span style={{ color: "#CE4141" }}>Failed</span>;
        statusIcon = (<ErrorOutline sx={{ color: "#CE4141", mr: 1 }} size={size} />);
      }
      if (statusType === "pass") {
        status = <span style={{ color: "#2CAC0C" }}>Passed</span>;
        statusIcon = (<CheckCircleOutline sx={{ color: "#2CAC0C", mr: 1 }} size={size} />);
      }
      if (statusType === "running" || statusType === "queued" || statusType === "skipped") {
        status = <span style={{ textTransform: "capitalize" }}>{statusType}</span>;
        statusIcon = (statusType === "running" || statusType === "queued") ? (<HourglassTopOutlined sx={{ mr: 1 }} size={size} />) : (<CallMissedOutgoingOutlined sx={{ mr: 1 }} size={size} />);
      }
      return {status, statusIcon}
    }

    const renderContentHeading = () => {
      return (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            p: 2,
            pt: 0,
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <h5 style={{color: "black", margin: 0}}>
              <FormatListBulletedOutlined sx={{ mr: 1 }} />
              Execution Logs
            </h5>
            <RefreshOutlined
              onClick={refreshLogs}
              sx={{cursor: "pointer", width: "24px", height: "24px", color: "black"}}
            />
          </Stack>
          {buildDetail?.log_file_url && (
        <Button
          variant="contained"
          size="small"
          startIcon={<FileDownloadOutlined />}
          onClick={handleExport}
          sx={{
            backgroundColor: '#1976d2',
            textTransform: 'none',
            color: 'white',
            '&:hover': {
              backgroundColor: '#1565c0',
            }
          }}
        >
          Export
        </Button>
      )}
        </Stack>
      );
    };

    const renderMessage = (build) => {
      let msg = [];
      if (build.total_count >= 0) {
        msg.push(
          <span style={{ marginRight: "4px" }} key="total_count">
            {build.total_count} {buildType === "testScenario" ? " total cases" : " total scenarios"}
          </span>
        );
      }
      if (build.passed_count > 0) {
        msg.push(
          <span style={{ color: "#2CAC0C", marginRight: "0px" }} key="passed_count">
            , {build.passed_count} passed
          </span>
        );
      }
      if (build.failed_count > 0) {
        msg.push(
          <span style={{ color: "#CE4141", marginRight: "0px" }} key="failed_count">
            , {build.failed_count} failed
          </span>
        );
      }
      if (build.skipped_count > 0) {
        msg.push(
          <span style={{ color: "grey", marginRight: "0px" }} key="skipped_count">
            , {build.skipped_count} skipped
          </span>
        );
      }
      return msg;
    };

    const renderHeader = () => {
      const levelInfo = getLevelInfo(buildType);
      const {status, statusIcon} = getStatus(buildDetail?.status, "large");
      const onViewSource = () => {
        let url;
        if (buildDetail?.test_scenario?.id) {
          url = `${window.location.origin}/test-scenarios/${buildDetail.test_scenario.id}/`;
        } else if (buildDetail?.test_suite?.id) {
          url = `${window.location.origin}/test-suites/${buildDetail.test_suite.id}/`;
        } else if (buildDetail?.test_plan?.id) {
          url = `${window.location.origin}/test-plans/${buildDetail.test_plan.id}/`;
        }
        window.open(
          url,
          "_blank"
        );
      }

      return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
          <Box>
            <h4 style={{color: "black", fontSize: '24px', marginBottom: '16px'}}>
              <GradingOutlined sx={{ mr: 1 }} />
              {buildDetail?.test_scenario?.title || buildDetail?.test_suite?.title || buildDetail?.test_plan?.title}:{" "}
              {buildDetail?.test_scenario?.code || buildDetail?.test_suite?.code || buildDetail?.test_plan?.code}
              - Build #
              {buildDetail?.build_number}
            </h4>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ color: "black" }} >
              {statusIcon}
              {status}
              <Divider sx={{ borderWidth: "1px", height: "20px", borderColor: "#4d4d4d", width: 0 }} />
              <span style={{ textTransform: "capitalize" }}>
                Environment - {buildDetail?.environment_set?.title}
              </span>
              <Divider sx={{ borderWidth: "1px", height: "20px", borderColor: "#4d4d4d", width: 0, }} />
              {buildDetail?.execution_time > 0 && (
                <>
                  <span>{buildDetail?.execution_time?.toFixed(2)} seconds</span>
                  <Divider sx={{ borderWidth: "1px", height: "20px", borderColor: "#4d4d4d", width: 0, }} />
                </>
              )}
              {
                (buildDetail?.test_scenario?.code || buildDetail?.test_suite?.code || buildDetail?.test_plan?.code) &&
                (
                  <>
                    <span>Source : </span>
                    <span onClick={onViewSource} style={{cursor: "pointer", textDecoration: "underline"}}>
                      {buildDetail?.test_scenario?.code || buildDetail?.test_suite?.code || buildDetail?.test_plan?.code}
                    </span>
                  </>
                )
              }
              <span style={{ textTransform: "capitalize" }}>
                {renderMessage(buildDetail)}
              </span>
            </Stack>
          </Box>
        </Box>
      );
    };

    const renderSummary = () => {
      const levelInfo = getLevelInfo(buildType);
      const {status, statusIcon} = getStatus(buildDetail?.status);
      const source_code = buildDetail?.test_scenario?.code || buildDetail?.test_suite?.code || buildDetail?.test_plan?.code;
      return (
        <>
          <div style={{ width: "calc(100% - 200px)", display: "inline-block" }}>
            <h6>{source_code}: {buildDetail?.[levelInfo?.id]?.title}</h6>
          </div>
          <div style={{ width: "200px", display: "inline-block"}}>
            <Stack direction="row-reverse" spacing={1} sx={{ color: "black", mb: 1}} >
              {buildDetail?.execution_time > 0 &&
                <>
                  <span>{buildDetail?.execution_time?.toFixed(2)} seconds</span>
                  <Divider sx={{ borderWidth: "1px", height: "20px", borderColor: "#4d4d4d", width: 0, }} />
                </>
              }
              {status}
              {statusIcon}
            </Stack>
          </div>
        </>
      );
    };

    const renderDetails = () => {
      const levelInfo = getLevelInfo(buildType);
      const childBuildKey = levelInfo.childBuildKey;
      const childBuildType = levelInfo.childBuildType;
      const builds = buildDetail[childBuildKey];
      if (levelInfo.childType === "log") {
        return <BuildLogsContainer
          key={buildDetail?.id}
          buildId={buildDetail?.id}
          buildType={levelInfo.childType}
          getLevelInfo={getLevelInfo}
          getBuild={getBuild}
          expanded={false}
          getStatus={getStatus}
        />
      }
      if (builds) {
        return builds.map((build) => {
          const nextLevelInfo = getLevelInfo(childBuildType);
          if (nextLevelInfo.type === "level") {
            return (
              <BuildLevel
                key={build?.id}
                buildType={childBuildType}
                buildId={build?.id}
                expanded={false}
                getLevelInfo={getLevelInfo}
                getBuild={getBuild}
              />
            );
          }
        });
      } else {
        return <div>Loading...</div>;
      }
    };

    if (!buildDetail || isEmpty(buildDetail)) {
      return <LoadingOverlay />;
      // return <div>Loading...</div>;
    } else if (root) {
      return (
        <MainLayout renderHeader={renderHeader} sxLeftSide={{maxWidth: "80%"}}>
          {renderContentHeading()}
          <Box sx={{p: 2, maxHeight: "calc(100vh - 200px)", overflow: "auto", color: "black", mb: 1}}>
            {renderDetails()}
          </Box>
        </MainLayout>
      )
    } else {
      return (
        <Box sx={{ color: "black", mb: 1 }}>
          <Accordion expanded={isExpanded} onChange={onExpand} sx={{boxShadow: "none", border: "1px solid #e4e4e4" }}>
            <AccordionSummary
              aria-controls="build-content"
              id="build-header"
            >
              {renderSummary()}
            </AccordionSummary>
            <AccordionDetails>{isExpanded && renderDetails()}</AccordionDetails>
          </Accordion>
        </Box>
      )
    }
  }
);

export default BuildLevel;