import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Add, CreateOutlined } from "@mui/icons-material";
import { Chip, Stack, MenuItem, Menu, Tooltip } from "@mui/material";
import { getAllTestPlans } from "../../redux-store/testPlanReducers/testPlanActions";
import AttachTestPlanModal from "./AttachTestPlanModal";
import CreateTestPlanModal from "./CreateTestPlanModal";

const TestPlanAttach = ({ projectId }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const allTestPlans = useSelector((state) => state.testPlan.testPlansList);
  const selectedTestSuites = useSelector((state) => state.testSuite.selectedTestSuites);
  const isDisabled = !selectedTestSuites?.length;

  useEffect(() => {
    if (!allTestPlans || allTestPlans.length === 0) fetchAllTestPlans();
  }, []);

  const fetchAllTestPlans = async () => {
    await dispatch(
      getAllTestPlans({
        projectId: projectId,
        pageSize: 100000,
        pageNumber: 1,
      })
    );
  };

  const handleClick = (event) => {
    if (isDisabled) return;
    setAnchorEl(
      event.currentTarget.tagName === "svg"
        ? event.currentTarget.parentNode
        : event.currentTarget
    );
  };

  const onCreate = () => {
    setAnchorEl("");
    setShowCreateModal(true);
  };

  const onAdd = () => {
    setAnchorEl("");
    setShowAddModal(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowAddModal(false);
    setShowCreateModal(false);
  };

  return (
    <>
      <Tooltip 
        title="To Create/Add to Test Plan, select the active test suites"
        placement="top"
        arrow
      >
        <span> 
          <Chip
            id="attach-test-plan"
            label="Create/Add to Test Plan"
            icon={<Add />}
            onClick={handleClick}
            size="small"
            disabled={isDisabled}
            sx={{
              border: "1px solid #6241d4",
              padding: "14px 0",
              borderRadius: "4px",
              fontWeight: 500,
              opacity: isDisabled ? 0.6 : 1,
              cursor: isDisabled ? 'not-allowed' : 'pointer',
              '&.MuiChip-disabled': {
                opacity: 0.6,
                border: "1px solid rgba(98, 65, 212, 0.5)"
              }
            }}
          />
        </span>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ mt: 0.5 }}
        open={Boolean(anchorEl)}
        className="attach-test-plan-menu"
      >
        <Stack spacing={1}>
          <MenuItem onClick={onCreate} sx={{ display: 'flex', alignItems: 'center' }}>
            <CreateOutlined sx={{ mr: 1 }} />
            Create new test plan
          </MenuItem>
          <MenuItem onClick={onAdd} sx={{ display: 'flex', alignItems: 'center' }}>
            <Add sx={{ mr: 1 }} />
            Add to existing test plan
          </MenuItem>
        </Stack>
      </Menu>
      {showAddModal && (
        <AttachTestPlanModal
          projectId={projectId}
          open={showAddModal}
          onClose={handleClose}
          onCancel={handleClose}
        />
      )}
      {showCreateModal && (
        <CreateTestPlanModal
          projectId={projectId}
          open={showCreateModal}
          onClose={handleClose}
          onCancel={handleClose}
        />
      )}
    </>
  );
};

export default TestPlanAttach;