import React, { useReducer, useEffect } from 'react';
import moment from 'moment-timezone';
import { Typography, Box, TextField, Button, Grid, Autocomplete } from '@mui/material';
import coreUtils from '../../utils/coreUtils';

const OrganisationForm = ({ onSubmit, organisation, actionType="new", isLoading }) => {
  const timeZones = moment.tz.names();
  
  const getDefaultTimezone = () => {
    if (organisation?.location) {
      return organisation.location;
    }
    const guessedZone = moment.tz.guess();
    return timeZones.includes(guessedZone) ? guessedZone : 'UTC';
  };

  const [state, setState] = useReducer(reducer, {
    companyName: organisation?.name || "",
    companyLocation: getDefaultTimezone(),
    errors: {},
  });

  // Update timezone if organization data changes
  useEffect(() => {
    if (organisation?.location && organisation.location !== state.companyLocation) {
      setState({
        type: "SET_STATE",
        payload: { companyLocation: organisation.location },
      });
    }
  }, [organisation]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'companyName' && value.length > 50) {
      e.preventDefault();
      return;
    }
    setState({
      type: "SET_STATE",
      payload: { [name]: value },
    });
  };

  const onFormSubmit = async () => {
    var errors = {},
      error = false;

    if (coreUtils.isStringInvalidOrBlank(state.companyName)) {
      errors["companyName"] = "Please enter company name";
      error = true;
    }
    if (coreUtils.isStringInvalidOrBlank(state.companyLocation)) {
      errors["companyLocation"] = "Please select time zone.";
      error = true;
    }

    setState({
      type: "SET_STATE",
      payload: {
        errors: errors,
      },
    });

    if (error) return;

    onSubmit(state);
  };

  return (
    <Box sx={{ my: 4 }}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Typography>Company Name<span style={{color: "grey"}}> (max chars: 50)</span></Typography>
          <TextField
            fullWidth
            size='small'
            variant="outlined"
            name="companyName"
            value={state.companyName}
            onChange={handleInputChange}
            error={!!state.errors.companyName}
            helperText={state.errors.companyName || ''}
            maxLength={50}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Time Zone</Typography>
          <Autocomplete
            fullWidth
            disableClearable
            disablePortal
            size="small"
            value={state.companyLocation}
            onChange={(event, newValue) => {
              setState({
                type: "SET_STATE",
                payload: { companyLocation: newValue },
              });
            }}
            onClick={(event) => {
              console.log(event.path || event.composedPath())
            }}
            options={timeZones}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label=""
                placeholder='Select time zone'
                error={!!state.errors.companyLocation}
                helperText={state.errors.companyLocation || ''}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onFormSubmit}
            disabled={isLoading}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrganisationForm;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE": {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};