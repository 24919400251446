import React from "react";
import { Box, Card, CardContent, Button } from "@mui/material";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const BulkDeleteScenarioModal = ({
  open,
  onClose,
  onDelete,
  selectedCount,
}) => {
  if (!open) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1300,
      }}
    >
      <Card
        variant="outlined"
        sx={{ width: "550px", backgroundColor: "white" }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              mb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <WarningAmberOutlinedIcon sx={{ color: "warning.main", mr: 2 }} />
              <span style={{ fontSize: "24px" }}>
                Delete {selectedCount} Test Scenario
                {selectedCount !== 1 ? "s" : ""}?
              </span>
            </Box>
            <CloseIcon
              onClick={onClose}
              sx={{ cursor: "pointer", color: "text.secondary" }}
            />
          </Box>

          <Box sx={{ my: 3 }}>
            <p>We recommend archiving Test Scenarios instead of deleting.</p>
            <p>
              Archiving disables the Test Scenario from all Test Suites &
              Builds, but you can always access it in 'Archived' Test Scenarios
              for later reference.
            </p>
            <p>
              Are you sure you want to permanently delete the selected Test
              Scenario{selectedCount !== 1 ? "s" : ""}?
            </p>
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              color="error"
              onClick={onDelete}
              startIcon={<DeleteOutlineIcon />}
            >
              Delete Permanently
            </Button>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BulkDeleteScenarioModal;
