import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { Add } from "@mui/icons-material";

import TestPlanList from "./TestPlanList";
import CustomDialog from "../Common/CustomDialog";
import MainLayout from "../../layouts/MainLayout";
import CustomButton from "../Common/CustomButton";

import useSearchParams from "../../hooks/useSearchParams";
import {
  createTestPlan,
  getAllTestPlans,
  deleteTestPlan,
  updateTestPlan,
} from "../../redux-store/testPlanReducers/testPlanActions";
import { getAllVariableSets } from "../../redux-store/variableSetReducers/variableSetActions";
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

const TestPlansListPage = function ({ project }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchParams = useSearchParams();
  const page = parseInt(searchParams.get("page") || "1");
  const { openSnackbar } = useSnackbar();

  const isLoading = useSelector((state) => state.testPlan.isLoading);
  const environmentSets = useSelector(
    (state) => state.variableSet.allVariableSets
  );
  const totalPlans = useSelector((state) => state.testPlan.total);
  const [pageNumber, setPageNumber] = useState(isNaN(page) ? 1 : page);
  const [totalPages, setTotalPages] = useState(0);
  const [testPlanList, setTestPlanList] = useState([]);
  const [buildDialogOpen, setBuildDialogOpen] = useState(false);

  useEffect(() => {
    fetchAllVariables();
  }, []);

  useEffect(() => {
    getTestPlans();

    // keep page query-param in sync with state
    window.history.pushState("", "", `?page=${pageNumber}`);
  }, [pageNumber]);

  const fetchAllVariables = async () => {
    await dispatch(getAllVariableSets({ projectId: project?.id, isEnvironment: true, pageNumber: 1 }));
  };

  const getTestPlans = async () => {
    const testPlansResponse = await dispatch(
      getAllTestPlans({
        projectId: project.id,
        pageNumber: pageNumber
      })
    );
    setTestPlanList(testPlansResponse?.payload?.test_plans);
    setTotalPages(testPlansResponse?.payload?.total_pages);
  };

  const onPageChange = (e, value) => {
    setPageNumber(value);
  };

  const onCreateTestPlan = async (environmentSetId) => {
    const title = `Test Plan ${totalPlans ? totalPlans + 1 : 1}`;
    setBuildDialogOpen(false);
    const response = await dispatch(
      createTestPlan({
        projectId: project.id,
        title: title,
        environmentSetId: environmentSetId,
      })
    );
    if (response?.payload?.id) {
      openSnackbar({
        message: `Test plan ${title} created successfully.`,
        severity: "success",
      });
      history.push(`/test-plans/${response.payload.id}`);
    } else {
      openSnackbar({
        message: `Test plan creation failed!`,
        severity: "error",
      });
    }
  };


  const onArchiveTestPlan = async (testPlanId) => {
    await dispatch(
      updateTestPlan({ testPlanId, data: { status: 'archived' } })
    );
    openSnackbar({
      message: `Test plan archived successfully.`,
      severity: "success",
    });
    getTestPlans();
  };

  const onDeleteTestPlan = async (testPlanId) => {
    await dispatch(deleteTestPlan({ testPlanId: testPlanId }));
    openSnackbar({
      message: `Test plan deleted successfully.`,
      severity: "success",
    });

    if (testPlanList.length === 1 && pageNumber !== 1) {
      onPageChange("", pageNumber - 1 > 0 ? pageNumber - 1 : 1);
    } else {
      getTestPlans();
    }
  };

  const onToggleRunningStatus = async (testPlanId, isPaused) => {
    await dispatch(updateTestPlan({ testPlanId, data: { status: isPaused ? "paused" : "active" } }));
  };

  const onMarkAsActiveTestPlan = async (testPlanId) => {
    await dispatch(updateTestPlan({ testPlanId, data: { status: 'active' } }));
  };

  return (
    <MainLayout
      isLoading={isLoading}
      headerText="Test Plans"
      subtitleText={`Showing ${totalPlans || 0} test plan(s)`}
      infoText="Select your test suites and add them to a plan through the Create/Add to Test Plan button"
      pageNumber={pageNumber}
      totalPages={totalPages}
      onPageChange={onPageChange}
    >
      <Box sx={{maxHeight: "calc(100vh - 180px)", overflow: "auto"}}>
        <TestPlanList
          testPlanList={testPlanList}
          onToggleRunningStatus={onToggleRunningStatus}
          onDelete={onDeleteTestPlan}
          onArchive={onArchiveTestPlan}
          onMarkAsActiveTestPlan={onMarkAsActiveTestPlan}
        />
      </Box>
      <CustomDialog
        open={buildDialogOpen}
        title="Select environment"
        btnText="Create"
        onClose={() => setBuildDialogOpen(false)}
        options={environmentSets || []}
        handleSubmit={(environmentSetId) => onCreateTestPlan(environmentSetId)}
      />
    </MainLayout>
  );
};

export default TestPlansListPage;
