import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  Tab,
  Tabs,
  IconButton
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import QueryParams from './QueryParamsComponent';
import AuthorizationComponent from '../Collections/AuthorizationComponent';
import HeadersComponent from './HeadersComponent.jsx';
import BodyComponent from './BodyComponent';
import SettingPageComponent from './SettingPageComponent';
import { useDispatch, connect } from 'react-redux';
import { renameTab } from '../../redux-store/tabSlice';
import { updateState } from '../../redux-store/currentUserSlice.js';
import {
  sendApiCall,
  updateRequest,
} from '../../redux-store/currentUserActions';
import {
  updateEntity,
  updateEntityNameFromUrl,
} from '../../redux-store/currentUserSlice';
import LoadingOverlay from '../Common/LoadingOverlay';
import AppContext from '../../AppContext';
import { useGuestUser } from '../../contexts/GuestUserContext.js';
import CTAAccountDialog from '../Common/CTAAccountDialog.js';
import ConditionalRender from '../../utils/conditionalRender';
import SaveIcon from '@mui/icons-material/Save';
import APIDefinitionComponent from './APIDefinitionComponent';

const ApiComponent = ({ state, api, entity, onEntitySlugUpdate, selectedSidebarState }) => {
  const dispatch = useDispatch();
  const [method, setMethod] = useState('');
  const [url, setUrl] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams, setQueryParams] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [body, setBody] = useState([]);
  const [bodyType, setBodyType] = useState([]);
  const [openAccountDialog, setOpenAccountDialog] = useState(false);
  const { isGuestUser } = useGuestUser();
  const { setSendClicked } = useContext(AppContext);
  const [apiDefinition, setApiDefinition] = useState('');
  const tabLabels = ['Params', 'Authorization', 'Headers', 'Body', 'Settings', 'Details'];
  const defaultHeaders = [
    { name: 'Accept', value: '*/*', required: true, datatype: "string" },
    { name: 'Accept-Encoding', value: 'identity', required: true, datatype: "string" },
    { name: 'Connection', value: 'keep-alive', required: true, datatype: "string" },
  ];

  useEffect(() => {
    const request = entity?.request;

    // if body_type is  present in the request
    if (request?.body_type) {
      if (Array.isArray(request?.body) && request.body.length > 0) {
        setActiveTab(3);
      } else if (typeof request?.body === 'object' && request.body !== null && Object.keys(request.body).length > 0) {
        setActiveTab(3);
      } else {
        setActiveTab(0);
      }
    } else {    // if it is not present in the request then set the active tab to params.
      setActiveTab(0);
    }

    if (Array.isArray(request?.body) && request.body.length > 0) {
      setActiveTab(3);
    } else if (typeof request?.body === 'object' && request.body !== null && Object.keys(request.body).length > 0) {
      setActiveTab(3);
    } else {
      setActiveTab(0);
    }
    setUrl(request?.url || '');
    setMethod(request?.request_type || '');
    setBody(request?.body);
    setBodyType(request?.body_type);
    setHeaders(getMergedHeaders());
    setQueryParams(request?.params);
    setApiDefinition(request?.api_definition);
  }, [selectedSidebarState, entity, dispatch]);

  const getMergedHeaders = () => {
    const backendHeadersArray = entity?.request?.headers || [];
    const uniqueDefaultHeaders = defaultHeaders.filter(
      (defaultHeader) =>
        !backendHeadersArray.some(
          (backendHeader) => backendHeader.name === defaultHeader.name
        )
    );
    const mergedHeaders = [...uniqueDefaultHeaders, ...backendHeadersArray];
    return mergedHeaders;
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleMethodChange = (event) => {
    const newMethod = event.target.value;
    setMethod(newMethod);
  };

  const handleUrlChange = (event) => {
    const extractedParams = updateParamsFromUrl(event.target.value);
    setUrl(event.target.value);
    setQueryParams(prevState => {
      const formattedParams = extractedParams.map((param, index) => {
        if ((prevState[index] && param) && (prevState[index]?.name === param?.name)) {
          return {...prevState?.[index], name: param?.name, value: param?.value};
        } else {
          return {...(param || {})};
        }
      });
      return formattedParams;
    });
  };

  const updateParamsFromUrl = (requestUrl) => {
    if (!requestUrl) {
      return [];
    }
    try {
      const indexOfQueryStart = requestUrl.indexOf('?');
      if (indexOfQueryStart !== -1) {
        const queryString = requestUrl.substring(indexOfQueryStart + 1);
        const params = new URLSearchParams(queryString);
        const extractedParams = Array.from(params.entries()).map(
          ([key, value]) => ({ name: key, value, description: "", required: false, datatype: "" })
        );
        return extractedParams;
      }
      return [];
    } catch (error) {
      console.error('Error parsing URL parameters', requestUrl, error);
      return [];
    }
  };

  const concatQueryParams = (rows) => {
    const tmpUrl = url || entity?.request?.url || '';
    const validParams = rows.filter((p) => p.name);
    const tParams = buildQueryParams(validParams);
    const indexOfQuery = tmpUrl.indexOf('?');
    const baseUrl =
      indexOfQuery > -1 ? tmpUrl.substring(0, indexOfQuery) : tmpUrl;
    const newUrl = `${baseUrl}${tParams.length ? '?' + tParams : ''}`;
    setUrl(newUrl);
    setQueryParams(validParams);
  };

  const buildQueryParams = (arr) => {
    return arr
      .map((item) => (item.value ? `${item.name}=${item.value}` : item.name))
      .join('&')
      .replace(/&+$/, '');
  };

  const handleSaveRequest = async () => {
    const request = entity?.request;
    if (request) {
      const state = {
        id: request?.id,
        request: {
          url: url,
          request_type: method,
          params: queryParams,
          headers: headers,
          body: body,
          body_type: bodyType,
          api_definition: apiDefinition
        },
      };
      return dispatch(updateRequest(state));
    }
  };

  const handleSendRequest = async () => {
    if (isGuestUser) {
      handleOpenAccountDialog();
      return;
    }
    setIsLoading(true);
    setSendClicked(true);
    // await new Promise(resolve => setTimeout(resolve, 250));
    await handleSaveRequest();
    // await new Promise(resolve => setTimeout(resolve, 250));
    const res = await dispatch(sendApiCall({ id: selectedSidebarState?.id }));
    if (res?.payload?.response) {
      // dispatch(setResponse({ apiId: selectedSidebarState?.id, response: res?.payload?.response }));
      const name = res.payload.entity?.name;
      const slug = res.payload.entity?.slug;
      await dispatch(
        updateEntity({ id: selectedSidebarState?.id, name: name, slug: slug })
      );
      await dispatch(
        updateEntityNameFromUrl({ id: selectedSidebarState?.id, name: name })
      );
      await dispatch(renameTab({id: selectedSidebarState?.id, newName: name}))
      const entity = res.payload.entity;
      const newState = entity?.folder_id ? {folderApi: entity} : {collectionApi: entity};
      await dispatch(updateState(newState));
      onEntitySlugUpdate(slug);
    }
    setIsLoading(false);
  };

  const handleParamsChange = (newParams) => {
    concatQueryParams(newParams);
  };

  const handleHeadersChange = (validHeaders) => {
    setHeaders(validHeaders);
  };

  const handleBodyChange = (body, bodyType) => {
    setBody(body);
    setBodyType(bodyType);
  };

  const getTabStyle = (index) => ({
    textTransform: 'none',
    color: activeTab === index ? '#fff' : '#575757',
    backgroundColor: activeTab === index ? '#D27FFF' : '#fff',
  });

  const handleOpenAccountDialog = () => {
    setOpenAccountDialog(true);
  };

  const handleCloseAccountDialog = () => {
    setOpenAccountDialog(false);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return (
          <QueryParams
            skipOutsideClickCheck
            initialParams={queryParams}
            onParamsChange={handleParamsChange}
          />
        );
      case 1:
        return <AuthorizationComponent state={state} api={api} />;
      case 2:
        return (
          <HeadersComponent
            skipOutsideClickCheck
            headers={headers}
            onHeadersChange={handleHeadersChange}
          />
        );
      case 3:
        return (
          <BodyComponent
            body={body}
            bodyType={bodyType}
            onBodyChange={handleBodyChange}
          />
        );
      case 4:
        return <SettingPageComponent />;
      case 5:
        return (
          <APIDefinitionComponent
            initialValue={apiDefinition}
            onChange={setApiDefinition}
          />
        );
      default:
        return null;
    }
  };

  const tabStyle = {
    height: '30px',
    minHeight: '30px',
    textTransform: 'none',
    color: '#575757',
    backgroundColor: 'transparent',
    fontSize: "11px",
    fontFamily: "Inter, Sans-serif"
  };

  const commonRequestTypeStyle = {
    fontWeight: 600,
    fontFamily: "Inter, Sans-serif",
    fontSize: "12px",
  }

  const getColorByMethod = (method) => {
    switch (method) {
      case "GET":
        return "green";
      case "POST":
        return "#ffb900";
      case "PUT":
        return "blue";
      case "PATCH":
        return "purple";
      case "DELETE":
        return "red";
      case "HEAD":
        return "green";
      case "OPTIONS":
        return "pink";
      default:
        return "black";
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        marginLeft: '10px',
        marginRight: '10px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Select
          size="small"
          value={method}
          onChange={handleMethodChange}
          sx={{
            marginRight: '10px',
            width: '15%',
            fontFamily: "Inter, Sans-serif",
            fontWeight: "600",
            fontSize: "12px",
            color: getColorByMethod(method),
            '& .MuiSelect-select': {
            color: getColorByMethod(method),
          },
           }}
        >
          <MenuItem value="GET" sx={{ color: "green", ...commonRequestTypeStyle }}>
            GET
          </MenuItem>
          <MenuItem value="POST" sx={{ color: '#ffb900', ...commonRequestTypeStyle }}>
            POST
          </MenuItem>
          <MenuItem value="PUT" sx={{ color: 'blue', ...commonRequestTypeStyle }}>
            PUT
          </MenuItem>
          <MenuItem value="PATCH" sx={{ color: 'purple', ...commonRequestTypeStyle }}>
            PATCH
          </MenuItem>
          <MenuItem value="DELETE" sx={{ color: 'red', ...commonRequestTypeStyle }}>
            DELETE
          </MenuItem>
          <MenuItem value="HEAD" sx={{ color: 'green', ...commonRequestTypeStyle }}>
            HEAD
          </MenuItem>
          <MenuItem value="OPTIONS" sx={{ color: 'pink', ...commonRequestTypeStyle }}>
            OPTIONS
          </MenuItem>
        </Select>
        <TextField
          value={url}
          onChange={handleUrlChange}
          variant="outlined"
          size="small"
          fullWidth
          placeholder="Enter request url or paste curl"
          InputProps={{
            sx: {
              fontFamily: "Inter, Sans-serif", fontWeight: "380", fontSize: "12px",
            }
          }}
          sx={{ marginRight: '10px'}}
        />

<Button
  variant="contained"
  onClick={handleSendRequest}
  size="medium"
  endIcon={<ArrowDropDownIcon />}
  style={{
    width: '120px', // Set a fixed width for the button
    fontFamily: "Inter, Sans-serif",
    fontWeight: "550",
    fontSize: "12px",
    textTransform: "none",
  }}
>
  Send
</Button>

<ConditionalRender if={entity?.can_update}>
  <Button
    variant="text"
    onClick={handleSaveRequest}
    size="medium"
    style={{
      display: 'flex',
      alignItems: 'center',
      width: '80px', // Set a fixed width for the button
      fontFamily: "Inter, Sans-serif",
      fontWeight: "480",
      fontSize: "11px",
      textTransform: "none",
      color: '#575757',
    }}
  >
    <IconButton
      onClick={handleSaveRequest}
      size="small"
      style={{
        padding: '0',
        marginRight: '5px',
      }}
    >
      <SaveIcon fontSize="small" color='#575757' /> {/* Set icon size */}
    </IconButton>
    Save
  </Button>
</ConditionalRender>

      </Box>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        sx={{
          marginTop: '10px',
          height: '30px',
          minHeight: '30px',
          border: '1px solid #e9e9e9',
          borderRadius: '5px',
        }}
      >
        {tabLabels.map((label) => (
          <Tab
            key={label}
            label={label}
            sx={getTabStyle(label)}
            style={tabStyle}
          />
        ))}
      </Tabs>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          marginTop: '10px',
          marginBottom: '10px',
          '&::-webkit-scrollbar': { display: 'none' },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        {renderTabContent()}
      </Box>
      <LoadingOverlay isLoading={isLoading} />
      <CTAAccountDialog
        open={openAccountDialog}
        handleClose={handleCloseAccountDialog}
      />
    </Box>
  );
};

function mapStateToProps(state, ownProps){
  return {
    ...ownProps,
    selectedSidebarState: state.user.selectedSidebarState
  }
}

export default connect(mapStateToProps)(ApiComponent);
