import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  ListItemText,
  Checkbox,
  OutlinedInput,
  Button,
  TextField,
  IconButton,
  Typography,
  Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChatWindow from 'components/TestScenarios/TestScenariosValidation/ChatWindow'; 
import { fetchFlatCollectionEntities, fetchProjectCollections } from '../../redux-store/currentUserActions';
import { forceGenerateTestScenario, generateBulkTestScenario, generateSingleIntegrationTest, submitUserInputData } from '../../redux-store/testScenarioReducers/testScenarioActions';
import useTestProcessStatusPolling from '../TestScenarios/TestProcessGeneration/useTestProcessStatusPolling';
import useTestScenarioPolling from '../TestScenarios/TestScenariosValidation/useTestScenarioPolling';
import dayjs from 'dayjs';
import { InfoOutlined } from '@mui/icons-material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ApiChatModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const collections = useSelector((state) => state.user.collections);
  const project = useSelector((state) => state.common.selectedProject);
  const allCollectionEntities = useSelector(state => state.user.flatCollectionEntities || {});
  const [selectedCollection, setSelectedCollection] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [selectedApis, setSelectedApis] = useState([]);
  const [message, setMessage] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [selectedScenarioTypes, setSelectedScenarioTypes] = useState([]);
  const [selectedTestTypes, setSelectedTestTypes] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [defaultTag, setDefaultTag] = useState('');
  const [inputValue, setInputValue] = useState(''); 
  const [showChat, setShowChat] = useState(false);
  const [showChatForMultiple, setShowChatForMultiple] = useState(false);
  const [testProcessData, setTestProcessData] = useState({
    messages: [],
    loading: false,
    user_input_needed: false
  });
  const [testProcessId, setTestProcessId] = useState(null);
  const kPOLLING_INTERVAL = 5000;
  const { data, error: pollingError, startPolling, stopPolling } = useTestProcessStatusPolling(testProcessId, kPOLLING_INTERVAL);
  let [testScenarioProcessId, setTestScenarioProcessId] = useState(null);
  const { 
    data: testScenarioData, 
    error: testScenarioError, 
    startPolling: startTestScenarioPolling, 
    stopPolling: stopTestScenarioPolling 
  } = useTestScenarioPolling(testScenarioProcessId, kPOLLING_INTERVAL);

  const apis = selectedCollection && allCollectionEntities && allCollectionEntities[selectedCollection] 
    ? allCollectionEntities[selectedCollection] 
    : [];

  useEffect(() => {
    if (activeTab === 1) {
      const now = dayjs().format('DD-MMM-HH:mm');
      const tag = `AI-${now}`;
      setDefaultTag(tag);
      setSelectedTags([tag]);
    } else {
      setSelectedTags([]);
    }
  }, [activeTab]);

  useEffect(() => {
    if (open && project?.id) {
      dispatch(fetchProjectCollections(project.id));
    }
  }, [project, open]);

  useEffect(() => {
    if (selectedCollection && allCollectionEntities && allCollectionEntities[selectedCollection]) {
      const collectionApis = allCollectionEntities[selectedCollection];
      const apiIds = collectionApis.map(api => api.id);
      setSelectedApis(apiIds);
    }
  }, [selectedCollection, allCollectionEntities]);

  useEffect(() => {
    if (testProcessId) {
      startPolling();
    }
    return () => {
      stopPolling();
    };
  }, [testProcessId, startPolling, stopPolling]);

  useEffect(() => {
    if (testScenarioData) {
      setTestProcessData(prevData => ({
        ...prevData,
        ...testScenarioData,
        messages: [message, ...testScenarioData.messages],
      }));

      if (testScenarioData.loading === false) {
        stopTestScenarioPolling();
      }
    }
    if (testScenarioError) {
      stopTestScenarioPolling();
      setTestProcessData(prevData => ({
        ...prevData,
        loading: false,
        error: testScenarioError
      }));
    }
  }, [testScenarioData, testScenarioError, stopTestScenarioPolling, message]);

  useEffect(() => {
    if (data) {
      setTestProcessData((prevData) => ({
        ...prevData,
        ...data,
        loading: true
      }));
      if (data.completed === true) {
        stopPolling();
        if (data?.test_scenario?.test_scenario_process_id) {
          setTestScenarioProcessId(data.test_scenario.test_scenario_process_id);
          startTestScenarioPolling();
        } else {
          if (activeTab === 1) {
            setTestProcessData((prevData) => ({
              ...prevData,
              loading: false
            }));
          } 
        }
      }
    }
    if (pollingError) {
      stopPolling();
      setTestProcessData((prevData) => ({
        ...prevData,
        loading: false,
        error: pollingError.message || "An error occurred during polling."
      }));
    }
  }, [data, pollingError, stopPolling, startTestScenarioPolling]);

  const handleCollectionChange = async (event) => {
    const collectionId = event.target.value;
    setSelectedCollection(collectionId);
    if (collectionId) {
      await dispatch(fetchFlatCollectionEntities(collectionId));
    } else {
      setSelectedApis([]);
    }
  };

  const handleApiChange = (event) => {
    const value = event.target.value;
    setSelectedApis(typeof value === 'string' ? value.split(',') : value);
  };

  const handleInitialGenerate = () => {
    if (message.trim()) {
      setTestProcessData(prev => ({
        ...prev,
        messages: [message],
        loading: true,
        user_input_needed: false
      }));
      setShowChat(true);
      startGeneratingSingleTestProcess(message);
    }
  };

  const handleUserSubmit = async (inputData, shouldSaveMsg) => {
    try {
      stopTestScenarioPolling();
      setTestProcessData(prevState => ({
        ...prevState,
        user_input_needed: false,
        loading: true,
        messages: [...prevState.messages, inputData],
      }));

      const response = await dispatch(submitUserInputData({ testScenarioProcessId: testScenarioProcessId, messageToSend: inputData, saveMessage: false })).unwrap();
      setTestProcessData((prevData) => ({
        ...prevData,
        ...response,
      }));

      setTimeout(() => {
        startTestScenarioPolling();
      }, kPOLLING_INTERVAL);
    } catch (err) {
      setTestProcessData({ error: err.message || "Failed to submit data. Please try again." });
    }
  };

  const handleForceGenerateClick = async () => {
    setTestProcessData(prev => ({
      ...prev,
      loading: true,
      user_input_needed: false
    }));
    try {
      await dispatch(forceGenerateTestScenario({testProcessId: testProcessId})).unwrap();
      startPolling();
    } catch (error) {
      setTestProcessData(prev => ({
        ...prev,
        loading: false,
        error: 'Failed to re-generate test'
      }));
    }
  };

  const startGeneratingSingleTestProcess = async (message, saveForLater = false) => {
    const payload = {
      projectId: project.id,
      collectionIds: selectedCollection,
      entityIds: selectedApis,
      description: message,
      tags: selectedTags,
      saveForLater
    };
    try {
      const result = await dispatch(generateSingleIntegrationTest(payload)).unwrap();
      setTestProcessId(result.test_process_id);
      if (result.test_process_id) {
        startPolling();
      }
    } catch (error) {
      setTestProcessData(prev => ({
        ...prev,
        loading: false,
        error: 'Failed to generate test'
      }));
    }
  };

  const startGeneratingBulkTestProcess = async () => {
    setTestProcessData(prev => ({
      messages: additionalInfo ? [additionalInfo] : [],
      loading: true,
      user_input_needed: false
    }));
    setShowChatForMultiple(true);
    const payload = {
      projectId: project.id,
      collectionIds: selectedCollection,
      entityIds: selectedApis,
      additionalPrompt: additionalInfo,
      selectedTestTypes: selectedTestTypes.map(type => type.toLowerCase()),
      testTypeDetails: selectedScenarioTypes.map(type => type.toLowerCase()),
      tags: selectedTags
    };
  
    try {
      const result = await dispatch(generateBulkTestScenario(payload)).unwrap();
      setTestProcessId(result.test_process_id);
      if (result.test_process_id) {
        startPolling();
      }
    } catch (error) {
      setTestProcessData(prev => ({
        ...prev,
        loading: false,
        error: 'Failed to generate test'
      }));
    }
  };

  const handleGoToTestScenario = () => {
    const encodedTags = selectedTags.map((tag) => `"${encodeURIComponent(tag)}"`).join(',');
    const url = `${window.location.origin}/test-scenarios/${testProcessData?.test_scenario?.id}?page=1&tags=[${encodedTags}]`;
    window.open(url, '_blank');
  };

  const handleTabChange = (event, newValue) => {
    resetStates();
    setActiveTab(newValue);
  };

  const handleScenarioTypeClick = (type) => {
    setSelectedScenarioTypes(prev => {
      if (prev.includes(type)) {
        return prev.filter(t => t !== type);
      }
      return [...prev, type];
    });
  };
  
  const handleTestTypeClick = (type) => {
    setSelectedTestTypes(prev => {
      if (prev.includes(type)) {
        return prev.filter(t => t !== type);
      }
      return [...prev, type];
    });
  };

  const isMultipleGenerateEnabled = () => {
    return (
      selectedCollection &&
      selectedApis.length > 0 &&
      selectedScenarioTypes.length > 0 &&
      selectedTestTypes.length > 0 
    );
  };

  const isSingleGenerateEnabled = () => {
    return (
      selectedCollection &&
      selectedApis.length > 0 &&
      message.trim().length >= 50
    );
  };

  const renderSingleTabContent = () => (
    <>
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <FormControl sx={{ flex: 1 }}>
          <Typography 
            variant="subtitle2" 
            sx={{ 
              mb: 1, 
              fontFamily: 'Inter, Sans-serif',
              color: '#575757'
            }}
          >
            Select Collection
          </Typography>
          <Select
            value={selectedCollection}
            onChange={handleCollectionChange}
            displayEmpty
            size="small"
            sx={{
              fontFamily: 'Inter, Sans-serif',
              fontWeight: '500'
            }}
          >
            <MenuItem value="" disabled>Choose collection</MenuItem>
            {collections?.map((collection) => (
              <MenuItem 
                key={collection.id} 
                value={collection.id}
                sx={{
                  fontFamily: 'Inter, Sans-serif',
                  fontWeight: '500'
                }}
              >
                {collection.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
  
        <FormControl sx={{ flex: 1 }}>
          <Typography 
            variant="subtitle2" 
            sx={{ 
              mb: 1, 
              fontFamily: 'Inter, Sans-serif',
              color: '#575757'
            }}
          >
            Select APIs ({selectedApis.length})
          </Typography>
          <Select
            multiple
            value={selectedApis}
            onChange={handleApiChange}
            input={<OutlinedInput />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {`${selected.length} API${selected.length !== 1 ? 's' : ''} selected`}
              </Box>
            )}
            MenuProps={MenuProps}
            size="small"
          >
            {getSortedApis(apis, selectedApis).map((api) => (
              <MenuItem 
                key={api.id} 
                value={api.id}
                sx={{
                  fontFamily: 'Inter, Sans-serif',
                  fontWeight: '500'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox checked={selectedApis.includes(api.id)} />
                  <Box component="span" sx={{ 
                    color: getMethodColor(api.request_type),
                    fontWeight: '600',
                    minWidth: '45px',
                    mr: 0.5
                  }}>
                    {api.request_type}
                  </Box>
                  <ListItemText primary={api.path || api.name} />
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
  
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 3 }}>
        {!showChat && (
          <Box sx={{ width:'100%', position: 'relative' }}>
          <TextField
            multiline
            rows={4}
            placeholder="Give a detailed description... (minimum 50 characters)"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            sx={{
              width: '100%',
              '& .MuiOutlinedInput-root': {
                fontFamily: 'Inter, Sans-serif',
              }
            }}
          />
          <Typography 
            variant="caption" 
            sx={{ 
              position: 'absolute',
              bottom: -20,
              right: 0,
              color: message.length < 50 ? '#d32f2f' : '#666666',
              fontFamily: 'Inter, Sans-serif'
            }}
          >
            {message.length} characters (minimum 50)
          </Typography>
        </Box>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Inter, Sans-serif',
              color: '#575757',
            }}
          >
            Add Tags
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              border: '1px solid #d1d1d1',
              borderRadius: 1,
              padding: '2px',
              gap: 0.5,
            }}
          >
            {selectedTags.map((tag) => (
              <Chip
                key={tag}
                label={tag}
                onDelete={() => handleDelete(tag)}
                sx={{
                  backgroundColor: '#e0e0e0',
                  color: '#333',
                  fontFamily: 'Inter, Sans-serif',
                  fontWeight: '500',
                }}
              />
            ))}

            <OutlinedInput
              placeholder="Type and press Enter or Comma to add tags"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
              sx={{
                flex: 1,
                fontFamily: 'Inter, Sans-serif',
                '& fieldset': { border: 'none' }, 
              }}
            />
          </Box>
        </Box>

        {!showChat ? (
          <Button
            variant="contained"
            disabled={!isSingleGenerateEnabled()}
            onClick={handleInitialGenerate}
            sx={{
              backgroundColor: '#6241D4',
              color: 'white',
              fontFamily: 'Inter, Sans-serif',
              fontWeight: '500',
              textTransform: 'none',
              width: 'fit-content',
              alignSelf: 'flex-start',
              '&:hover': {
                backgroundColor: '#4c2bb3'
              },
              '&.Mui-disabled': {
                backgroundColor: '#e0e0e0',
                color: '#a1a1a1'
              }
            }}
          >
            Generate
          </Button>
        ) : null}
      </Box>

      {showChat && (
        <ChatWindow
          testScenarioData={testProcessData}
          onSendBtnClick={handleUserSubmit}
          onGoToTestScenarioClick={handleGoToTestScenario}
          forceGenerateClick={handleForceGenerateClick}
          startNewClick={handleForceGenerateClick}
        />
      )}
    </>
  );

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      const newTag = inputValue.trim();
      if (newTag && !selectedTags.includes(newTag)) {
        setSelectedTags((prev) => [...prev, newTag]);
      }
      setInputValue('');
    }
  };

  const handleDelete = (tagToDelete) => {
    setSelectedTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  const handleGoToAllTestScenarios = () => {
    const encodedTags = selectedTags.map((tag) => `"${encodeURIComponent(tag)}"`).join(',');
    const url = `${window.location.origin}/test-scenarios?page=1&tags=[${encodedTags}]`;
    window.open(url, '_blank');
    resetStates();
  };

  const renderMultipleTabContent = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <FormControl sx={{ flex: 1 }}>
            <Typography 
              variant="subtitle2" 
              sx={{ 
                mb: 1, 
                fontFamily: 'Inter, Sans-serif',
                color: '#575757'
              }}
            >
              Select Collection
            </Typography>
            <Select
              value={selectedCollection}
              onChange={handleCollectionChange}
              displayEmpty
              size="small"
              sx={{
                fontFamily: 'Inter, Sans-serif',
                fontWeight: '500'
              }}
            >
              <MenuItem value="" disabled>Choose collection</MenuItem>
              {collections?.map((collection) => (
                <MenuItem 
                  key={collection.id} 
                  value={collection.id}
                  sx={{
                    fontFamily: 'Inter, Sans-serif',
                    fontWeight: '500'
                  }}
                >
                  {collection.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ flex: 1 }}>
            <Typography 
              variant="subtitle2" 
              sx={{ 
                mb: 1, 
                fontFamily: 'Inter, Sans-serif',
                color: '#575757'
              }}
            >
              Select APIs ({selectedApis.length})
            </Typography>
            <Select
              multiple
              value={selectedApis}
              onChange={handleApiChange}
              input={<OutlinedInput />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {`${selected.length} API${selected.length !== 1 ? 's' : ''} selected`}
                </Box>
              )}
              MenuProps={MenuProps}
              size="small"
              sx={{
                fontFamily: 'Inter, Sans-serif',
                fontWeight: '500'
              }}
            >
              {apis.map((api) => (
                <MenuItem 
                  key={api.id} 
                  value={api.id}
                  sx={{
                    fontFamily: 'Inter, Sans-serif',
                    fontWeight: '500'
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox checked={selectedApis.includes(api.id)} />
                    <Box component="span" sx={{ 
                      color: getMethodColor(api.request_type),
                      fontWeight: '600',
                      minWidth: '45px',
                      mr: 0.5
                    }}>
                      {api.request_type}
                    </Box>
                    <ListItemText primary={api.path || api.name} />
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <TextField
          multiline
          rows={4}
          placeholder="Add additional information or requirements..."
          value={additionalInfo}
          onChange={(e) => setAdditionalInfo(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              fontFamily: 'Inter, Sans-serif',
            }
          }}
        />

        <Box>
          <Typography variant="subtitle2" sx={{ mb: 1, fontFamily: 'Inter, Sans-serif' }}>
            Scenario Types
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {['Positive', 'Negative', 'Boundary'].map((type) => (
              <Chip
                key={type}
                label={type}
                onClick={() => handleScenarioTypeClick(type)}
                sx={{
                  backgroundColor: selectedScenarioTypes.includes(type) ? '#6241D4' : '#f5f5f5',
                  color: selectedScenarioTypes.includes(type) ? 'white' : '#575757',
                  fontFamily: 'Inter, Sans-serif',
                  fontWeight: '500',
                  '&:hover': {
                    backgroundColor: selectedScenarioTypes.includes(type) ? '#4c2bb3' : '#e0e0e0'
                  }
                }}
              />
            ))}
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2" sx={{ mb: 1, fontFamily: 'Inter, Sans-serif' }}>
            Test Types
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {['Unit', 'Integration'].map((type) => (
              <Chip
                key={type}
                label={type}
                onClick={() => handleTestTypeClick(type)}
                sx={{
                  backgroundColor: selectedTestTypes.includes(type) ? '#6241D4' : '#f5f5f5',
                  color: selectedTestTypes.includes(type) ? 'white' : '#575757',
                  fontFamily: 'Inter, Sans-serif',
                  fontWeight: '500',
                  '&:hover': {
                    backgroundColor: selectedTestTypes.includes(type) ? '#4c2bb3' : '#e0e0e0'
                  }
                }}
              />
            ))}
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Inter, Sans-serif',
              color: '#575757',
            }}
          >
            Add Tags
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              border: '1px solid #d1d1d1',
              borderRadius: 1,
              padding: '2px',
              gap: 0.5,
            }}
          >
            <Chip
              label={defaultTag}
              sx={{
                backgroundColor: '#e0e0e0',
                color: '#333',
                fontFamily: 'Inter, Sans-serif',
                fontWeight: '500',
              }}
            />

            {selectedTags
              .filter((tag) => tag !== defaultTag)
              .map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={() => handleDelete(tag)}
                  sx={{
                    backgroundColor: '#e0e0e0',
                    color: '#333',
                    fontFamily: 'Inter, Sans-serif',
                    fontWeight: '500',
                  }}
                />
              ))}

            <OutlinedInput
              placeholder="Type and press Enter or Comma to add tags"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
              sx={{
                flex: 1,
                fontFamily: 'Inter, Sans-serif',
                '& fieldset': { border: 'none' }, 
              }}
            />
          </Box>
        </Box>

        {!showChatForMultiple ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              variant="contained"
              disabled={!isMultipleGenerateEnabled()}
              onClick={() => {
                startGeneratingBulkTestProcess();
              }}
              sx={{
                backgroundColor: '#6241D4',
                color: 'white',
                fontFamily: 'Inter, Sans-serif',
                fontWeight: '500',
                textTransform: 'none',
                width: 'fit-content',
                alignSelf: 'flex-start',
                '&:hover': {
                  backgroundColor: '#4c2bb3'
                },
                '&.Mui-disabled': {
                  backgroundColor: '#e0e0e0',
                  color: '#a1a1a1'
                }
              }}
            >
              Generate
            </Button>
          </Box>
        ) : (
          <ChatWindow
            testScenarioData={testProcessData}
            onSendBtnClick={handleUserSubmit}
            startNewClick={resetStates}
            onGoToAllTestScenariosClick={handleGoToAllTestScenarios}
          />
        )}
      </Box>
    );
  };

  const methodColors = {
    GET: 'green',
    POST: '#ffb900',
    PUT: 'blue',
    PATCH: 'purple',
    DELETE: 'red',
    HEAD: 'green',
    OPTIONS: 'pink',
  };

  const getMethodColor = (method) => {
    let methodUpper = method?.toUpperCase();
    return methodColors[methodUpper] || 'inherit';
  };

  const resetStates = () => {
    stopPolling();
    stopTestScenarioPolling();
    setTestProcessId(null);
    setTestScenarioProcessId(null);
    setSelectedCollection('');
    setSelectedApis([]);
    setMessage('');
    setAdditionalInfo('');
    setSelectedScenarioTypes([]);
    setSelectedTestTypes([]);
    setSelectedTags(activeTab === 1 ? [defaultTag] : []);
    setShowChat(false);
    setShowChatForMultiple(false);
    setInputValue('');
    setTestProcessData(null);
  };

  const handleClose = () => {
    resetStates();
    onClose();
  };

  const getSortedApis = (apis, selectedApis) => {
    return [...apis].sort((a, b) => {
      const aSelected = selectedApis.includes(a.id);
      const bSelected = selectedApis.includes(b.id);
      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      return 0;
    });
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        gap: '4px',
        fontFamily: 'Inter, Sans-serif',
        pb: 1
      }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}>
          <Typography sx={{
            fontWeight: '600',
            fontSize: '20px'
          }}>
            Generate {activeTab === 0 ? 'single' : 'multiple'} test {activeTab === 0 ? 'scenario' : 'scenarios'} with AI
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{display: 'flex', 
          alignItems: 'center',
          gap: 0.5
        }}>
          <InfoOutlined 
            sx={{ 
              fontSize: '14px',
              color: '#6241D4'
            }} 
          />
          <Typography sx={{
            fontSize: '13px',
            color: '#666666',
            fontFamily: 'Inter, Sans-serif',
            fontWeight: '400',
            lineHeight: '1',
            '& span': {
              color: '#6241D4',
              fontWeight: '500'
            }
          }}>
            {activeTab === 0 ? 'Creates one single test scenario based on your plain English description of what to test.' : 'Automatically generates a variety of test scenarios covering different testing types and scenarios.'}
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%', mb: 2 }}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            sx={{
              '& .MuiTab-root': {
                textTransform: 'none',
                fontFamily: 'Inter, Sans-serif',
                fontWeight: '500',
                color: '#575757',
                '&.Mui-selected': {
                  color: '#6241D4'
                }
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#6241D4',
                height: '2px'
              }
            }}
          >
            <Tab label="Single" />
            <Tab label="Multiple" />
          </Tabs>
        </Box>

        {activeTab === 0 ? renderSingleTabContent() : renderMultipleTabContent()}
      </DialogContent>
    </Dialog>
  );
};

export default ApiChatModal;