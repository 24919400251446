import React, { useReducer, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Grid, Card, Box, Button, TextField, FormControlLabel, Checkbox, InputLabel, Autocomplete } from '@mui/material';
import moment from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import {
  createOrganisation,
  createProject,
} from "../../redux-store/currentUserActions";
import "../../styles/App.scss"
import "./index.css";
import axios from "axios";

const HomeScreen = () => {
  const history = useHistory();
  const _dispatch = useDispatch();
  const timeZones = moment.tz.names();
  const [state, setState] = useReducer(reducer, {
    companyName: "",
    companyLocation: "",
    projectName: "",
    useCompanyNameForProject: true,
    isLoading: false,
    errors: {},
  });

  const user = useSelector((state) => state.user.current_user);
  const commonDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com'];

  useEffect(() => {
    if (user?.email) {
      const emailDomain = user.email.split('@')[1];
      if (!commonDomains.includes(emailDomain)) {
        const companyName = emailDomain.split('.')[0];
        setState({
          type: "SET_STATE",
          payload: {
            companyName,
            projectName: companyName,
            useCompanyNameForProject: true
          },
        });
      }
    }
  }, [user?.email]);

  useEffect(() => {
    const fetchUserTimezone = async () => {
      const timezone = await getUserTimezone();
      if (timezone) {
        setState({
          type: "SET_STATE",
          payload: { companyLocation: timezone },
        });
      }
    };
    fetchUserTimezone();
  }, []);

  const handleInputChange = (e, value, reason) => {
    if (reason === 'selectOption' || reason === 'clear') {
      setState({
        type: "SET_STATE",
        payload: { companyLocation: value },
      });
    } else if (e && e.target) {
      const { name, value } = e.target;
      
      const newState = {
        [name]: value,
      };
      
      if (name === "companyName" && state.useCompanyNameForProject) {
        newState.projectName = value;
      }
      
      setState({
        type: "SET_STATE",
        payload: newState,
      });
    }
  };

  const handlecheckChange = (e) => {
    const { name, checked } = e.target;
    const newState = {
      type: "SET_STATE",
      payload: { [name]: checked },
    };
  
    if (name === "useCompanyNameForProject" && checked) {
      newState.payload.projectName = state.companyName;
    } else if (name === "useCompanyNameForProject" && !checked) {
      newState.payload.projectName = "";
    }
  
    setState(newState);
  };
  
  const getUserTimezone = async () => {
    try {
      const response = await axios.get(`https://ipinfo.io/json?token=ea5265807ee5a1`);
      const timezone = response.data.timezone;
      return timezone;
    } catch (error) {
      return null;
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!state.companyName) errors.companyName = "Please enter company name.";
    if (!state.companyLocation) errors.companyLocation = "Please select time zone.";
    if (!state.projectName) errors.projectName = "Please enter project name.";
    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    setState({ type: "SET_STATE", payload: { errors } });

    if (Object.keys(errors).length === 0) {
      setState({ type: "SET_STATE", payload: { isLoading: true } });

      const res = await _dispatch(createOrganisation(state));
      if (res.payload?.id) {
        const newValue = { organisationId: res.payload.id };
        const newState = Object.assign({}, state, newValue);
        await _dispatch(createProject(newState));
        history.push("/dashboard?showGuide=1");
      }
    } else {
      setState({ type: "SET_STATE", payload: { isLoading: false } });
    }
  };

  const onFormSubmit = async () => {
    await handleSubmit();
  };

  return (
    <Container maxWidth="md" className="full-height">
      <Grid container spacing={2} className="d-flex justify-content-center align-items-center h-100">
        <Grid item xs={12} md={6} className="column-margin">
          <Card className="company-grid-card">
            <div className="right-column">
              <Box className="heading-container">
                <Box className="create-account-title">
                  Add your company details
                </Box>
              </Box>
              <Box>
                <InputLabel htmlFor="companyName" className="input-label">Company Name</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  className="input-field"
                  variant="outlined"
                  name="companyName"
                  value={state.companyName}
                  onChange={handleInputChange}
                  error={Boolean(state.errors["companyName"])}
                  helperText={state.errors["companyName"] || ' '}
                />
                <InputLabel htmlFor="companyLocation" className="input-label">Time Zone</InputLabel>
                <Autocomplete
                  fullWidth
                  size="small"
                  value={state.companyLocation}
                  onChange={handleInputChange}
                  options={timeZones}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label=""
                      error={Boolean(state.errors["companyLocation"])}
                      helperText={state.errors["companyLocation"] || ' '}
                    />
                  )}
                />
                <InputLabel htmlFor="projectName" className="input-label">Project Name</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  className="input-field"
                  variant="outlined"
                  name="projectName"
                  value={state.projectName}
                  onChange={handleInputChange}
                  error={Boolean(state.errors["projectName"])}
                  helperText={state.errors["projectName"]}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="useCompanyNameForProject"
                      color="primary"
                      checked={state.useCompanyNameForProject}
                      onChange={handlecheckChange}
                    />
                  }
                  label="Same as company name"
                />
              </Box>
              <div className="d-flex flex-column justify-content-between mt-3">
                <Button
                  variant="contained"
                  color="primary"
                  className="btn-round"
                  fullWidth
                  onClick={onFormSubmit}
                  disabled={state.isLoading}
                >
                  {state.isLoading ? "Saving..." : "Next"}
                </Button>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_STATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default HomeScreen;