import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { Box, Tabs, Tab, Menu, MenuItem, CircularProgress } from "@mui/material";
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

import {
  createVariableSet,
  getAllVariableSets,
  deleteVariableSet,
} from '../../redux-store/variableSetReducers/variableSetActions';

import useSearchParams from '../../hooks/useSearchParams';
import MainLayout from '../../layouts/MainLayout';
import VariableSetList from './VariableSetList';
import CustomButton from '../Common/CustomButton';

const VariableSetListPage = ({ project, defaultTab = 0 }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { openSnackbar } = useSnackbar();
  const searchParams = useSearchParams();
  const page = parseInt(searchParams.get('page') || '1');

  const isLoading = useSelector((state) => state.variableSet.isLoading);

  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [pageNumber, setPageNumber] = useState(isNaN(page) ? 1 : page);
  const [variableSetList, setVariableSetList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalVariableSets, setTotalVariableSets] = useState(0);
  const [isLoadingData, setIsLoadingData] = useState(false);
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    getVariableSetList();
    
    // Update URL with current tab and page
    const currentSearch = new URLSearchParams(location.search);
    currentSearch.set('page', pageNumber);
    currentSearch.set('tab', selectedTab === 0 ? 'environments' : 'variables');
    history.replace({
      pathname: location.pathname,
      search: currentSearch.toString()
    });
  }, [pageNumber, selectedTab]);

  const getVariableSetList = async () => {
    setIsLoadingData(true);
    setVariableSetList([]);
    setTotalPages(0);
    setTotalVariableSets(0);

    try {
      const variableSetListResponse = await dispatch(
        getAllVariableSets({
          projectId: project.id,
          pageNumber: pageNumber,
          pageSize: 10,
          isEnvironment: selectedTab === 0,
        })
      );

      if (variableSetListResponse?.payload) {
        setVariableSetList(variableSetListResponse.payload?.variable_sets || []);
        setTotalPages(variableSetListResponse.payload?.total_pages);
        setTotalVariableSets(variableSetListResponse.payload?.total);
      }
    } catch (error) {
      console.error('Error fetching variable sets:', error);
      openSnackbar({
        message: "Failed to fetch variable sets",
        severity: "error",
      });
    } finally {
      setIsLoadingData(false);
    }
  };

  const onPageChange = (e, value) => {
    setPageNumber(value);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setPageNumber(1);
    
    // Update URL when tab changes
    const currentSearch = new URLSearchParams(location.search);
    currentSearch.set('tab', newValue === 0 ? 'environments' : 'variables');
    history.push({
      pathname: location.pathname,
      search: currentSearch.toString()
    });
  };

  const onDeleteVariableSet = async (variableSetId) => {
    await dispatch(deleteVariableSet({ variableSetId }));
    openSnackbar({
      message: "Variable set deleted successfully.",
      severity: "success",
    });

    if (variableSetList.length === 1 && pageNumber !== 1) {
      onPageChange('', pageNumber - 1 > 0 ? pageNumber - 1 : 1);
    } else {
      getVariableSetList();
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const createNewVariableSet = async (isEnvironment = false) => {
    const title = `${isEnvironment ? 'Environment' : 'Variable'} ${
      variableSetList ? totalVariableSets + 1 : 1
    }`;
    
    const variableSetResponse = await dispatch(
      createVariableSet({ 
        projectId: project.id, 
        title: title,
        isEnvironment: isEnvironment 
      })
    );
    
    handleClose();
    
    if (variableSetResponse?.payload?.id) {
      openSnackbar({
        message: `${title} created successfully.`,
        severity: "success",
      });
      history.push(`/variable-set/${variableSetResponse.payload.id}`);
    }
  };

  return (
    <MainLayout
      isLoading={isLoading}
      headerText={selectedTab === 0 ? 'Environments' : 'Variables'}
      subtitleText={`Showing ${totalVariableSets || 0} ${selectedTab === 0 ? 'Environment' : 'Variable'}(s)`}
      totalPages={totalPages}
      pageNumber={pageNumber}
      onPageChange={onPageChange}
      rightSideContent={
        <>
          <CustomButton
            startIcon={<Add />}
            onClick={handleClick}
            aria-controls={open ? 'create-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            Create {selectedTab === 0 ? 'environment' : 'variable'}
          </CustomButton>
          <Menu
            id="create-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'create-button',
            }}
          >
            <MenuItem onClick={() => createNewVariableSet(true)}>
              + Create Environment
            </MenuItem>
            <MenuItem onClick={() => createNewVariableSet(false)}>
              + Create Variable
            </MenuItem>
          </Menu>
        </>
      }
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab label="Environments" />
          <Tab label="Variables" />
        </Tabs>
      </Box>
      
      <Box sx={{maxHeight: "calc(100vh - 230px)", overflow: "auto"}}>
        <VariableSetList
          variableSetList={variableSetList}
          isLoading={isLoadingData}
          pageNumber={pageNumber}
          onDelete={onDeleteVariableSet}
        />
      </Box>
    </MainLayout>
  );
};

export default VariableSetListPage;