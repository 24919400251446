import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import coreUtils from "utils/coreUtils.js";

const MemoryModal = ({ isOpen, onClose, actionType, onConfirm, detail = {} }) => {
  const [message, setMessage] = useState(detail?.name || "");
  const [errors, setErrors] = useState({});
  const isLoading = useSelector(state => state.memory.isLoading);

  useEffect(() => {
    if (detail && detail.message) {
      setMessage(detail.message);
    } else {
      setMessage("");
    }
  }, [detail, isOpen]);

  const handleConfirmClick = (e) => {
    const errorsObj = validate();
    if (Object.keys(errorsObj).length > 0) {
      return;
    }
    onConfirm(actionType, {
      message: message?.trim()
    });
  }

  const handleClose = () => {
    setMessage("");
    setErrors({});
    onClose();
  }

  const handleChange = (value) => {
    setMessage(value);
    if (errors.name) validate();
  }

  const validate = () => {
    const errors = {};
    let nameError = coreUtils.isStringInvalidOrBlank(message) ? "Required" : "";
    if (nameError) errors.name = nameError;
    setErrors(errors);
    return errors;
  }

  if (!isOpen) {
    return null;
  }

  let title = actionType === "create" ? "Add Memory" : "Edit Memory";
  let btnText = actionType === "create" ? "Create" : "Update";

  return (
    <Dialog maxWidth="sm" fullWidth onClose={(e) => { e.preventDefault(); }} open={isOpen}>
      <DialogTitle><h4>{title}</h4></DialogTitle>
      <DialogContent>
        <h6>Message</h6>
        <TextField 
          value={message} 
          size="small" 
          fullWidth 
          onChange={(e) => { handleChange(e.target.value) }} 
          placeholder="Enter message" 
          error={!!errors.name} 
        />
        {errors.name && <span className="error">{errors.name}</span>}
      </DialogContent>
      <DialogActions sx={{ my: 2, p: "0px 24px" }}>
        <Button disabled={isLoading} onClick={handleClose} variant="outlined" color="error">
          Discard
        </Button>
        <Button disabled={isLoading} onClick={handleConfirmClick} variant="outlined" color="primary">
          {btnText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MemoryModal;