import React, { useState, useEffect } from 'react';
import { TextField, Box } from '@mui/material';

const APIDefinitionComponent = ({ initialValue = '', onChange }) => {
  const [definition, setDefinition] = useState(initialValue);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setDefinition(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    setDefinition(initialValue);
  }, [initialValue]);

  return (
    <Box  sx={{
        height: "200px",
        overflow: "auto", 
        border: "1px solid #e9e9e9",
        margin: "10px 0",
          borderRadius: "5px",
        background: "white",
      }}>
      <TextField
        fullWidth
        multiline
        rows={20}
        value={definition}
        onChange={handleChange}
        placeholder="Enter API details here..."
        variant="standard"
        sx={{
          '& .MuiInput-root': {
            '&::before': {
              borderBottom: 'none',
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: 'none',
            },
            '&::after': {
              borderBottom: 'none',
            }
          }
        }}
        InputProps={{
          sx: {
            fontFamily: "Inter, Sans-serif", 
                fontSize: "12px",
            fontWeight: "500",
            '& .MuiInput-input': {
              padding: '12px'
            }
          },
          disableUnderline: true
        }}
      />
    </Box>
  );
};

export default APIDefinitionComponent;