import {
  ModeEditOutline,
  CheckCircleOutline,
  Inventory2Outlined,
} from '@mui/icons-material';

export const ScreenTypes = {
  COLLECTION: 'collection',
  FOLDER: 'folder',
  API: 'api',
  NONE: 'none',
};

export const ResourceTypes = {
  COLLECTION: 'collection',
  FOLDER: 'folder',
  ENTITY: 'entity',
};

export const STATUS_TO_ICON = {
  draft: ( <ModeEditOutline fontSize="small" sx={{ color: "#fb662b" }} />),
  active: <CheckCircleOutline fontSize="small" color="success" />,
  archived: <Inventory2Outlined fontSize="small" sx={{ color: 'gray' }} />,
};

export const BUILD_STATUS_TO_COLOR = {
  pass: 'rgb(46, 125, 50)',
  fail: 'rgb(211, 47, 47)',
};

export const TEST_SCENARIOS_PAGE_SIZE = 25;

export const VARIABLE_SETS_PAGE_SIZE = 25;

export const TEST_SUITES_PAGE_SIZE = 25;

export const TEST_PLANS_PAGE_SIZE = 25;