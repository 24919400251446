import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Box } from "@mui/material";
import TestScenarioHeader from "./TestScenarioHeader";
import TestScenarioBody from "./TestScenarioBody";
import LoadingOverlay from "../Common/LoadingOverlay";
import EditDetail from "./EditDetail";
import BuildScenario from "./BuildScenario";
import TestScenarioBuildsDrawer from "../TestScenarios/TestScenarioBuildsDrawer";
import TestScenarioVariables from "./TestScenarioVariables";
import MainLayout from "../../layouts/MainLayout";

import { getTestScenarioTags, fetchTestScenarioDetail } from "../../redux-store/testScenarioReducers/testScenarioActions";
import { getOwners } from "../../redux-store/currentUserActions";
import { getAllVariableSets } from "../../redux-store/variableSetReducers/variableSetActions";
import DataFileManager from "./DataFileManager";

const TestScenarioDetail = function ({ project, testScenarioId }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.testScenario.isLoading);
  const [selectedItemContent, setSelectedItemContent] = useState(null);
  const drawerItems = [
    { icon: "article", title: "Details", id: "detail" },
    { icon: "play", title: "Run", id: "run" },
    // { icon: "search", title: "Debug", id: "debug", disabled: true },
    { icon: "build", title: "Builds", id: "builds" },
    { icon: "functions", title: "Variables", id: "variables" },
    { icon: "file", title: "Data Files", id: "datafiles" }, 
  ];

  useEffect(() => {
    getTestScenario();
  }, []);

  useEffect(() => {
    getRequiredInfo();
  }, [testScenarioId]);


  const getTestScenario = async () => {
    await dispatch(
      fetchTestScenarioDetail({ projectId: project.id, testScenarioId: testScenarioId })
    );
  };

  const getRequiredInfo = async () => {
    await Promise.all([
      dispatch(getTestScenarioTags({ projectId: project.id })),
      dispatch(getOwners({ projectId: project.id })),
      dispatch(
        getAllVariableSets({
          projectId: project.id,
          isEnvironment: true,
          pageNumber: 1,
        })
      ),
    ]);
  };

  const renderDrawerItem = ({ selectedItem, onDrawerClose }) => {
    const props = { projectId: project.id, testScenarioId };
    switch (selectedItem) {
      case "detail":
        setSelectedItemContent(
          <EditDetail {...props} closeDrawer={onDrawerClose} />
        );
        break;
      case "run":
        setSelectedItemContent(
          <BuildScenario {...props} closeDrawer={onDrawerClose} />
        );
        break;
      case "builds":
        setSelectedItemContent(
          <TestScenarioBuildsDrawer {...props} closeDrawer={onDrawerClose} />
        );
        break;
      case "variables":
        setSelectedItemContent(<TestScenarioVariables {...props} />);
        break;
      
        case "datafiles":
          setSelectedItemContent(
            <DataFileManager {...props} closeDrawer={onDrawerClose} />
          );
          break;
      default:
        setSelectedItemContent(null);
        break;
    }
  };

  if (isLoading) return <LoadingOverlay isLoading={isLoading} />;

  return (
    <MainLayout
      isLoading={isLoading}
      drawerItems={drawerItems}
      renderDrawerItem={renderDrawerItem}
      enableSideDrawer={true}
      contentStyle={{ width: "calc(100% - 100px)", overflow: "auto"}}
      selectedItemContent={selectedItemContent}
      sxLeftSide={{maxWidth: "70%"}}
      renderHeader={() => (<TestScenarioHeader projectId={project.id} testScenarioId={testScenarioId} />)}
    >
      <Box>
        <TestScenarioBody
          projectId={project.id}
          testScenarioId={testScenarioId}
        />
      </Box>
    </MainLayout>
  );
};

function mapStateToProps(state, ownProps){
  return {
    ...ownProps,
    project: state.common.selectedProject
  }
}

export default connect(mapStateToProps)(TestScenarioDetail);

