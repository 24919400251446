import { apiAgent } from '../../config/axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  updateLoadError,
  setLoadingState,
  setTestSuiteBuildList
} from './testSuiteBuildSlice';

const createBuild = createAsyncThunk(
  'createBuild',
  async ({ testSuiteId, environmentSetId }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'POST',
        url: '/api/v1/test_suite_builds',
        data: {
          test_suite_id: testSuiteId,
          environment_set_id: environmentSetId,
        },
      });
      if (response.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const getBuild = createAsyncThunk(
  'getBuild',
  async ({ testSuiteBuildId }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'GET',
        url: `/api/v1/test_suite_builds/${testSuiteBuildId}`,
      });
      if (response.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

const getAllBuilds = createAsyncThunk(
  'getAllBuilds',
  async (
    { testSuiteId, projectId, pageNumber, pageSize, filters },
    { dispatch, rejectWithValue }
  ) => {
    let paramsObj = {
      page: pageNumber,
      page_size: pageSize
    };
    if (testSuiteId) paramsObj.test_suite_id = testSuiteId;
    if (projectId) paramsObj.project_id = projectId;
    if (filters) paramsObj = {...paramsObj, ...filters};
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'GET',
        url: `/api/v1/test_suite_builds/?test_suite_id`,
        params: paramsObj
      });
      if (response.status === 200) {
        await dispatch(setTestSuiteBuildList(response?.data || []));
        await dispatch(setLoadingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

export { createBuild, getBuild, getAllBuilds };
