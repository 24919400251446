import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import ConfirmationDialog from "../Dialog/ConfirmationDialog";
import DeleteDialog from "../Common/DeleteDialog";
import TestPlanListTable from "./TestPlanListTable";

import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import {createBuild} from "../../redux-store/testPlanBuildReducers/testPlanBuildActions";

const TestPlansList = function ({
  testPlanList,
  onDelete,
  onToggleRunningStatus,
  onMarkAsActiveTestPlan
}) {
  const history = useHistory();
  const { openSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.testPlan.isLoading);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTestPlanId, setDeleteTestPlanId] = useState("");
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState(false);
  const [runningStatusTogglePlan, setRunningStatusTogglePlan] = useState("");

  const onViewTestPlan = (testPlanId) => {
    history.push(`/test-plans/${testPlanId}`);
  };

  const onClickDelete = (testPlanId) => {
    setDeleteTestPlanId(testPlanId);
    onToggleDeleteDialog();
  };

  const onDeleteConfirm = async () => {
    onDelete(deleteTestPlanId);
    onToggleDeleteDialog();
  };

  const onBuild = async (testPlanId) => {
    const buildResponse = await dispatch(
      createBuild({ testPlanId: testPlanId })
    );
    if (buildResponse?.payload?.id) {
      openSnackbar({
        message: "Build triggered successfully.",
        severity: "success",
      });
    } else {
      openSnackbar({
        message: "Build triggered failed.",
        severity: "error",
      });
    }
  };

  const onViewBuilds = async (id) => {
    history.push(`/test-plans/${id}/build/?page=1`);
  };

  const onToggleDeleteDialog = () => {
    setDeleteDialogOpen((prevState) => !prevState);
    if (deleteTestPlanId) setDeleteTestPlanId("");
  };

  const onRunningStatusToggleConfirm = () => {
    onToggleRunningStatus(
      runningStatusTogglePlan.id,
      !runningStatusTogglePlan.paused
    );
    setIsStatusDialogOpen();
  };

  const showRunningStatusDialog = (testPlan) => {
    setIsStatusDialogOpen((prevState) => !prevState);

    if (testPlan) setRunningStatusTogglePlan(testPlan);
  };

  const renderConfirmDialog = () => {
    return (
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={onToggleDeleteDialog}
        title="Delete Test Plan?"
        deleteBtnLabel="Delete Permanently"
        secondaryBtnLabel="Cancel"
        secondaryBtnIcon={<CancelOutlined />}
        onDeleteAction={onDeleteConfirm}
        onSecondaryAction={onToggleDeleteDialog}
      >
        <Typography variant="body1" sx={{ marginBottom: "16px" }}>
          Are you sure you want to delete?
        </Typography>
      </DeleteDialog>
    );
  };

  const renderToggleConfirmDialog = () => {
    return (
      <ConfirmationDialog
        open={isStatusDialogOpen}
        onClose={showRunningStatusDialog}
        onConfirm={onRunningStatusToggleConfirm}
        title="Confirm state change"
        actionText="Save"
        content="Are you sure you want to change change the status?"
      />
    );
  };

  return (
    <>
      <Box>
        {!isLoading && (
          <TestPlanListTable
            testPlanList={testPlanList}
            onViewTestPlan={onViewTestPlan}
            onClickDelete={onClickDelete}
            showRunningStatusDialog={showRunningStatusDialog}
            onViewBuilds={onViewBuilds}
            onBuild={onBuild}
            onMarkActive={onMarkAsActiveTestPlan}
          />
        )}
      </Box>
      {renderConfirmDialog()}
      {renderConfirmDialog && renderToggleConfirmDialog()}
    </>
  );
};

export default TestPlansList;
