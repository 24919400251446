import React, { useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import {Box, Stack, Typography, Avatar, Tooltip} from "@mui/material";
import CustomChip from '../Common/CustomChip';
import CustomTable from "../Common/CustomTable";
import BuildReportFilters from './BuildReportFilters';

import { BUILD_STATUS_TO_COLOR } from '../../config/constants';
import { getAllBuilds } from '../../redux-store/testScenarioBuildReducers/testScenarioBuildActions';
import { getTestScenarioTags } from '../../redux-store/testScenarioReducers/testScenarioActions';

const TestScenarioBuilds = ({projectId, pageNumber, setPageNumber}) => {
  const dispatch = useDispatch();
  const isInitialMount = useRef(true);
  const lastFetchedPage = useRef(pageNumber);
  const builds = useSelector(state => state.testScenarioBuild.testScenarioBuildList);
  const isLoading = useSelector(state => state.testScenarioBuild.isLoading);
  const appliedFilters = useSelector(state => state.buildReport.appliedFilters.testScenario);

  const defaultFilters = useMemo(() => ({
    tags: [],
    status: "",
    environmentSetId: ""
  }), []);

  useEffect(() => {
    if (isInitialMount.current)
      getFilters();
    fetchAllBuilds();
  }, []);

  useEffect(() => {
    if (pageNumber !== 1)
      setPageNumber(1);
    else
      fetchAllBuilds();
  }, [appliedFilters]);

  useEffect(() => {
    fetchAllBuilds();
  }, [pageNumber]);

  const getFilters = async () => {
    await Promise.all([
      dispatch(getTestScenarioTags({ projectId: projectId }))
    ]);
  };

  const fetchAllBuilds = async () => {
    const formattedFilters = {...appliedFilters};
    formattedFilters.environment_set_id = formattedFilters?.environmentSetId ? formattedFilters.environmentSetId : "";
    delete formattedFilters.environmentSetId;
    await dispatch(
      getAllBuilds({
        filters: formattedFilters,
        projectId: projectId,
        pageNumber,
        pageSize: 10
      })
    );
    lastFetchedPage.current = pageNumber;
    isInitialMount.current = false;
  }

  const onClickBuild = (e, testScenarioId, buildId) => {
    window.open(`${window.location.origin}/test-scenarios/${testScenarioId}/build/${buildId}`, "_blank");
  };

  const getTableContent = () => {
    if (builds.length === 0 && isLoading) {
      return (
        <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
          <h3>Curating builds data...</h3>
        </Box>
      )
    }
    if (builds.length === 0) {
      return (
        <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
          <h3>No results found.</h3>
        </Box>
      )
    }

    return (
      <CustomTable
        headers={[
          {
            id: 'buildNumber',
            type: 'text',
            text: 'Build Number',
          },
          {
            id: 'testScenario',
            type: 'text',
            text: 'Test Scenario',
          },
          {
            id: 'triggeredVia',
            type: 'text',
            text: 'Triggered Via',
          },
          {
            id: 'Result',
            type: 'text',
            text: 'Run Result',
          },
          {
            id: 'executionTime',
            type: 'text',
            text: 'Execution Time',
          },
          {
            id: 'tags',
            type: 'text',
            text: 'Tags',
          },
          {
            id: 'owner',
            type: 'text',
            text: 'Owner',
          }
        ]}
        rows={builds.map(
          ({
            id,
            build_number,
            test_scenario,
            started_at,
            execution_time,
            status,
            environment_set,
            triggered_via,
            owner
          }) => ({
            id,
            onClick: (e) => onClickBuild(e, test_scenario?.id, id),
            cells: [
              {
                id: id,
                type: 'text',
                text: `${test_scenario.code}: Build #${build_number}`,
                sx: { width: '100px', cursor: 'pointer' }
              },
              {
                id: 'title-' + id,
                type: 'component',
                content: (
                  <Stack direction="column" className="title" sx={{ cursor: 'pointer' }}>
                    <Tooltip
                        title={
                          <span style={{ fontSize: '1.0rem' }}>
                            {test_scenario?.title}
                          </span>
                        }
                      placement="top-start"
                    >
                      <Typography variant="body1" className="truncate-2-lines">
                        {test_scenario?.title}
                      </Typography>
                    </Tooltip>
                  </Stack>
                ),
                sx: { width: '180px', cursor: 'pointer' }
              },
              {
                id: 'triggeredVia-' + id,
                type: 'component',
                content: (
                  <Typography variant="body1" className="status" sx={{ cursor: 'pointer' }}>
                    {triggered_via}
                  </Typography>
                ),
                sx: { width: '100px', cursor: 'pointer' }
              },
              {
                id: 'build-status-' + id,
                type: 'component',
                content: (
                  <Stack useFlexGap direction="row" className="chips lastrun" flexWrap="wrap" spacing={1} sx={{ cursor: 'pointer' }}>
                    <CustomChip
                      key={environment_set?.id || `build-status`}
                      className={status}
                      title={status}
                      label={
                        <Tooltip title={status} placement="top-start">
                          <span>
                            {environment_set?.title || '-'}:{' '}
                            <span
                              style={{
                                color: BUILD_STATUS_TO_COLOR[status] || 'black',
                                cursor: 'pointer'
                              }}
                            >
                              {started_at
                                ? dayjs(started_at * 1000).fromNow()
                                : status || '-'}
                            </span>
                          </span>
                        </Tooltip>
                      }
                    />
                  </Stack>
                ),
                sx: { width: '150px', cursor: 'pointer' }
              },
              {
                id: 'executionTime-' + id,
                type: 'component',
                content: (
                  <Typography variant="body1" className="status" sx={{ cursor: 'pointer' }}>
                    {execution_time ? `${execution_time.toFixed(2)} seconds` : ""}
                  </Typography>
                ),
                sx: { width: '100px', cursor: 'pointer' }
              },
              {
                id: 'tags-' + id,
                type: 'component',
                content: (
                  <Stack useFlexGap direction="row" flexWrap="wrap" spacing={1} className="chips" sx={{ cursor: 'pointer' }}>
                    {(test_scenario?.tags || []).map((tag) => (
                      <CustomChip key={tag} label={tag} />
                    ))}
                  </Stack>
                ),
                sx: { width: '240px', cursor: 'pointer' }
              },
              {
                id: 'owner-' + id,
                type: 'component',
                content: owner ? (
                  <Stack direction="row" spacing={1} alignItems="center" sx={{ cursor: 'pointer' }}>
                    <Avatar
                      alt={owner.name}
                      src={owner.avatar}
                      sx={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body1" className="truncate-2-lines">
                      {owner.name}
                    </Typography>
                  </Stack>
                ) : null,
                sx: { width: '140px', cursor: 'pointer' }
              }
            ],
          })
        )}
      />
    );
  }

  return (
    <Box sx={{p: 2}}>
      <BuildReportFilters sourceType="testScenario" initialFilters={appliedFilters} defaultFilters={defaultFilters} />
      <Box sx={{ maxHeight: "calc(100vh - 280px)", overflow: "auto" }}>
        {getTableContent()}
      </Box>
    </Box>
  )
}

export default TestScenarioBuilds;