import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import {
  Box,
  Stack,
  Typography,
  Avatar,
  IconButton,
  MenuList,
  MenuItem,
} from '@mui/material';
import { Inventory2Outlined, MoreVert } from '@mui/icons-material';
import LoadingOverlay from '../Common/LoadingOverlay';
import CustomTable from '../Common/CustomTable';
import CustomCheckbox from "../Common/CustomCheckbox";
import CustomChip from '../Common/CustomChip';
import CustomMenu from '../Common/CustomMenu';
import CustomDialog from '../Common/CustomDialog';
import DeleteDialog from '../Common/DeleteDialog';

import { createBuild } from '../../redux-store/testSuiteBuildReducers/testSuiteBuildActions';
import { setSelectedTestSuites } from "../../redux-store/testSuiteReducers/testSuiteSlice";
import { STATUS_TO_ICON, BUILD_STATUS_TO_COLOR } from '../../config/constants';

const TestSuitesList = function ({
  testSuiteList,
  pageNumber,
  onDelete,
  onArchive,
  onMarkActive
}) {
  const isLoading = useSelector((state) => state.testSuite.isLoading);
  const history = useHistory();
  const dispatch = useDispatch();

  const environmentSets = useSelector(
    (state) => state.variableSet.allVariableSets
  );

  const [checkedTestSuites, setCheckedTestSuites] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTestSuiteId, setDeleteTestSuiteId] = useState('');
  const [buildSuiteId, setBuildSuiteId] = useState('');
  const [anchorEls, setAnchorEls] = useState({});

  const buildDialogOpen = !!buildSuiteId;

  const allChecked =
    testSuiteList.filter((suite) => suite.status === "active").length === checkedTestSuites.length && checkedTestSuites.length !== 0;

  useEffect(() => {
    updateCheckedTestSuitesIds([]);
  }, [testSuiteList]);

  const handleAllCheckboxChange = () => {
    if (allChecked) {
      updateCheckedTestSuitesIds([]);
    } else {
      updateCheckedTestSuitesIds(
        testSuiteList
          .filter((suite) => suite.status === "active")
          .map((suite) => suite.id)
      );
    }
  };

  const handleCheckboxChange = (e, tId) => {
    if (e.target.checked) {
      updateCheckedTestSuitesIds([...checkedTestSuites, tId]);
    } else {
      updateCheckedTestSuitesIds(checkedTestSuites.filter((id) => id !== tId));
    }
  };

  const updateCheckedTestSuitesIds = async (suiteIds) => {
    setCheckedTestSuites(suiteIds);
    const selectedTestSuites = testSuiteList
      .filter((suite) => suiteIds.indexOf(suite.id) !== -1)
      .map((suite) => ({
        title: suite.title,
        id: suite.id,
      }));
    await dispatch(setSelectedTestSuites([...selectedTestSuites]));
  };

  const handleClick = (event, id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
  };

  const handleClose = (id) => {
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };

  const handleBuildDialogClose = () => setBuildSuiteId('');

  const onViewTestSuite = async (id) => {
    history.push(`/test-suites/${id}/?page=${pageNumber}`);
  };

  const onBuild = async (envId) => {
    const buildResponse = await dispatch(
      createBuild({ testSuiteId: buildSuiteId, environmentSetId: envId })
    );
    history.push(
      `/test-suites/${buildSuiteId}/build/${buildResponse.payload.id}`
    );
  };

  const onViewBuilds = async (id) => {
    history.push(`/test-suites/${id}/build/`);
  };

  const onClickDelete = (id) => {
    setDeleteTestSuiteId(id);
    onToggleDeleteDialog();
  };

  const onDeleteConfirm = () => {
    onDelete(deleteTestSuiteId);
    onToggleDeleteDialog();
  };

  const onArchiveConfirm = () => {
    onArchive(deleteTestSuiteId);
    onToggleDeleteDialog();
  };

  const onToggleDeleteDialog = () => {
    setDeleteDialogOpen((prevState) => !prevState);
    if (deleteTestSuiteId) setDeleteTestSuiteId('');
  };

  const renderTable = () => {
    if (testSuiteList.length === 0) {
      return (
        <Box sx={{width: "100%", textAlign: "center", padding: "50px 32px"}}>
          <h3>No results found.</h3>
        </Box>
      )
    }

    return (
      <CustomTable
        headers={[
          {
            id: "checkbox",
            type: "component",
            content: (
              <CustomCheckbox
                checked={allChecked}
                onChange={handleAllCheckboxChange}
                disabled={testSuiteList?.filter((suite) => suite.status === "active").length === 0}
              />
            ),
          },
          {
            id: "code",
            type: "text",
            text: "Code",
          },
          {
            id: "title",
            type: "text",
            text: "Title",
          },
          {
            id: "status",
            type: "text",
            text: "Status",
          },
          {
            id: "tags",
            type: "text",
            text: "Tags",
          },
          {
            id: "owner",
            type: "text",
            text: "Owner",
          },
          {
            id: 'lastRunResult',
            type: 'text',
            text: 'Last Run Result',
          },
          {
            id: "actions",
            type: "text",
            text: "Actions",
            align: "center",
          },
        ]}
        rows={testSuiteList.map(
          ({ id, code, title, description, status, tags, owner, test_suite_statuses }) => ({
            id,
            cells: [
              {
                id: "checkbox-" + id,
                type: "component",
                content: (
                  <CustomCheckbox
                    checked={checkedTestSuites.includes(id)}
                    onChange={(e) => {
                      handleCheckboxChange(e, id);
                    }}
                    disabled={status !== "active"}
                  />
                ),
                sx: { width: "50px" },
              },
              {
                id: "code-" + id,
                type: "text",
                text: code,
                sx: { width: "100px" },
              },
              {
                id: "title-" + id,
                type: "component",
                content: (
                  <Stack direction="column" className="title">
                    <Typography variant="body1" className="truncate-2-lines" title={title}>{title}</Typography>
                    <Typography
                      variant="body2"
                      color="gray"
                      className="truncate-1-lines"
                      title={description}
                    >
                      {description}
                    </Typography>
                  </Stack>
                ),
                sx: { cursor: "pointer", width: "400px" },
                onClick: () => {
                  onViewTestSuite(id);
                },
              },
              {
                id: "status-" + id,
                type: "component",
                content: (
                  <Typography variant="body1" className="status">
                    {STATUS_TO_ICON[status] || ""} {status || ""}
                  </Typography>
                ),
                sx: { width: "120px" },
              },
              {
                id: "tags-" + id,
                type: "component",
                content: (
                  <Stack useFlexGap direction="row" flexWrap="wrap" spacing={1} className="chips">
                    {(tags || []).map((tag) => (
                      <CustomChip key={tag} label={tag} />
                    ))}
                  </Stack>
                ),
                sx: { width: '240px' },
              },
              {
                id: "owner-" + id,
                type: "component",
                content: owner ? (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar
                      alt={owner.name}
                      src={owner.avatar}
                      sx={{ width: 24, height: 24 }}
                    />
                    <Typography variant="body1" className="truncate-2-lines">{owner.name}</Typography>
                  </Stack>
                ) : null,
                sx: { width: "140px" },
              },
              {
                id: 'suite-status-' + id,
                type: 'component',
                content: (
                  <Stack useFlexGap direction="row" className="chips lastrun" flexWrap="wrap" spacing={1}>
                    {(test_suite_statuses || []).map(
                      (
                        {
                          environment_set: env,
                          status: buildStatus,
                          last_run_at: lastRunAt,
                        },
                        i
                      ) => (
                        <CustomChip
                          key={env?.id || `build-status-${i}`}
                          className={buildStatus}
                          title={buildStatus}
                          label={
                            <span>
                              {env?.title || '-'}:{' '}
                              <span
                                style={{
                                  color:
                                    BUILD_STATUS_TO_COLOR[buildStatus] ||
                                    'black',
                                }}
                              >
                                {lastRunAt
                                  ? dayjs(lastRunAt * 1000).fromNow() // multiply timestamp with 1000 to make it js compatible
                                  : buildStatus || '-'}
                              </span>
                            </span>
                          }
                        />
                      )
                    )}
                  </Stack>
                ),
                sx: { width: '150px' },
              },
              {
                id: "actions-" + id,
                type: "component",
                align: "center",
                content: (
                  <>
                    <IconButton
                      id={`actions-btn-${id}`}
                      aria-label="actions"
                      onClick={(e) => handleClick(e, id)}
                    >
                      <MoreVert />
                    </IconButton>

                    <CustomMenu
                      labelledBy={"actions-btn-" + id}
                      anchorEl={anchorEls[id]}
                      onClose={() => handleClose(id)}
                    >
                      <MenuList sx={{ minWidth: "100px" }}>
                        {status === 'active' && (
                           <MenuItem onClick={() => {setBuildSuiteId(id); handleClose(id);}}>
                           Run
                         </MenuItem>
                        )}
                       
                        <MenuItem onClick={() => {onViewBuilds(id); handleClose(id);}}>
                          All builds
                        </MenuItem>

                         {(status === 'draft' || status === 'archived') && (
                            <MenuItem onClick={() => {onMarkActive(id); handleClose(id);}}>
                                Mark as Active
                            </MenuItem>
                          )}
                        <MenuItem onClick={() => {onClickDelete(id); handleClose(id);}}>
                          Delete
                        </MenuItem>
                      </MenuList>
                    </CustomMenu>
                  </>
                ),
                sx: { width: "50px" },
              },
            ],
          })
        )}
      />
    );
  };

  const renderConfirmDialog = () => {
    return (
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={onToggleDeleteDialog}
        title="Delete Test Suite? Archive instead!"
        deleteBtnLabel="Delete Permanently"
        secondaryBtnLabel="Archive"
        secondaryBtnIcon={<Inventory2Outlined />}
        onDeleteAction={onDeleteConfirm}
        onSecondaryAction={onArchiveConfirm}
      >
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
          We recommend archiving Test Suites instead of deleting.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: '16px' }}>
          Are you sure you want to permanently delete this Test Suite?
        </Typography>
      </DeleteDialog>
    );
  };

  return (
    <>
      <Box>
        <LoadingOverlay isLoading={isLoading} />
        {!isLoading && renderTable()}
      </Box>
      {deleteDialogOpen && renderConfirmDialog()}

      <CustomDialog
        open={buildDialogOpen}
        title="Select environment"
        btnText="Run"
        onClose={handleBuildDialogClose}
        options={environmentSets || []}
        handleSubmit={(env) => onBuild(env)}
      />
    </>
  );
};

export default TestSuitesList;
