import React, { useState, useEffect, useRef } from 'react';
import
    {
        Box,
        Select,
        Button,
        Switch,
        Dialog,
        DialogContent,
        DialogTitle,
        DialogActions,
        MenuItem,
        Typography,
        Table,
        TableBody,
        TableCell,
        TableContainer,
        TableHead,
        TableRow,
        Paper,
        CircularProgress
    } from '@mui/material';
import { Upload, Delete } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { useS3Upload } from 'utils/useS3Upload';
import
    {
        fetchTestScenarioDataFiles,
        updateTestScenarioDataFile,
        deleteTestScenarioDataFile,
        addTestScenarioDataFile
    } from '../../redux-store/testScenarioReducers/testScenarioActions';

const DataFileManager = ({ projectId, testScenarioId, closeDrawer }) =>
{
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedEnv, setSelectedEnv] = useState('');
    const [dataFiles, setDataFiles] = useState([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [dataFileToDelete, setDataFileToDelete] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const s3Config = {
        AWS_ACCESS_KEY_ID: 'AKIA33HSNZ4X5NKBPPXB',
        AWS_SECRET_ACCESS_KEY: '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',
        AWS_BUCKET: 'qodex-temp',
        AWS_REGION: 'ap-southeast-1',
    };

    const { uploadFile, progress, uploadStatus, signedUrl, resetUploadStatus } = useS3Upload(s3Config);

    const allEnvironments = useSelector(
        (state) => state.variableSet.allVariableSets
    );

    const fetchDataFiles = async () =>
    {
        try
        {
            const response = await dispatch(fetchTestScenarioDataFiles({ testScenarioId })).unwrap();
            const files = response?.test_scenario_data_files || [];
            setDataFiles(Array.isArray(files) ? files : []);
        } catch (error)
        {
            console.error('Error fetching files:', error);
            setDataFiles([]);
        }
    };

    useEffect(() =>
    {
        if (testScenarioId)
        {
            fetchDataFiles();
        }
    }, [testScenarioId]);

    const handleFileChange = (event) =>
    {
        const file = event.target.files[0];
        console.log('File selected:', file);
        if (file && file.type === 'text/csv')
        {
            setSelectedFile(file);
            resetUploadStatus();
        } else
        {
            alert('Please select a CSV file');
            if (fileInputRef.current)
            {
                fileInputRef.current.value = '';
            }
        }
    };

    const handleSave = async () =>
    {
        if (!selectedFile || !selectedEnv) return;

        try
        {
            setIsUploading(true);
            const { url: s3Url } = await uploadFile(selectedFile);
            await dispatch(addTestScenarioDataFile({
                testScenarioId,
                environmentSetId: selectedEnv,
                dataFileId: s3Url
            })).unwrap();

            // Reset everything including the file input
            setSelectedFile(null);
            setSelectedEnv('');
            resetUploadStatus();
            if (fileInputRef.current)
            {
                fileInputRef.current.value = '';
            }
            await fetchDataFiles();

        } catch (error)
        {
            console.error('Error saving file:', error);
        } finally
        {
            setIsUploading(false);
        }
    };

    const handleDelete = (dataFile) =>
    {
        setDataFileToDelete(dataFile);
        setDeleteDialogOpen(true);
    };

    const confirmDelete = async () =>
    {
        try
        {
            const fileIdToDelete = dataFileToDelete.id;

            const response = await dispatch(deleteTestScenarioDataFile({
                testScenarioDataFileId: fileIdToDelete
            })).unwrap();

            if (response && response.success)
            {
                setDataFiles(prevFiles => prevFiles.filter(file => file.id !== fileIdToDelete));
                setDeleteDialogOpen(false);
                setDataFileToDelete(null);
            }
        } catch (error)
        {
            console.error('Error deleting file:', error);
            setDeleteDialogOpen(false);
            setDataFileToDelete(null);
        }
    };

    const toggleActive = async (dataFileId, currentSelected) =>
    {
        try
        {
            await dispatch(updateTestScenarioDataFile({
                testScenarioDataFileId: dataFileId,
                selected: !currentSelected
            })).unwrap();
            await fetchDataFiles();
        } catch (error)
        {
            console.error('Error updating file status:', error);
        }
    };

    const renderUploadButton = () =>
    {
        if (isUploading)
        {
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <CircularProgress size={20} />
                    <Typography>{`Uploading... ${progress}%`}</Typography>
                </Box>
            );
        }
        return selectedFile ? selectedFile.name : 'Upload CSV';
    };

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ mb: 4, fontWeight: 500 }}>
                Data Files
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: 4 }}>
                <Box>
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id="csv-upload"
                        disabled={isUploading}
                    />
                    <label htmlFor="csv-upload" style={{ width: '100%' }}>
                        <Button
                            variant="outlined"
                            component="span"
                            startIcon={<Upload />}
                            fullWidth
                            disabled={isUploading}
                            sx={{
                                height: '48px',
                                textTransform: 'none',
                                fontSize: '16px'
                            }}
                        >
                            {renderUploadButton()}
                        </Button>
                    </label>
                </Box>

                <Select
                    value={selectedEnv}
                    onChange={(e) => setSelectedEnv(e.target.value)}
                    displayEmpty
                    fullWidth
                    disabled={isUploading}
                    sx={{ height: '48px' }}
                >
                    <MenuItem value="" disabled>Select Environment</MenuItem>
                    {allEnvironments.map(env => (
                        <MenuItem key={env.id} value={env.id}>
                            {env.title}
                        </MenuItem>
                    ))}
                </Select>

                <Button
                    variant="contained"
                    onClick={handleSave}
                    disabled={!selectedFile || !selectedEnv || isUploading}
                    sx={{
                        height: '48px',
                        textTransform: 'none',
                        fontSize: '16px'
                    }}
                >
                    {isUploading ? 'Uploading...' : 'Save'}
                </Button>
            </Box>

            <TableContainer component={Paper} sx={{ boxShadow: 'none', border: '1px solid #e0e0e0' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 600 }}>File Name</TableCell>
                            <TableCell sx={{ fontWeight: 600 }}>Environment</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 600 }}>Active</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 600 }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataFiles.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={4} align="center" sx={{ py: 3 }}>
                                    No data files uploaded
                                </TableCell>
                            </TableRow>
                        ) : (
                            dataFiles.map(dataFile => (
                                <TableRow key={dataFile.id}>
                                    <TableCell>{dataFile.filename}</TableCell>
                                    <TableCell>
                                        {allEnvironments.find(e => e.id === dataFile.environment_set.id)?.title}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Switch
                                            checked={dataFile.selected}
                                            onChange={() => toggleActive(dataFile.id, dataFile.selected)}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button
                                            startIcon={<Delete />}
                                            onClick={() => handleDelete(dataFile)}
                                            sx={{ textTransform: 'none' }}
                                        >
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                PaperProps={{
                    sx: { borderRadius: 1 }
                }}
            >
                <DialogTitle sx={{ pb: 1 }}>Confirm Deletion</DialogTitle>
                <DialogContent sx={{ pb: 1 }}>
                    Are you sure you want to delete {dataFileToDelete?.filename}?
                </DialogContent>
                <DialogActions sx={{ px: 1, pb: 2 }}>
                    <Button
                        onClick={() => setDeleteDialogOpen(false)}
                        sx={{ textTransform: 'none' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={confirmDelete}
                        variant="contained"
                        sx={{ textTransform: 'none' }}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default DataFileManager;