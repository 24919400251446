import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Paper,
  List,
  ListItem,
  Typography,
  TextField,
  Button,
  Checkbox,
  Card,
  CardContent,
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import './ChatWindow.css'; 
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ConfirmationDialog from 'components/Dialog/ConfirmationDialog';

const ChatWindow = ({ testScenarioData, onSendBtnClick, onGoToTestScenarioClick, forceGenerateClick, startNewClick, onGoToAllTestScenariosClick, startAgainClick }) => {
  const [inputMessage, setInputMessage] = useState('');
  const [saveForLater, setSaveForLater] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [dotCount, setDotCount] = useState(1);
  const [loadingIndex, setLoadingIndex] = useState(0);
  const messagesEndRef = useRef(null);
  const [previewPosition, setPreviewPosition] = useState(null);
  const scenarioData = testScenarioData?.duplicate_test_scenario || testScenarioData?.test_scenario;
  const isDuplicate = testScenarioData?.duplicate_test_scenario || false
  const isMultipleTestCase = testScenarioData?.test_scenarios?.length > 0 || false;
  const [showValidateConfirmation, setShowValidateConfirmation] = useState(false);


  useEffect(() => {
    if (messagesEndRef.current && testScenarioData?.messages?.length >= 3) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [testScenarioData?.messages]);

  useEffect(() => {
    if ((testScenarioData?.test_scenario || testScenarioData?.duplicate_test_scenario) && previewPosition === null) {
      setPreviewPosition(testScenarioData.messages?.length || 0);
    }
  },[testScenarioData?.test_scenario, testScenarioData?.duplicate_test_scenario, previewPosition, testScenarioData?.messages?.length])

  useEffect(() => {
    const loadingMsgs = [
      "Validating the test cases input",
      "Analyzing the test cases dependencies",
      "Optimizing... thinking faster than a quantum computer",
      "Running some magic under the hood to make this more efficient",
      "The code elves are working hard to finish this up!",
      "Good things take time, like this analysis!",
    ];

    setLoadingText(loadingMsgs[loadingIndex]);

    const interval = setInterval(() => {
      setLoadingIndex((prev) => {
        const nextIndex = (prev + 1) % loadingMsgs.length; 
        setLoadingText(loadingMsgs[nextIndex]); 
        return nextIndex;
      });
    }, 5000);
  
    const dotInterval = setInterval(() => {
      setDotCount((prev) => (prev < 3 ? prev + 1 : 1));
    }, 500);
  
    return () => {
      clearInterval(interval);
      clearInterval(dotInterval); 
    };
  }, [testScenarioData?.text_count_index, testScenarioData?.total_test_case_count, loadingIndex]);


  const renderValidateConfirmDialog = () => {
    return (
      <ConfirmationDialog
        open={showValidateConfirmation}
        onClose={() => setShowValidateConfirmation(false)}
        onConfirm={() => {
          setShowValidateConfirmation(false);
          startAgainClick();
        }}
        title="Confirm Action"
        content="This will delete all the request body and headers and will setup everything again with AI."
        actionText="Continue"
        cancelText="Cancel"
        actionTextColor={"primary"}
        cancelTextColor={"error"}
      />
    )
  }

  const handleSend = () => {
    if (inputMessage.trim()) {
      onSendBtnClick(inputMessage.trim(), saveForLater); 
      setInputMessage('');
      setSaveForLater(false);
    }
  };

  const handleOnStartAgainClick = () => {
    setShowValidateConfirmation(true);
  };

  const renderLoadingText = () => (
    <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray', height: '1.5rem', margin: 0 }}>
      {loadingText}
      <span className="loading-dots">
        {[...Array(dotCount)].map((_, index) => (
          <span key={index} className={`dot dot-${index + 1}`}></span>
        ))}
      </span>
    </Typography>
  );

  const Marker = () => (
    <span style={{ marginRight: '10px' }}>
      <ChatBubbleIcon style={{ fontSize: '12px', color: 'gray' }} />
    </span>
  );

  const renderButtonsForTestScenarioCase = () => {
    if (isDuplicate || isMultipleTestCase || !scenarioData || testScenarioData?.user_input_needed || testScenarioData?.loading) return null;
      return (
        <Box 
          sx={{ 
            display: 'flex', 
            gap: 2, 
            mt: 2,
            justifyContent: 'flex-start',
          }}
        >
          <Button
            variant="outlined"
            sx={{ textTransform: 'none' }}
            onClick={startNewClick}
          >
            Start again
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: 'none' }}
            onClick={onGoToTestScenarioClick}
          >
            Go to Test Scenario
          </Button>
        </Box>
      );
  };

  const renderButtonsForDuplicateCase = () => {
    if (isDuplicate && !isMultipleTestCase && scenarioData && testScenarioData?.loading === false) {
      return (
        <Box 
      sx={{ 
        display: 'flex', 
        gap: 2, 
        mt: 2,
        justifyContent: 'flex-start',
      }}
    >
      <Button
        variant="outlined"
        sx={{ textTransform: 'none' }}
        onClick={startNewClick}
      >
        Start again
      </Button>
      <Button
        variant="contained"
        color="primary"
        sx={{ textTransform: 'none' }}
        onClick={forceGenerateClick}
      >
        Force Generate
      </Button>
    </Box>

      );
    }
    return null;
  };
  

  const renderButtonsForMultipleTestCase = () => {
    if (isDuplicate || !isMultipleTestCase || scenarioData || testScenarioData?.user_input_needed || testScenarioData?.loading) return null;

      return (
        <>
          <Box sx={{ 
            mt: 2,
            mb: 4,
            p: 1,
            backgroundColor: '#F0F7FF',
            borderRadius: '4px',
            border: '1px solid #BAD6FF'
          }}>
            <Typography sx={{
              color: '#1976D2',
              fontWeight: 600,
              fontSize: '12px',
              mb: 1
            }}>
              🚀 Processing in Progress!
            </Typography>
            <Typography sx={{
              color: '#2196F3',
              fontSize: '10px'
            }}>
              We are generating request data for all the test scenarios,
              we will notify you as soon as it is complete!
            </Typography>
          </Box>
          <Box 
            sx={{ 
              display: 'flex', 
              gap: 2,
              justifyContent: 'flex-start',
            }}
          >
            <Button
              variant="outlined"
              sx={{ textTransform: 'none' }}
              onClick={startNewClick}
            >
              Start again
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ textTransform: 'none' }}
              onClick={onGoToAllTestScenariosClick}
            >
              See All Test Scenarios
            </Button>
          </Box>
        </>
      );
    
  };

  const renderScenarioPreview = () => {
    const renderScenarioCard = (scenario, index, isDuplicate = false) => {
      return (
        <Card 
          key={scenario.id || index}
          sx={{ 
            minWidth: '300px', 
            maxWidth: '400px', 
            flex: '0 0 auto',
            backgroundColor: isDuplicate ? '#FFF8E1' : '#F0F4F8',
            border: '1px solid',
            borderColor: isDuplicate ? '#FFB74D' : 'transparent',
          }}
        >
          <CardContent>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                fontWeight: 600, 
                mb: 1,
                color: isDuplicate ? '#F57C00' : 'primary.main'
              }}
            >
              {scenario.title || `Test Scenario ${index + 1}`}
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                color: 'text.secondary',
                whiteSpace: 'pre-wrap',
                mb: 1
              }}
            >
              {scenario.description || 'No description available'}
            </Typography>
          </CardContent>
        </Card>
      );
    };

    if (testScenarioData?.test_scenarios && testScenarioData?.test_scenarios?.length > 0) {
      return (
        <Box sx={{ 
          width: '100%', 
          overflowX: 'auto', 
          display: 'flex', 
          gap: 1, 
          p: 1,
          '&::-webkit-scrollbar': {
            height: '8px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,0.2)',
            borderRadius: '4px'
          }
        }}>
          {testScenarioData?.test_scenarios.map((scenario, index) => 
            renderScenarioCard(scenario, index)
          )}
        </Box>
      );
    }
    
    if (!scenarioData) return null;
  
    return (
      <Box sx={{ 
        width: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        gap: 2,
        pt: 1,
        pb:1 
      }}>
        {renderScenarioCard(scenarioData, 0, isDuplicate)}
      </Box>
    );
  };

  const renderCombinedContent = () => {
    const messages = testScenarioData?.messages || [];
    const content = [];
    let hasPreviewBeenAdded = false;

    messages.forEach((message, index) => {
      if (index === previewPosition && !hasPreviewBeenAdded) {
        content.push(
          <Box key="preview">
            {renderScenarioPreview()}
          </Box>
        );
        hasPreviewBeenAdded = true;
      }

      content.push(
        <ListItem
          key={`message-${index}`}
          sx={{
            display: 'flex',
            alignItems: 'top', 
            paddingLeft: '4px',
            paddingRight: '4px',
            paddingBottom: '0px',
            flexWrap: 'wrap',
          }}
        >
          <Marker />
          <Typography
            variant="body1"
            sx={{
              wordBreak: 'break-word',
              flex: 1, 
            }}
          >
            {message}
          </Typography>
        </ListItem>
      );
    });

    if (!hasPreviewBeenAdded && (testScenarioData?.test_scenario || testScenarioData?.duplicate_test_scenario || testScenarioData?.test_scenarios)) {
      content.push(
        <Box 
        key="preview"
        sx={{
          marginTop: '8px',
        }}
        >
          {renderScenarioPreview()}
        </Box>
      );
    }

    return content;
  };

  const renderLoadingMessage = () => {
    if (!testScenarioData?.loading || testScenarioData?.messages?.length === 0) return null;
    return (
      <ListItem sx={{ paddingLeft: 0, paddingTop: 1, paddingBottom: 0 }}>
        {renderLoadingText()}
      </ListItem>
    );
  };

  const renderUserInput = () => {
    if (!testScenarioData?.user_input_needed) return null;
  
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          padding: '0 8px', 
          marginTop: '12px', 
        }}
      >
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center',
          }}
        >
          <TextField
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            variant="outlined"
            placeholder="Enter required information..."
            fullWidth
            sx={{
              marginRight: 1,
              borderRadius: '8px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#e0e0e0', 
                  borderWidth: '1px', 
                },
                '&:hover fieldset': {
                  borderColor: '#e0e0e0', 
                  borderWidth: '1px', 
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#e0e0e0', 
                  borderWidth: '1px', 
                },
              },
              '& .MuiInputBase-root': {
                height: '40px',
              },
            }}
          />
          <Button 
            variant="contained" 
            sx={{
              backgroundColor: '#e0e0e0', 
              borderRadius: '4px',
              height: '40px', 
              width: '48px', 
              minWidth: '0', 
              padding: '0', 
              marginLeft: '6px', 
              '&:hover': {
                backgroundColor: '#e0e0e0',
              },
            }}
            onClick={handleSend}
          >
            <SendIcon sx={{ color: 'primary.main' }} /> 
          </Button>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
          <Checkbox
            checked={saveForLater}
            onChange={(e) => setSaveForLater(e.target.checked)}
            sx={{
              padding: 0,
              color: 'gray',
              '&.Mui-checked': {
                color: 'primary.main',
                border: '1px solid primary.main',
              },
              '&:hover': {
                bgcolor: 'transparent',
              }
            }}
          />
          <Typography sx={{ color: 'text.secondary', ml: 0.4 }}>
            Save this response in the AI memory
          </Typography>
        </Box>
        {testScenarioData?.start_again_permitted && (
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}>
            <Button
              variant="outlined"
              sx={{ textTransform: 'none' }}
              onClick={handleOnStartAgainClick}
            >
              Start again
            </Button>
          </Box>
        )}
      </Box>
    );
  };

  const renderChatContent = () => {
    return (
      <Paper 
        elevation={0} 
        sx={{ 
          width: '100%', 
          height: '100%', 
          display: 'flex', 
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box 
          sx={{ 
            flex: 1, 
            overflowY: 'auto', 
            maxHeight: '600px',
            backgroundColor: '#ffffff',
            padding: '0', 
          }}
        >
          <List sx={{ width: '100%', padding: 0, marginTop: '8px' }}>
            {renderCombinedContent()}
            {renderLoadingMessage()}
            {testScenarioData?.messages?.length >= 3 && (
              <ListItem ref={messagesEndRef} sx={{ paddingLeft: 0, paddingTop: '0', paddingBottom: '0' }} />
            )}
          </List>
        </Box>
        {renderUserInput()}
        {renderGoToTestScenarioButton()}
        {renderButtonsForTestScenarioCase()}
        {renderButtonsForDuplicateCase()} 
        {renderButtonsForMultipleTestCase()}     
        {showValidateConfirmation && renderValidateConfirmDialog()} 
      </Paper>
    );
  };

  const renderGoToTestScenarioButton = () => {
    if (testScenarioData?.user_input_needed || testScenarioData?.loading || testScenarioData?.test_scenario || testScenarioData?.duplicate_test_scenario || testScenarioData?.test_scenarios) return null;
    return (
      <Box 
        sx={{ 
        display: 'flex', 
        gap: 2, 
        mt: 2,
        justifyContent: 'flex-start',
        }}
      >
        {testScenarioData?.start_again_permitted && (
          <Button
            variant="outlined"
            sx={{ textTransform: 'none' }}
            onClick={handleOnStartAgainClick}
          >
            Start again
          </Button>
        )}

        <Button
          variant="contained"
          color="primary"
          sx={{ textTransform: 'none' }}
          onClick={onGoToTestScenarioClick}
        >
          Go to Test Scenario
        </Button>
      </Box>
    );
  };

  return (
    <>
      {
      testScenarioData?.loading && (!testScenarioData?.messages || testScenarioData.messages.length === 0) 
        ? (
          <Box sx={{ textAlign: 'left', paddingTop: 2 }}>
            {renderLoadingText()}
          </Box>
        ) 
        : renderChatContent()
        }
    </>
  );
};

export default ChatWindow;