import { apiAgent } from '../../config/axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  updateLoadError,
  setLoadingState,
  setSavingState,
  updateTestScenariosList,
  setSelectedTestScenario,
  updateSelectedTestScenarioDetail,
  setTestScenarioTags
} from './testScenarioSlice';
// Constants
import { TEST_SCENARIOS_PAGE_SIZE } from '../../config/constants';


const createTestScenario = createAsyncThunk(
  'createTestScenario',
  async ({ projectId, title }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'POST',
        url: '/api/v1/test_scenarios/',
        data: {
          project_id: projectId,
          title: title || '',
        },
      });
      if (response.status === 200) {
        await dispatch(
          setSelectedTestScenario({ testScenarioId: response?.data?.id })
        );
        await dispatch(
          updateSelectedTestScenarioDetail({ testScenario: {...response?.data} })
        );
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const getAllTestScenarios = createAsyncThunk(
  'getAllTestScenarios',
  async (
    {
      projectId,
      pageNumber,
      pageSize = TEST_SCENARIOS_PAGE_SIZE,
      searchText = null,
      tags = [],
      statuses = [],
      environmentSetId = null,
      buildStatus = null,
      ownerIds = [],
    },
    { dispatch }
  ) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'GET',
        url: '/api/v1/test_scenarios',
        params: {
          page: pageNumber,
          page_size: pageSize,
          project_id: projectId,
          search_text: searchText,
          environment_set_id: environmentSetId,
          build_status: buildStatus,
          owner_ids: ownerIds,
          tags,
          statuses,
        },
      });
      if (response.status === 200) {
        await dispatch(
          updateTestScenariosList({
            testScenariosList: response?.data?.test_scenarios,
            totalPages: response?.data?.total_pages,
            total: response?.data?.total,
            scenario_being_generated: response?.data?.scenario_being_generated,
          })
        );
        await dispatch(setLoadingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

const updateTestScenario = createAsyncThunk(
  'updateTestScenario',
  async ({ testScenarioId, data }, { dispatch }) => {
    await dispatch(setSavingState(true));
    try {
      const response = await apiAgent({
        method: 'PUT',
        url: `/api/v1/test_scenarios/${testScenarioId}`,
        data,
      });
      if (response.status === 200) {
        await dispatch(
          updateSelectedTestScenarioDetail({
            testScenarioDetail: {...response?.data},
          })
        );
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const fetchTestScenarioDetail = createAsyncThunk(
  'fetchTestScenarioDetail',
  async ({ testScenarioId }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'GET',
        url: `/api/v1/test_scenarios/${testScenarioId}`,
      });
      if (response.status === 200) {
        await dispatch(
          updateSelectedTestScenarioDetail({
            testScenarioDetail: {...response?.data},
          })
        );
        await dispatch(setLoadingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const deleteTestScenario = createAsyncThunk(
  'deleteTestScenario',
  async ({ testScenarioId }, { dispatch, rejectWithValue }) => {
    await dispatch(setSavingState(true));
    try {
      const response = await apiAgent({
        method: 'DELETE',
        url: `/api/v1/test_scenarios/${testScenarioId}`,
      });
      if (response.status === 204) {
        await dispatch(setSavingState(false));
        return response?.data;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const cloneTestScenario = createAsyncThunk(
  'cloneTestScenario',
  async ({ testScenarioId }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'POST',
        url: `/api/v1/test_scenarios/${testScenarioId}/clone`,
      });
      if (response.status === 200) {
        await dispatch(
          setSelectedTestScenario({ testScenarioId: response?.data?.id })
        );
        // await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const getTestScenarioTags = createAsyncThunk(
  'getTestScenarioTags',
  async ({ projectId }, { dispatch }) => {
    try {
      const response = await apiAgent({
        method: 'GET',
        url: '/api/v1/test_scenarios/tags',
        params: {
          project_id: projectId,
        },
      });
      if (response.status === 200) {
        await dispatch(setTestScenarioTags(response.data.tags));
        return response.data.tags;
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const startTestScenarioProcess = createAsyncThunk(
  'startTestScenarioProcess',
  async ({ testScenarioId, startAgain }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiAgent({
        method: 'POST',
        url: '/api/v1/test_scenario_processes',
        params: {
          test_scenario_id: testScenarioId,
          start_again: startAgain
        },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const fetchTestScenarioProcessStatus = createAsyncThunk(
  'fetchTestScenarioProcessStatus',
  async ({ testScenarioProcessId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiAgent({
        method: 'GET',
        url: `/api/v1/test_scenario_processes/${testScenarioProcessId}`,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const submitUserInputData = createAsyncThunk(
  'submitUserInputData',
  async ({ testScenarioProcessId, messageToSend, saveMessage}, { dispatch, rejectWithValue }) => {
    console.log('submitUserInputData', messageToSend, saveMessage);
    try {
      const response = await apiAgent({
        method: 'POST',
        url: `/api/v1/test_scenario_processes/${testScenarioProcessId}/add_message`,
        data: {
          user_message: messageToSend,
          is_project_message: saveMessage
        },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const exportDetailed = createAsyncThunk(
  'exportDetailed',
  async ({ projectId }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'POST',
        url: '/api/v1/test_scenarios/export_detailed',
        data: {
          project_id: projectId
        },
      });
      if (response.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);


const exportBrief = createAsyncThunk(
  'exportBrief',
  async ({ projectId }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'POST',
        url: '/api/v1/test_scenarios/export_brief',
        data: {
          project_id: projectId
        },
      });
      if (response.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);


const generateSingleIntegrationTest = createAsyncThunk(
  'generateSingleIntegrationTest',
  async ({ projectId, collectionIds, entityIds, description, tags }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'POST',
        url: '/api/v1/ai/generate_single_test_scenario',
        data: {
          project_id: projectId,
          collection_ids: collectionIds,
          entity_ids: entityIds,
          description: description,
          tags: tags
        },
      });
      if (response.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const getTestProcessStatus = createAsyncThunk(
  'getTestProcessStatus',
  async ({ testProcessId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiAgent({
        method: 'GET',
        url: `/api/v1/test_processes/${testProcessId}`,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const generateBulkTestScenario = createAsyncThunk(
  'generateBulkTestScenario',
  async ({ projectId, collectionIds, entityIds, additionalPrompt, selectedTestTypes, testTypeDetails, tags }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'POST',
        url: '/api/v1/ai/generate_bulk_test_scenarios',
        data: {
          project_id: projectId,
          collection_ids: collectionIds,
          entity_ids: entityIds,
          additional_prompt: additionalPrompt,
          test_types: selectedTestTypes,
          test_type_details: testTypeDetails,
          tags: tags
        },
      });
      if (response.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const forceGenerateTestScenario = createAsyncThunk(
  'forceGenerateTestScenario',
  async ({ testProcessId }, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'PUT',
        url: `/api/v1/test_processes/${testProcessId}`,
        data: {
          input_data: {
            force_scenario_generation: true
          }
        },
      });
      if (response.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const bulkDeleteTestScenarios = createAsyncThunk(
  'bulkDeleteTestScenarios',
  async ({ projectId, testScenarioIds }, { dispatch, rejectWithValue }) => {
    await dispatch(setSavingState(true));
    try {
      const response = await apiAgent({
        method: 'DELETE',
        url: '/api/v1/test_scenarios/bulk_destroy',
        data: {
          project_id: projectId,
          test_scenario_ids: testScenarioIds
        },
      });

      // Wait for the loading state to be set
      await dispatch(setSavingState(false));

      // Check for successful response
      if (response.status === 204 || response.status === 200) {
        return { success: true };
      } else {
        return rejectWithValue({ message: 'Failed to delete test scenarios' });
      }
    } catch (err) {
      await dispatch(setSavingState(false));
      return rejectWithValue(err.response?.data || { message: 'Failed to delete test scenarios' });
    }
  }
);


const fetchTestScenarioDataFiles = createAsyncThunk(
  'fetchTestScenarioDataFiles',
  async ({ testScenarioId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiAgent({
        method: 'GET',
        url: '/api/v1/test_scenario_data_files',
        params: {
          test_scenario_id: testScenarioId
        }
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response?.data || { message: 'Failed to fetch data files' });
    }
  }
);

const addTestScenarioDataFile = createAsyncThunk(
  'addTestScenarioDataFile',
  async ({ testScenarioId, environmentSetId, dataFileId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiAgent({
        method: 'POST',
        url: '/api/v1/test_scenario_data_files',
        data: {
          test_scenario_id: testScenarioId,
          environment_set_id: environmentSetId,
          data_file_id: dataFileId
        }
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response?.data || { message: 'Failed to add data file' });
    }
  }
);

const updateTestScenarioDataFile = createAsyncThunk(
  'updateTestScenarioDataFile',
  async ({ testScenarioDataFileId, selected }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiAgent({
        method: 'PUT',
        url: `/api/v1/test_scenario_data_files/${testScenarioDataFileId}`,
        data: {
          selected
        }
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response?.data || { message: 'Failed to update data file' });
    }
  }
);

const deleteTestScenarioDataFile = createAsyncThunk(
  'deleteTestScenarioDataFile',
  async ({ testScenarioDataFileId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiAgent({
        method: 'DELETE',
        url: `/api/v1/test_scenario_data_files/${testScenarioDataFileId}`,
      });
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response?.data || { message: 'Failed to delete data file' });
    }
  }
);

export {
  createTestScenario,
  getAllTestScenarios,
  updateTestScenario,
  fetchTestScenarioDetail,
  deleteTestScenario,
  cloneTestScenario,
  getTestScenarioTags,
  startTestScenarioProcess,
  fetchTestScenarioProcessStatus,
  submitUserInputData,
  exportBrief,
  exportDetailed,
  generateSingleIntegrationTest,
  getTestProcessStatus,
  generateBulkTestScenario,
  forceGenerateTestScenario,
  bulkDeleteTestScenarios,
  fetchTestScenarioDataFiles,
  addTestScenarioDataFile,
  updateTestScenarioDataFile,
  deleteTestScenarioDataFile
};
