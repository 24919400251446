import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, TextField, CircularProgress } from '@mui/material';
import { updateResourceSummary, generateResourceOverview } from "../../redux-store/currentUserActions";
import { setResourceSummary } from "../../redux-store/currentUserSlice";
import TextEditor from "../Common/TextEditor/TextEditor.jsx";
import { useCallback } from "react";
import { useSnackbar } from "contexts/CustomSnackbarContext";

const OverviewComponent = ({ resource }) => {
  const [editorData, setEditorData] = useState('');
  const [showEditor, setShowEditor] = useState(false);
  const [isSourceCodeMode, setIsSourceCodeMode] = useState(false);
  const [loading, setLoading] = useState(false);
  let selectedSummary = useSelector((state) => state.user.selectedSummary);
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();

  const handleToggleEditor = () => {
    setShowEditor(prevShowEditor => !prevShowEditor);
  };

  const handleGenerateAction = async () => {
    setLoading(true);
    let type = resource?.type === "collection" ? "collections" : (resource?.type === "folder" ? "folders" : "entities");
    try {
      const response = await dispatch(generateResourceOverview({ type: type, id: resource?.id }));
      setLoading(false);   
      console.log("response payload is ", response?.payload);
      
      if (response && response?.payload) {
        if (response.payload?.data?.overview) {
          setEditorData(response.payload?.data?.overview);          
        } 
        if (response.payload.message) {
          openSnackbar({
            message: response.payload.message || 'Overview generated successfully',
            severity: 'success'
          });
        }
      } else {
        openSnackbar({
          message: 'Failed to generate overview',
          severity: 'error'
        });
      }
    } catch (error) {
      setLoading(false);
      openSnackbar({
        message: 'Error occurred during generation',
        severity: 'error'
      });
    }
  };

  const handleToggleSourceCodeMode = () => {
    setIsSourceCodeMode(!isSourceCodeMode);
  };

  const handleSave = async () => {
    setShowEditor(false);
    setIsSourceCodeMode(false);
    const data = {
      id: selectedSummary?.id,
      summary: {
        overview: editorData,
      }
    };

    const res = await dispatch(updateResourceSummary(data));
    if (res.payload.summary) {
      await dispatch(setResourceSummary(res.payload.summary));
    }
  };

  useEffect(() => {
    setEditorData(selectedSummary?.overview || '');
  }, [selectedSummary]);

  const onChange = useCallback((content) => {
    setEditorData(content);
  }, []);

  return (
    <Box sx={{maxHeight: "calc(100vh - 60px)", overflow: "auto"}}>
      <div style={{
         position: "sticky",
         top: 8,
         backgroundColor: "white",
         zIndex: 1,
         paddingBottom: '12px'
        }}>
          {!editorData && (
            <div
              style={{
              fontSize: '11px',
              fontWeight: '500',
              marginBottom: '15px'
              }}
            > Add overview to preview here.
            </div>
          )}
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button
              size="small"
              variant="contained"
              style={{
                backgroundColor: '#6241d4',
                fontFamily: "Inter, Sans-serif",
                fontWeight: "550",
                fontSize: "10px",
                textTransform: "none"
              }}
              onClick={handleToggleEditor}
            >
              Edit
            </Button>
          {resource &&
            <Button
              size="small"
              variant="contained"
              style={{
                backgroundColor: '#6241d4',
                paddingLeft: '20px',
                paddingRight: '20px',
                fontFamily: "Inter, Sans-serif",
                fontWeight: "550",
                fontSize: "10px",
                textTransform: "none",
                '&.Mui-disabled': {
                  color: 'white'
                }
              }}
              sx={{
                '&.Mui-disabled': {
                  color: 'white'
                }
              }}
              onClick={handleGenerateAction}
              disabled={loading}
            >
              {loading ? 'Generating...' : editorData ? 'Regenerate Documentation' : 'Generate Documentation'}
            </Button>
          }
        </div>
      </div>

      {showEditor ? (
        <div>
          {isSourceCodeMode ? (
            <TextField
              fullWidth
              multiline
              minRows={10}
              value={editorData}
              onChange={(e) => setEditorData(e.target.value)}
              variant="outlined"
              margin="normal"
            />
          ) : (
            <TextEditor initialContent={editorData} onChange={onChange} />
          )}
          <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              size="small"
              variant="contained"
              style={{ backgroundColor: '#6241d4', marginRight: '10px' }}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              size="small"
              variant="outlined"
              style={{ marginRight: '10px' }}
              onClick={handleToggleSourceCodeMode}
            >
              {isSourceCodeMode ? 'Switch to Editor' : 'View Source Code'}
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleToggleEditor}
            >
              Close Editor
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{ fontSize: '11px', fontWeight:'500' }}
          dangerouslySetInnerHTML={{ __html: editorData }}
        />
      )}
    </Box>
  );
};

export default OverviewComponent;