import React, { useState, useEffect, useRef } from "react";
import {
  Stack,
  Chip,
  Box,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText, 
  CircularProgress,
  Typography,
  Tooltip,
} from "@mui/material";

import {
  LocalOffer,
  FiberManualRecord,
  Close,
  Add,
  FileDownloadOutlined,
  FormatListBulleted,
  Description,
  Edit,
} from "@mui/icons-material";
import { useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import useSearchParams from "../../hooks/useSearchParams";
import useDebounce from "../../hooks/useDebounce";
import {
  getAllTestScenarios,
  createTestScenario,
  deleteTestScenario,
  getTestScenarioTags,
  updateTestScenario,
  exportBrief,
  exportDetailed,
} from "../../redux-store/testScenarioReducers/testScenarioActions";
import { getOwners } from "../../redux-store/currentUserActions";
import { getAllVariableSets } from "../../redux-store/variableSetReducers/variableSetActions";
import { bulkDeleteTestScenarios } from "../../redux-store/testScenarioReducers/testScenarioActions";

import MainLayout from "../../layouts/MainLayout";
import TestScenariosList from "./TestScenarioList";
import CustomFilter from "../Common/CustomFilter";
import SearchWithIcon from "../Common/SearchWithIcon";
import CustomButton from "../Common/CustomButton";
import TestSuiteAttach from "./TestSuiteAttach";
import ApiChatModal from "../Common/ApiChatModal";
import BulkDeleteScenarioModal from "./BulkDeleteScenarioModal";

const defaultTestScenarioFilters = {
  tags: [],
  statuses: [],
  environmentSetId: null,
  buildStatus: null,
  ownerIds: [],
};
const statusesObj = {
  Draft: "draft",
  Active: "active",
  Archived: "archived",
};
const statuses = Object.keys(statusesObj);
const buildStatuses = ["pass", "fail"];

const TestScenarioListPage = ({ project }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchParams = useSearchParams();
  const page = parseInt(searchParams.get("page") || "1");
  const { openSnackbar } = useSnackbar();

  // Export menu state
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const exportMenuOpen = Boolean(exportAnchorEl);

  const testScenarios = useSelector(
    (state) => state.testScenario.testScenariosList
  );
  const totalScenarios = useSelector((state) => state.testScenario.total);
  const selectedTestScenarios = useSelector(
    (state) => state.testScenario.selectedTestScenarios
  );
  const totalPages = useSelector((state) => state.testScenario.totalPages);
  const tags = useSelector((state) => state.testScenario.testScenarioTags);
  const isTestScenariosBeingGenerated = useSelector(
    (state) => state.testScenario.scenario_being_generated
  );
  const owners = useSelector((state) => state.user.owners);
  const environmentSets = useSelector(
    (state) => state.variableSet.allVariableSets
  );
  const isLoading = useSelector((state) => state.testScenario.isLoading);

  const [pageNumber, setPageNumber] = useState(isNaN(page) ? 1 : page);
  const [searchText, setSearchText] = useState("");
  const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState(false);
  const tagsFilter = searchParams.get("tags");
  const statusesFilter = searchParams.get("statuses");
  const environmentSetIdFilter = searchParams.get("environmentSetId");
  const buildStatusFilter = searchParams.get("buildStatus");
  const ownerIdsFilter = searchParams.get("ownerIds");
  const parseFilter = (filter) => {
    let parsedFilter;
    try {
      parsedFilter = JSON.parse(filter) || [];
    } catch (e) {
      parsedFilter = [];
    }
    return parsedFilter;
  };
  const [appliedFilters, setAppliedFilters] = useState({
    tags: tagsFilter ? parseFilter(tagsFilter) : [],
    statuses: statusesFilter ? parseFilter(statusesFilter) : [],
    environmentSetId: environmentSetIdFilter || null,
    buildStatus: buildStatusFilter || null,
    ownerIds: ownerIdsFilter ? parseFilter(ownerIdsFilter) : [],
  });
  const lastFetchedPage = useRef(pageNumber);
  const isInitialMount = useRef(true);
  const debouncedSearchText = useDebounce(searchText, 1000);

  const hasAppliedFilters =
    appliedFilters.tags.length ||
    appliedFilters.statuses.length ||
    appliedFilters.environmentSetId ||
    appliedFilters.buildStatus ||
    appliedFilters.ownerIds.length;

  const handleExportClick = (event) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const handleExport = async (isDetailed) => {
    try {
      if (isDetailed) {
        // Call exportDetailed if isDetailed is true
        const response = await dispatch(
          exportDetailed({ projectId: project.id })
        ).unwrap();
        openSnackbar({
          message: response.message,
          severity: "success",
        });
      } else {
        // Call exportBrief if isDetailed is false
        const response = await dispatch(
          exportBrief({ projectId: project.id })
        ).unwrap();
        openSnackbar({
          message: response.message,
          severity: "success",
        });
      }
    } catch (error) {
      console.error("Export Failed:", error);
      if (error && error.message) {
        alert(`Error: ${error.message}`); // Display the error message to the user
      }
    }

    handleExportClose();
  };

  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    // keep page query-param in sync with state
    const queryParams = new URLSearchParams(history.location.search);
    queryParams.set("page", pageNumber);
    window.history.pushState("", "", `?${queryParams.toString()}`);
  }, [pageNumber]);

  useEffect(() => {
    if (
      lastFetchedPage.current === pageNumber &&
      pageNumber > 1 &&
      !isInitialMount.current
    ) {
      // start to fetch from page#1 if filters or search text updates
      setPageNumber(1);
    } else {
      getTestScenariosList();
    }
  }, [pageNumber, appliedFilters, debouncedSearchText]);

  const getTestScenariosList = async () => {
    await dispatch(
      getAllTestScenarios({
        ...appliedFilters,
        statuses: appliedFilters.statuses.map((status) => statusesObj[status]),
        searchText: debouncedSearchText || null,
        projectId: project.id,
        pageNumber,
      })
    );

    lastFetchedPage.current = pageNumber;
  };

  const getFilters = async () => {
    await Promise.all([
      dispatch(getTestScenarioTags({ projectId: project.id })),
      dispatch(getOwners({ projectId: project.id })),
      dispatch(
        getAllVariableSets({
          projectId: project.id,
          isEnvironment: true,
          pageNumber: 1,
        })
      ),
    ]);
  };

  const createNewTestScenario = async () => {
    const testScenario = await dispatch(
      createTestScenario({
        projectId: project.id,
        title: `Test Scenario - ${totalScenarios + 1}`,
      })
    );
    if (testScenario?.payload?.id) {
      openSnackbar({
        message: "Test scenario created successfully.",
        severity: "success",
      });
      history.push(`/test-scenarios/${testScenario.payload.id}`);
    } else {
      openSnackbar({
        message: `Test suite creation failed!`,
        severity: "error",
      });
    }
  };

  const onDeleteTestScenario = async (testScenarioId) => {
    await dispatch(deleteTestScenario({ testScenarioId }));
    openSnackbar({
      message: "Test scenario deleted successfully.",
      severity: "success",
    });

    if (testScenarios.length === 1 && pageNumber !== 1) {
      onPageChange("", pageNumber - 1 > 0 ? pageNumber - 1 : 1);
    } else {
      getTestScenariosList();
    }
  };

  const handleBulkDelete = async () => {
    try {
      await dispatch(
        bulkDeleteTestScenarios({
          projectId: project.id,
          testScenarioIds: selectedTestScenarios.map((test) => test.id),
        })
      ).unwrap();

      openSnackbar({
        message: `Successfully deleted ${
          selectedTestScenarios.length
        } test scenario${selectedTestScenarios.length !== 1 ? "s" : ""}.`,
        severity: "success",
      });

      setIsBulkDeleteModalOpen(false);
      getTestScenariosList();
    } catch (error) {
      openSnackbar({
        message: error?.message || "Failed to delete test scenarios",
        severity: "error",
      });
    }
  };

  const onMarkActiveTestScenario = async (testScenarioId) => {
    await dispatch(
      updateTestScenario({ testScenarioId, data: { status: "active" } })
    );
    openSnackbar({
      message: "Test scenario activated successfully.",
      severity: "success",
    });
    getTestScenariosList();
  };

  const onArchiveTestScenario = async (testScenarioId) => {
    await dispatch(
      updateTestScenario({ testScenarioId, data: { status: "archived" } })
    );
    openSnackbar({
      message: "Test scenario archived successfully.",
      severity: "success",
    });
    getTestScenariosList();
  };

  const onPageChange = (e, value) => {
    setPageNumber(value);
  };

  const applyFilters = (filters, reset = false) => {
    setPageNumber(1);
    if (reset) {
      updateUrl(filters);
      setAppliedFilters({ ...filters });
    } else {
      setAppliedFilters((prevState) => {
        const newState = { ...prevState, ...filters };
        updateUrl(newState);
        return newState;
      });
    }
  };

  const updateUrl = (filters = {}) => {
    let queryStr = "?page=1";
    Object.keys(filters).forEach((f) => {
      queryStr +=
        filters[f] && filters[f].length > 0
          ? `&${f}=${
              Array.isArray(filters[f])
                ? JSON.stringify(filters[f])
                : filters[f]
            }`
          : "";
    });
    history.replace(history.location.pathname + queryStr);
  };

  const onResetFilters = () => applyFilters(defaultTestScenarioFilters, true);

  // Add new state for menu and modal
  const [anchorEl, setAnchorEl] = useState(null);
  const [isApiChatOpen, setIsApiChatOpen] = useState(false);

  // Modify the existing add button click handler
  const handleAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCreateWithAI = () => {
    setIsApiChatOpen(true);
    handleMenuClose();
  };

  const handleCreateManually = () => {
    // Call your existing function for creating manually
    handleAddNewTestScenario();
    handleMenuClose();
  };

  const handleAddNewTestScenario = () => {
    createNewTestScenario();
  };

  // Add collections and apis from state
  const [collections, setCollections] = useState([]);
  const [apis, setApis] = useState([]);

  // Add useEffect to fetch collections and apis
  useEffect(() => {
    // TODO: Replace with actual API calls to fetch collections and apis
    setCollections([]);
    setApis([]);
  }, []);

  return (
    <MainLayout
      isLoading={isLoading}
      headerText="Test Scenarios"
      headerContent={isTestScenariosBeingGenerated && (
        <Box sx={{ 
          display: 'inline-flex', 
          alignItems: 'center',
          backgroundColor: '#f0f7ff',
          padding: '4px 12px',
          borderRadius: '16px',
          border: '1px solid #1976d2'
        }}>
          <CircularProgress size={12} sx={{ mr: 1, color: '#1976d2' }} />
          <Typography variant="body2" sx={{ color: '#1976d2', fontWeight: 500 }}>
            Generating Test Scenarios...
          </Typography>
        </Box>
      )}
      subtitleText={`Showing ${totalScenarios || 0} test scenario(s)`}
      totalPages={totalPages}
      pageNumber={pageNumber}
      onPageChange={onPageChange}
      rightSideContent={
        <>
          <SearchWithIcon
            placeholder="Search test scenarios"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ marginRight: "16px" }}
          />
          <CustomButton startIcon={<Add />} onClick={handleAddClick}>
            Add new test scenario
          </CustomButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={handleCreateWithAI}>
              <ListItemIcon>
                <AutoAwesomeIcon sx={{ color: "#6241D4" }} />
              </ListItemIcon>
              <ListItemText>Create with AI</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleCreateManually}>
              <ListItemIcon>
                <Edit sx={{ color: "#6241D4" }} />
              </ListItemIcon>
              <ListItemText>Create Manually</ListItemText>
            </MenuItem>
          </Menu>
        </>
      }
    >
      <Box sx={{ px: "0px", position: "relative", mb: 2, mt: 0 }}>
        <Stack direction="row" spacing={1} sx={{ pr: "100px" }}>
          <CustomFilter
            id="tags-filter"
            label="Tags"
            icon={<LocalOffer />}
            searchInputPlaceholder="Select one or more options"
            options={tags || []}
            appliedFilters={appliedFilters.tags}
            setAppliedFilters={(tags) => applyFilters({ tags })}
          />
          <CustomFilter
            id="status-filter"
            label="Status"
            icon={<FiberManualRecord />}
            options={statuses || []}
            appliedFilters={appliedFilters.statuses}
            setAppliedFilters={(statuses) => applyFilters({ statuses })}
          />
          <CustomFilter
            id="last-run-filter"
            label="Last Run Result"
            type="radio"
            icon={<FiberManualRecord />}
            options={environmentSets || []}
            additionalOptions={buildStatuses || []}
            appliedFilter={appliedFilters.environmentSetId}
            setAppliedFilter={(environmentSetId) =>
              applyFilters({ environmentSetId })
            }
            additionalAppliedFilter={appliedFilters.buildStatus}
            setAdditionalAppliedFilter={(buildStatus) =>
              applyFilters({ buildStatus })
            }
          />
          <CustomFilter
            id="owner-filter"
            label="Owner"
            icon={<FiberManualRecord />}
            options={owners || []}
            appliedFilters={appliedFilters.ownerIds}
            setAppliedFilters={(ownerIds) => applyFilters({ ownerIds })}
          />
          {hasAppliedFilters ? (
            <Chip
              label="Reset all filters"
              deleteIcon={<Close />}
              onClick={onResetFilters}
              onDelete={onResetFilters}
              size="small"
              sx={{
                background: "rgb(229 229 229)",
                border: hasAppliedFilters ? "1px solid #6241d4" : "none",
                borderRadius: "4px",
                fontWeight: 500,
                padding: "14px 0",
              }}
            />
          ) : null}
          <TestSuiteAttach projectId={project.id} />
          <Chip
            id="delete-all"
            label={`Delete All (${selectedTestScenarios?.length || 0})`}
            icon={<DeleteOutlineIcon />}
            onClick={() => setIsBulkDeleteModalOpen(true)}
            size="small"
            disabled={!selectedTestScenarios?.length}
            sx={{
              border: "1px solid #6241d4",
              padding: "14px 0",
              borderRadius: "4px",
              fontWeight: 500,
              opacity: !selectedTestScenarios?.length ? 0.6 : 1,
              cursor: !selectedTestScenarios?.length
                ? "not-allowed"
                : "pointer",
              "&.MuiChip-disabled": {
                opacity: 0.6,
                border: "1px solid rgba(98, 65, 212, 0.5)",
              },
            }}
          />
          {/* <CustomButton
            startIcon={<DeleteOutlineIcon />}
            onClick={() => setIsBulkDeleteModalOpen(true)}
            disabled={!selectedTestScenarios?.length}
            sx={{
              backgroundColor: selectedTestScenarios?.length
                ? "#ff4444"
                : "grey.400",
              color: "white",
              "&:hover": {
                backgroundColor: selectedTestScenarios?.length
                  ? "#cc0000"
                  : "grey.400",
              },
            }}
          >
            Bulk Delete ({selectedTestScenarios?.length || 0})
          </CustomButton> */}
        </Stack>
        <Box
          sx={{
            position: "absolute",
            right: "0px",
            top: "0",
            zIndex: 1,
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            height: "32px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            startIcon={<FileDownloadOutlined />}
            onClick={handleExportClick}
            sx={{
              backgroundColor: "#1976d2",
              textTransform: "none",
              color: "white",
              "&:hover": {
                backgroundColor: "#1565c0",
              },
            }}
          >
            Export
          </Button>
          <Menu
            anchorEl={exportAnchorEl}
            open={exportMenuOpen}
            onClose={handleExportClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={() => handleExport(false)}>
              <ListItemIcon>
                <FormatListBulleted fontSize="small" />
              </ListItemIcon>
              <ListItemText>Basic Export</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleExport(true)}>
              <ListItemIcon>
                <Description fontSize="small" />
              </ListItemIcon>
              <ListItemText>Detailed Export</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Box sx={{ maxHeight: "calc(100vh - 210px)", overflow: "auto" }}>
        <TestScenariosList
          testScenarios={testScenarios}
          isLoading={isLoading}
          onDelete={onDeleteTestScenario}
          onArchive={onArchiveTestScenario}
          onMarkActive={onMarkActiveTestScenario}
        />
      </Box>

      <ApiChatModal
        open={isApiChatOpen}
        onClose={() => setIsApiChatOpen(false)}
      />
      <BulkDeleteScenarioModal
        open={isBulkDeleteModalOpen}
        onClose={() => setIsBulkDeleteModalOpen(false)}
        onDelete={handleBulkDelete}
        selectedCount={selectedTestScenarios?.length || 0}
      />
    </MainLayout>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    project: state.common.selectedProject,
  };
}

export default connect(mapStateToProps)(TestScenarioListPage);
