import { apiAgent } from "../../config/axiosConfig";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  updateLoadError,
  setLoadingState,
  setFunctionsList,
  setTotalPages,
  setTotal
} from "./functionSlice";

const createFunction = createAsyncThunk(
  "createFunction",
  async ({projectId, name, args, code}, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: "POST",
        url: '/api/v1/js_functions',
        data: {
          project_id: projectId,
          function_name: name,
          function_args: args,
          js_code: code
        },
      });
      if (response.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response?.data);
      }
    } catch (err) {
      await dispatch(setLoadingState(false));
      return rejectWithValue(err?.response?.data);
      // dispatch(updateLoadError({ error_message: err.response.data.message }));
    }
  }
);

const updateFunction = createAsyncThunk(
  "updateFunction",
  async ({functionId, args, name, code}, { dispatch, rejectWithValue }) => {
    await dispatch(setLoadingState(true));
    const requestData = {};
    if (args) requestData.function_args = args;
    if (name) requestData.function_name = name;
    if (code) requestData.js_code = code;
    try {
      const response = await apiAgent({
        method: "PUT",
        url: `/api/v1/js_functions/${functionId}/`,
        data: requestData,
      });
      if (response.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

const getFunctionsList = createAsyncThunk(
  'getFunctionsList',
  async (
    { projectId, pageNumber, pageSize = 10 },
    { dispatch, rejectWithValue }
  ) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'GET',
        url: '/api/v1/js_functions',
        params: {
          page: pageNumber,
          page_size: pageSize,
          project_id: projectId
        },
      });
      if (response.status === 200) {
        await dispatch(setFunctionsList({functionsList: response?.data?.js_functions}));
        await dispatch(setTotalPages({totalPages: response?.data?.total_pages}));
        await dispatch(setTotal({total: response?.data?.total}));
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

const getFunction = createAsyncThunk(
  'getFunction',
  async (
    { functionId },
    { dispatch, rejectWithValue }
  ) => {
    await dispatch(setLoadingState(true));
    try {
      const response = await apiAgent({
        method: 'GET',
        url: '/api/v1/js_functions/',
        params: {
          function_id: functionId
        },
      });
      if (response.status === 200) {
        await dispatch(setLoadingState(false));
        return response?.data;
      } else {
        await dispatch(setLoadingState(false));
        return rejectWithValue(response.data);
      }
    } catch (err) {
      dispatch(updateLoadError({ error_message: err.response.data.message }));
      await dispatch(setLoadingState(false));
    }
  }
);

export {
  createFunction,
  updateFunction,
  getFunctionsList,
  getFunction
};
