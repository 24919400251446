import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Box, Alert } from '@mui/material';
import { Add } from '@mui/icons-material';
import TestRulesSelected from "./TestRulesSelected.jsx";
import TestRulesSuggestions from "./TestRulesSuggestions.jsx";
import TestRuleCustom from "./TestRuleCustom.jsx";
import TabsComponent from "./TabsComponent.jsx";
import CustomButton from '../Common/CustomButton';

import { updateTestRule, deleteTestRule, getAllTestRules } from "../../redux-store/testRulesReducers/testRulesActions.js";
import { setTestRules } from '../../redux-store/testRulesReducers/testRulesSlice.js';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";

const TestRules = ({ testCase, isExpanded }) => {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [testRule, setTestRule] = useState("");
  const inputRef = useRef("");
  const allTestRules = useSelector(state => (state.testRules.testRules));
  const isSaving = useSelector(state => (state.testRules.isSaving));
  const testRules = allTestRules?.[testCase?.id] || [];
  const tabLabels = ["Suggested", "Write"];
  const initialLoad = useRef(true);
  const [activeTab, setActiveTab] = useState(0);
  const [addRuleActive, setAddRuleActive] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const timeoutIdRef = useRef();
  const [isFetchingRules, setIsFetchingRules] = useState(false);

  useEffect(() => {
    if (isExpanded && initialLoad.current) {
      initialLoad.current = false;
      // ensuring that if store has any changes then it should not override the changes..
      if (testRules.length === 0) {
        setAllTestRules(testCase?.test_rules);
      }
    }
  }, [isExpanded]);
  
  useEffect(() => {
    // ensuring that if store has any changes then it should not override the changes..
    if (isExpanded && testRules.length === 0) {
      setAllTestRules(testCase?.test_rules);
    }
  }, [testCase?.test_rules]);
  

  const onAddCustomRule = async (customRule) => {
    await dispatch(setTestRules({testCaseSetId: testCase.id, testRules: [...testRules, customRule]}));
  }

  const onUpdateRule = async (rule) => {
    const ruleIndex = testRules.findIndex(testRule => testRule.id ===  rule.id);
    const ruleData = {...testRules[ruleIndex], ...rule};
    const response = await dispatch(updateTestRule({testRuleId: rule.id, data: ruleData}));
    if (response?.payload) {
      const rulesList = [...testRules];
      rulesList[ruleIndex] = {
        ...rulesList[ruleIndex], ...response.payload
      }
      updateTestRulesList(rulesList);
      openSnackbar({message: "Test rule updated successfully.", severity: "success"});
    } else {
      openSnackbar({message: "Test rule update failed!", severity: "error"});
    }
  }

  const updateBulkRules = async (rules) => {
    await Promise.all([
      rules.map((rule) => (dispatch(updateTestRule({ testRuleId: rule.id, data: {checked: rule.checked} }))))
    ]);
  }

  const updateTestRulesList = async (testRulesListNew) => {
    await dispatch(setTestRules({testCaseSetId: testCase.id, testRules: [...testRulesListNew]}));
  }

  const removeTestRule = async (ruleId) => {
    await dispatch(deleteTestRule({testRuleId: ruleId}));
    const indexOfRule = testRules.findIndex(rule => rule.id === ruleId);
    updateTestRulesList([...testRules.slice(0, indexOfRule), ...testRules.slice(indexOfRule + 1)])
  }

  const editTestRule = (editRule) => {
    const indexOfRule = testRules.findIndex(rule => rule.id === editRule.id);
    updateBulkRules([editRule]);
    updateTestRulesList([...testRules.slice(0, indexOfRule), editRule, ...testRules.slice(indexOfRule + 1)])
  }

  const onAddSuggestedRules = async (ruleIds) => {
    const testRulesListNew = [...testRules];
    testRulesListNew.forEach((rule, index) => {
      if (ruleIds.indexOf(rule.id) !== -1) {
        testRulesListNew[index] = {...rule, checked: true};
      }
    });
    updateBulkRules(testRulesListNew.filter(rule => ruleIds.indexOf(rule.id) !== -1));
    updateTestRulesList(testRulesListNew);
  }

  const setAlert = (message) => {
    if (message && message.trim()) {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      setAlertMessage(message);
      timeoutIdRef.current = setTimeout(() => {
        setAlertMessage("");
        clearTimeout(timeoutIdRef.current);
      }, 4000);
    }
  }

  const setAllTestRules = async (testRules) => {
    await dispatch(setTestRules({testCaseSetId: testCase.id, testRules: [...testRules]}));
  }

  const filterSuggestedTestRules = () => {
    return testRules.filter((testRule) => testRule.suggested && !testRule.checked);
  }

  const filterSelectedTestRules = () => {
    return testRules.filter((testRule) => testRule.checked);
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
      return (<TestRuleCustom onSave={onAddCustomRule} testCaseSetId={testCase.id} setAlert={setAlert} onCancel={() => {setAddRuleActive(false)}} />);
      default:
        return (<TestRulesSuggestions testRules={filterSuggestedTestRules()} saveTestRules={onAddSuggestedRules} setAlert={setAlert} onCancel={() => {setAddRuleActive(false)}} />);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  
  const addRuleClick = async () => {
    setIsFetchingRules(true);
    try {
      const response = await dispatch(getAllTestRules({ testCaseSetId: testCase.id }));
      if (response?.payload) {
        await dispatch(setTestRules({ testCaseSetId: testCase.id, testRules: response.payload }));
      }
      setAddRuleActive(true);
    } catch (error) {
      setAlert("Failed to fetch test rules");
    } finally {
      setIsFetchingRules(false);
    }
  };

  return (
    <Box sx={{p: 2, bgcolor: "white", borderRadius: "8px"}}>
      <h5 style={{color: "black"}}>Test Rules</h5>
      <TestRulesSelected isSaving={isSaving} onUpdateRule={onUpdateRule} testRules={filterSelectedTestRules()} testCase={testCase} isExpanded={isExpanded} setAlert={setAlert} editTestRule={editTestRule} removeTestRule={removeTestRule} />
      {addRuleActive && (
        <>
          <TabsComponent activeTab={activeTab} handleTabChange={handleTabChange} tabLabels={tabLabels} />
          {renderTabContent()}
        </>
      )}

{!addRuleActive && (
  <CustomButton 
    startIcon={<Add />} 
    onClick={addRuleClick} 
    sx={{mt: 1}}
    disabled={isFetchingRules}
  >
    {isFetchingRules ? "Loading rules..." : "Add new rule"}
  </CustomButton>
)}
      {alertMessage && <Alert sx={{position: "fixed", top: 20, right: 20, zIndex: 99}}>{alertMessage}</Alert>}
    </Box>
  )
};

export default TestRules;