import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Typography, Box, Button, Container, Grid, Card, InputLabel, TextField } from '@mui/material';
import { useSnackbar } from "../../contexts/CustomSnackbarContext";
import { resetPassword } from 'redux-store/currentUserActions';
import {  useDispatch } from "react-redux";

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { openSnackbar } = useSnackbar();
  const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch(); // This hook gives us access to Redux's dispatch function

  const token = new URLSearchParams(location.search).get('token');

  const validateForm = () => {
    const newErrors = {};
    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long';
    }
    if (!formData.confirmPassword) {
      newErrors.confirmPassword = 'Please confirm your password';
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
  
    setIsSubmitting(true);
    try {
      const res = await dispatch(resetPassword({
        reset_password_token: token,
        password: formData.password,
        confirm_password: formData.confirmPassword
      }));
      
      if (!res.error) {
        openSnackbar({
          message: 'Password has been reset successfully',
          severity: 'success'
        });
        history.push('/login');
      } else {
        openSnackbar({
          message: res.payload?.message || 'Failed to reset password',
          severity: 'error'
        });
      }
    } catch (error) {
      openSnackbar({
        message: 'Failed to reset password',
        severity: 'error'
      });
    }
    setIsSubmitting(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  if (!token) {
    return (
      <Container maxWidth="md" className="full-height">
        <Box textAlign="center" mt={4}>
          <Typography variant="h5">Invalid or expired reset link</Typography>
          <Button 
            variant="contained" 
            onClick={() => history.push('/login')}
            style={{ marginTop: '16px' }}
          >
            Back to Login
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" className="full-height">
      <Grid container spacing={2} className="d-flex justify-content-center align-items-center h-100">
        <Grid item xs={12} md={6} className="column-margin">
          <Card className="login-grid-card">
            <div className="right-column">
              <Box className="heading-container">
                <Box className="create-account-title">
                  Reset Password
                </Box>
              </Box>
              <Box>
                <InputLabel htmlFor="password" className="input-label">New Password</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  className="input-field"
                  variant="outlined"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  error={!!errors.password}
                  helperText={errors.password || ' '}
                />

                <InputLabel htmlFor="confirmPassword" className="input-label">Confirm Password</InputLabel>
                <TextField
                  fullWidth
                  size="small"
                  className="input-field"
                  variant="outlined"
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword || ' '}
                />
              </Box>
              <div className="btn_login">
                <Button 
                  variant="contained" 
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  size="medium" 
                  style={{ padding: '10px 0', width: '100%' }}
                >
                  {isSubmitting ? 'Resetting...' : 'Reset Password'}
                </Button>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ResetPassword;