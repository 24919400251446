import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { 
  Dialog, 
  IconButton, 
  Typography, 
  Box,
  Button,
  Divider,
  Link
} from '@mui/material';
import { ChevronLeft, ChevronRight, Close } from '@mui/icons-material';
import clip1 from '../../assets/gifs/clip1.gif';  
import clip2 from '../../assets/gifs/clip2.gif'; 
import clip3 from '../../assets/gifs/clip3.gif';  
import customUtils from 'utils/customUtils';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '1200px',
    width: '100%',
    margin: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));

const NavigationButton = styled(IconButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(1),
  '&.Mui-disabled': {
    borderColor: theme.palette.grey[200],
    color: theme.palette.grey[400],
  },
}));

const ProgressDot = styled('div')(({ theme, active }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: active ? theme.palette.primary.main : theme.palette.grey[300],
  transition: 'background-color 0.3s ease',
}));

const BulletPoint = styled(Box)(({ theme, nested }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(1),
  marginLeft: nested ? theme.spacing(2) : 0,
  '&:last-child': {
    marginBottom: 0,
  },
}));

const StepText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  color: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(1),
  lineHeight: 1.5,
  '& .number': {
    minWidth: '24px',
    marginRight: theme.spacing(1),
    fontWeight: 500,
    marginTop: 2
  },
  '& .bullet': {
    minWidth: '20px',
    marginRight: theme.spacing(0.5),
    fontSize: '24px',
    lineHeight: '16px',
    marginTop: '2px',
  }
}));

const ContentTitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 700,
  color: theme.palette.text.primary,
  display: 'inline',
}));

const ContentText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  color: theme.palette.text.primary,
  display: 'inline',
}));

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.text.secondary,
  textAlign: 'center',
  '& .MuiLink-root': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  paddingTop: theme.spacing(2),
}));

const IntroTour = ({ open, onClose }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const slides = [
    {
      title: "Import API Collection",
      image: clip1,
      description: "To add an API collection, click the + (Add Collection) button and choose from these methods:",
      content: [
        {
          title: "Postman Exports:",
          description: "Import your Postman export files directly"
        },
        {
          title: "Swagger Exports:",
          description: "Upload a swagger.yaml or swagger.json file, or generate Swagger using our SDK"
        },
        {
          title: "Export from logs:",
          description: "Build from your application logs using our Rails or Node.js SDK"
        },
        {
          title: "Generate Swagger from your SDK:",
          description: "Generate Swagger from your codebase using our open-source SDK"
        }
      ]
    },
    {
      title: "Create your first Test Scenario",
      image: clip2,
      content: [
        { type: "step", text: "Go to API Testing > Test Scenarios" },
        { type: "step", text: 'Click "Add New Test Scenario"' },
        {
          type: "step",
          text: "Choose your creation method:",
          subItems: [
            {
              title: "AI-Generated:",
              description: "Let Qodex.ai analyze your docs and API collection to create scenarios. You can make a single test scenario or multiple tests at once."
            },
            {
              title: "Manual Creation:",
              description: "You can build your test scenario step by step - from naming it and setting up API requests to adding test rules in plain English."
            }
          ]
        }
      ]
    },
    {
      title: "Schedule your first Test Scenario",
      image: clip3,
      content: [
        { type: "step", text: "Create a test scenario (either with AI or manually)" },
        { type: "step", text: "Add it to a Test Suite" },
        { type: "step", text: "Create a Test Plan" },
        {
          type: "step",
          text: "In the Test Plan details, set your schedule:",
          subItems: [
            { text: "How often you want it to run" },
            { text: "Start/end times" },
            { text: "Who gets notified and how" }
          ]
        }
      ]
    }
  ];

  const handleNext = () => {
    setCurrentSlide((prev) => Math.min(prev + 1, slides.length - 1));
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => Math.max(prev - 1, 0));
  };

  const isLastSlide = currentSlide === slides.length - 1;

  const renderContent = (content) => {
    return content.map((item, index) => {
      if (item.type === "step") {
        return (
          <Box key={index}>
            <StepText>
              <span className="number">{index + 1}.</span>
              {item.text}
            </StepText>
            {item.subItems && (
              <Box sx={{ ml: 2, mt: 0.5, mb: 1 }}>
                {item.subItems.map((subItem, subIndex) => (
                  <BulletPoint key={subIndex} nested>
                    <StepText>
                      <span className="bullet">•</span>
                      <Box>
                        {subItem.title && (
                          <ContentTitle>
                            {subItem.title}{' '}
                          </ContentTitle>
                        )}
                        <ContentText>
                          {subItem.description || subItem.text}
                        </ContentText>
                      </Box>
                    </StepText>
                  </BulletPoint>
                ))}
              </Box>
            )}
          </Box>
        );
      }
      
      return (
        <BulletPoint key={index}>
          <StepText>
            <span className="bullet">•</span>
            <Box>
              <ContentTitle>
                {item.title}{' '}
              </ContentTitle>
              <ContentText>
                {item.description}
              </ContentText>
            </Box>
          </StepText>
        </BulletPoint>
      );
    });
  };

  return (
    <StyledDialog 
      open={open} 
      onClose={isLastSlide ? onClose : undefined}
      maxWidth={false}
      fullWidth
    >
      <Box sx={{ p: 4 }}>
        {isLastSlide && (
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
            }}
          >
            <Close />
          </IconButton>
        )}

        <Box sx={{ 
          display: 'flex', 
          gap: 4,
          height: '100%',
        }}>
          {/* Left side - Content */}
          <Box sx={{ flex: '1 1 60%' }}>
            <Typography variant="h4" sx={{ mb: 2, fontWeight: 700, fontSize: '28px' }}>
              {slides[currentSlide].title}
            </Typography>

            {slides[currentSlide].description && (
              <Typography variant="body1" sx={{ mb: 2, fontSize: '16px' }}>
                {slides[currentSlide].description}
              </Typography>
            )}

            <Box sx={{ mb: 3 }}>
              {renderContent(slides[currentSlide].content)}
            </Box>

            {/* Navigation */}
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              mt: 3
            }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {slides.map((_, index) => (
                  <ProgressDot key={index} active={index === currentSlide} />
                ))}
              </Box>

              <Box sx={{ display: 'flex', gap: 1 }}>
                <NavigationButton
                  onClick={handlePrev}
                  disabled={currentSlide === 0}
                  size="small"
                >
                  <ChevronLeft />
                </NavigationButton>
                <NavigationButton
                  onClick={handleNext}
                  disabled={isLastSlide}
                  size="small"
                >
                  <ChevronRight />
                </NavigationButton>
                {isLastSlide && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                    sx={{ ml: 1 }}
                  >
                    Get Started
                  </Button>
                )}
              </Box>
            </Box>
          </Box>

          {/* Right side - Image */}
          <Box sx={{ 
            flex: '1 1 40%', 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <img 
              src={slides[currentSlide].image}
              alt={slides[currentSlide].title}
              style={{
                width: '100%',
                height: 'auto',
                maxHeight: '300px',
                objectFit: 'cover',
                borderRadius: 8,
              }}
              onError={(e) => {
                console.error('Error loading image:', e);
                console.log('Attempted image path:', slides[currentSlide].image);
              }}
            />
          </Box>
        </Box>

        {/* Footer */}
        <FooterContainer>
          <Divider />
          <FooterText sx={{ mt: 2 }}>
            In case of any queries, please schedule a{' '}
            <Link href={customUtils.calendlyDemoUrl} target="_blank">
              call
            </Link>{' '}
            here or drop a mail to{' '}
            <Link href="mailto:care@qodex.ai">
              care@qodex.ai
            </Link>
          </FooterText>
        </FooterContainer>
      </Box>
    </StyledDialog>
  );
};

export default IntroTour;