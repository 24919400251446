import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip, ResponsiveContainer } from 'recharts';
import { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  Container,
  useTheme,
  CircularProgress,
  Tooltip as MuiTooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { 
  TrendingUp, 
  TrendingDown,
  Code,
  Speed,
  MonetizationOn,
  Science,
  InfoOutlined
} from '@mui/icons-material';
import { connect, useDispatch } from 'react-redux';
import { fetchDashboardDetails } from 'redux-store/dashboardActions';
import { ceil } from 'lodash';

const formatValue = (value, prefix = '', suffix = '', isPercentage = false) => {
  if (value === null || value === undefined || value === '') {
    return 'NA';
  }
  if (isPercentage && value > 100) {
    value = 100;
  }
  return `${prefix}${value}${suffix}`;
};

const ChartContainer = ({ title, children, action }) => {
  return (
    <Card>
      <CardContent>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 2 
        }}>
          <Typography variant="h6">
            {title}
          </Typography>
          {action}
        </Box>
        <Box sx={{ width: '100%', height: 400 }}>
          {children}
        </Box>
      </CardContent>
    </Card>
  );
};

const SimpleGaugeChart = ({ value, title, trend = "up" }) => {
  const [animatedValue, setAnimatedValue] = useState(0);
  
  useEffect(() => {
    setAnimatedValue(0); 
    
    const interval = setInterval(() => {
      setAnimatedValue(prev => {
        if (prev < value) {
          return Math.min(prev + 1, value);
        }
        clearInterval(interval);
        return prev;
      });
    }, 20);

    return () => clearInterval(interval);
  }, [value]); 

  const needleRotation = -90 + (180 * animatedValue / 100);

  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  };

  const describeArc = (x, y, radius, startAngle, endAngle) => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    return [
      "M", start.x, start.y,
      "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");
  };

  const displayValue = Number.isNaN(animatedValue) ? 0 : Math.min(Math.max(animatedValue, 0), 100);

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%', textAlign: 'center', mt: -3.2}}>
      <svg width="200" height="130" viewBox="0 0 200 130">
        <path 
          d={describeArc(100, 100, 70, -90, -54)} 
          fill="none" 
          stroke="#ff4d4d" 
          strokeWidth="15"
        />
        <path 
          d={describeArc(100, 100, 70, -54, 40)} 
          fill="none" 
          stroke="#ffd700" 
          strokeWidth="15"
        />
        <path 
          d={describeArc(100, 100, 70, 40, 70)} 
          fill="none" 
          stroke="#4169e1" 
          strokeWidth="15"
        />
        <path 
          d={describeArc(100, 100, 70, 70, 90)} 
          fill="none" 
          stroke="#4caf50" 
          strokeWidth="15"
        />

        <g transform={`rotate(${needleRotation}, 100, 100)`}>
          <line
            x1="100"
            y1="100"
            x2="100"
            y2="40"
            stroke="gray"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <circle cx="100" cy="100" r="5" fill="gray" />
        </g>

        <text
          x="70"
          y="85"
          textAnchor="middle"
          transform='scale(1.5)'
          fontSize="32px"
          fontWeight="800"
          fill="#000"
        >
          {displayValue}%
        </text>
      </svg>
      <Typography variant="body1" color="textSecondary">
        {title}
      </Typography>
    </Box>
  );
};

const MetricCard = ({ title, value, icon: Icon, trend = "up", showGauge = false }) => {
  const theme = useTheme();
  const TrendIcon = trend === "up" ? TrendingUp : TrendingDown;
  const trendColor = trend === "up" ? theme.palette.success.main : theme.palette.error.main;

  const tooltips = {
    "Test Coverage": "Percentage of API under with enough test scenarios",
    "Tests Automated": "Number of test scenarios running autonomously",
    "Success Rate": "Percentage of passing tests",
    "Cost Savings": "Financial benefits gained through automated vs manual testing (approx)"
  };

  const TitleWithTooltip = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, justifyContent: 'center' }}>
        <Typography variant="body1" color="textSecondary">
          {title}
        </Typography>
        <MuiTooltip title={tooltips[title]} arrow placement="top">
          <InfoOutlined sx={{ fontSize: 16, color: 'text.secondary', cursor: 'help' }} />
        </MuiTooltip>
      </Box>
      <TrendIcon sx={{ color: trendColor, mt: 1 }} />
    </Box>
  );

  return (
    <Card>
      <CardContent>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          p: 1
        }}>
          {showGauge ? (
            <>
              <SimpleGaugeChart value={parseInt(value)} title="" trend={trend} />
              <TitleWithTooltip />
            </>
          ) : (
            <>
              <Box sx={{ 
                bgcolor: theme.palette.primary.light,
                borderRadius: '50%',
                p: 2,
                mb: 1
              }}>
                <Icon sx={{ color: theme.palette.primary.main }} />
              </Box>
              <Typography variant="h4" gutterBottom>
                {value}
              </Typography>
              <TitleWithTooltip />
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

const DashboardPage = ({ projectId }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const [error, setError] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState('');
  const [collections, setCollections] = useState([]);
  const [entityDataByCollection, setEntityDataByCollection] = useState({});

  useEffect(() => {
    const loadDashboardData = async () => {
      try {
        const response = await dispatch(fetchDashboardDetails({ projectId })).unwrap();
        setDashboardData(response);
        
        // Process collections and entity data
        const collectionMap = new Map();
        response.test_cases_count_per_entity?.forEach(item => {
          const collectionId = item.collection_id || 'unknown';
          const collectionName = item.collection_name || 'Unknown Collection';
          
          if (!collectionMap.has(collectionId)) {
            collectionMap.set(collectionId, {
              id: collectionId,
              name: collectionName,
              entities: []
            });
          }
          
          collectionMap.get(collectionId).entities.push({
            entity: item.entity_name || 'Unknown',
            testCases: item.test_case_sets_count || 0
          });
        });

        const collectionsList = Array.from(collectionMap.values());
        setCollections(collectionsList);

        // Create entity data map by collection
        const entityDataMap = {};
        collectionsList.forEach(collection => {
          entityDataMap[collection.id] = collection.entities;
        });
        setEntityDataByCollection(entityDataMap);

        // Set default selected collection
        if (collectionsList.length > 0) {
          setSelectedCollection(collectionsList[0].id);
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setError(error.message || 'Failed to fetch dashboard data');
        setIsLoading(false);
      }
    };

    loadDashboardData();
  }, [dispatch, projectId]);

  const handleCollectionChange = (event) => {
    setSelectedCollection(event.target.value);
  };

  const getCollectionDropdown = () => (
    <FormControl size="small" sx={{ minWidth: 200 }}>
      <InputLabel id="collection-select-label">Collection</InputLabel>
      <Select
        labelId="collection-select-label"
        id="collection-select"
        value={selectedCollection}
        label="Collection"
        onChange={handleCollectionChange}
      >
        {collections.map((collection) => (
          <MenuItem key={collection.id} value={collection.id}>
            {collection.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', p: 3 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!dashboardData) {
    return (
      <Box sx={{ textAlign: 'center', p: 3 }}>
        <Typography>No dashboard data available</Typography>
      </Box>
    );
  }

  const userData = dashboardData.test_scenarios_count_per_user?.map(item => ({
    user: item.user_name || 'Unknown User',
    scenarios: item.test_scenarios_count || 0
  })) || [];

  const tagsData = dashboardData.test_scenarios_count_per_tag?.map(item => ({
    tag: item.tag || 'Unknown Tag',
    scenarios: item.tag_count || 0
  })) || [];

  return (
    <Box sx={{ 
      height: 'calc(100vh - 80px)',
      bgcolor: theme.palette.grey[100],
      overflowY: 'auto',
      p: 3
    }}>
      <Container maxWidth="xl">
        <Box sx={{ 
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(4, 1fr)'
          },
          gap: 3,
          mb: 3
        }}>
          <MetricCard 
            title="Test Coverage" 
            value={formatValue(ceil(dashboardData.percentage_test_coverage), '', '%')}
            icon={Speed}
            trend="up"
            showGauge={true}
          />
          <MetricCard 
            title="Tests Automated" 
            value={dashboardData.test_scenarios_count || 'NA'}
            icon={Code}
            trend="up"
          />
          <MetricCard 
            title="Success Rate" 
            value={(dashboardData.success_rate + '%') || 'NA'}
            icon={Science}
            trend="up"
          />
          <MetricCard 
            title="Cost Savings" 
            value={dashboardData.cost_saving || 'NA'}
            icon={MonetizationOn}
            trend="up"
          />
        </Box>

        <Box sx={{ mb: 3 }}>
          <ChartContainer 
            title="Test Cases by API" 
            action={getCollectionDropdown()}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={entityDataByCollection[selectedCollection] || []}
                margin={{ top: 40, right: 20, left: 20, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="entity"
                  interval={0}
                  angle={-45}
                  textAnchor="end"
                  height={100}
                  tick={{ fontSize: 12 }}
                />
                <YAxis />
                <Tooltip
                  cursor={{ fill: 'none' }}
                  formatter={(value, name) => [`${value}`, 'Test Cases']}
                />
                <Legend align='center' />
                <Bar dataKey="testCases" name="APIs" fill="#4169e1" />
              </BarChart>
            </ResponsiveContainer>
          </ChartContainer>
        </Box>

        <Box sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', lg: 'repeat(2, 1fr)' },
          gap: 2,
          mb: 0
        }}>
          <ChartContainer title="Test Scenarios by User">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={userData}
                margin={{ top: 40, right: 20, left: 20, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="user"
                  interval={0}
                  angle={-45}
                  textAnchor="end"
                  height={100}
                  tick={{ fontSize: 12 }}
                />
                <YAxis />
                <Tooltip
                  cursor={{ fill: 'none' }}
                  formatter={(value, name) => [`${value}`, 'Test Scenarios']}
                />
                <Legend />
                <Bar dataKey="scenarios" name="Users" fill="#4169e1" />
              </BarChart>
            </ResponsiveContainer>
          </ChartContainer>

          <ChartContainer title="Test Scenarios by Tag">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={tagsData}
                margin={{ top: 40, right: 20, left: 20, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="tag"
                  interval={0}
                  angle={-45}
                  textAnchor="end"
                  height={100}
                  tick={{ fontSize: 12 }}
                />
                <YAxis />
                <Tooltip
                  cursor={{ fill: 'none' }}
                  formatter={(value, name) => [`${value}`, 'Test Scenarios']}
                />
                <Legend />
                <Bar dataKey="scenarios" name="Tags" fill="#4169e1" />
              </BarChart>
            </ResponsiveContainer>
          </ChartContainer>
        </Box>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    project: state.common.selectedProject
  };
};

export default connect(mapStateToProps)(DashboardPage);