import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getTestProcessStatus } from 'redux-store/testScenarioReducers/testScenarioActions';


const useTestProcessStatusPolling = (testProcessId, pollingInterval = 5000) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    console.log('Starting polling...', testProcessId);

    if (!testProcessId) {
      return; 
    }

    try {
      const response = await dispatch(getTestProcessStatus({ testProcessId })).unwrap();
      setData(response);
    } catch (err) {
      setError(err.message);
    }
  }, [dispatch, testProcessId]); 

  const startPolling = useCallback(() => {
    console.log('Starting polling...', testProcessId);
    setIsPolling(true); 
  }, []);

  const stopPolling = useCallback(() => {
    setIsPolling(false); 
  }, []);

  useEffect(() => {
    console.log('Starting polling...', testProcessId);

    if (!testProcessId || !isPolling) {
      return; 
    }

    const intervalId = setInterval(fetchData, pollingInterval);
    fetchData(); 

    return () => {
      clearInterval(intervalId); 
    };
  }, [testProcessId, isPolling, fetchData, pollingInterval]); 

  return { data, error, startPolling, stopPolling }; 
};

export default useTestProcessStatusPolling;


